import {useQuery} from 'react-query';
import {getReturnWaitingReceptions} from './api';
import {returnsReservationRequestParam} from './type';
import {getReturnWaitingReceptionsKey} from '@constants/api';
import dayjs from 'dayjs';

/** 返却待ち受付の一覧取得 */
export const useFetchReturnWaitingReceptions = (hospitalHashId: string, params: returnsReservationRequestParam) => {
  return useQuery(
    [getReturnWaitingReceptionsKey, hospitalHashId, params],
    () => getReturnWaitingReceptions(hospitalHashId, params),
    {
      enabled: !!hospitalHashId,
      refetchOnWindowFocus: false,
    }
  );
};

/** 有効(完了・キャンセルを除く)な返却待ち受付数のステータス */
const ReturnWaitingReceptionsCountStatus = ['contacted', 'preparation', 'before_confirmation'];

/** 本日の日付の receptionAtFromとreceptionAtToを返す*/
const getTodayReturnWaitingReceptionsParams = () => {
  const currentDayStart = dayjs().startOf('day').format(); // 午前0時 (00:00:00)
  const currentDayEnd = dayjs().endOf('day').format();

  return {
    receptionAtFrom: currentDayStart,
    receptionAtTo: currentDayEnd,
  };
};

/**
 * 有効(完了・キャンセルを除く)な返却待ち受付数を取得
 * @param hospitalHashId
 * @returns
 */
export const useReturnWaitingReceptionsCount = (hospitalHashId: string, isAll?: boolean) => {
  const params: returnsReservationRequestParam = {
    perPage: 1,
    page: 1,
    statuses: ReturnWaitingReceptionsCountStatus.join(','),
    ...(isAll ? {} : getTodayReturnWaitingReceptionsParams()),
  };

  const query = useQuery(
    [getReturnWaitingReceptionsKey, hospitalHashId, params],
    () => getReturnWaitingReceptions(hospitalHashId, params),
    {
      enabled: !!hospitalHashId,
      refetchOnWindowFocus: false,
    }
  );

  return {count: query.data?.totalCount ?? 0, refetch: query.refetch};
};
