import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {createStyles, Grid, makeStyles, Paper, Theme} from '@material-ui/core';
import {Pagination} from '@material-ui/lab';
import {TableLayout, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {Column} from '@molecules/Table/props';
import {useProductPinnedCommentsQuery} from '@modules/dashboard/api';
import {DashboardProductPinnedComment, DashboardProductPinnedCommentsParam} from '@modules/dashboard/types';
import {useUpdateDashboardSubject, UpdatePaperType} from '../hooks';
import {Table} from '@molecules/Table';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {useNavigate} from 'react-router-dom';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {TableLayoutDialog, TableLayoutDialogProps} from '@organisms/Table/TableLayoutDialog';
import {PaperHeader} from '../PaperHeader';
import {papers} from '../consts';
import dayjs from 'dayjs';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';

const pageSizeMenuItems = [
  {
    label: '5件',
    value: 5,
  },
  {
    label: '10件',
    value: 10,
  },
  {
    label: '20件',
    value: 20,
  },
  {
    label: '50件',
    value: 50,
  },
];

const MAX_DESCRIPTION_LENGTH = 20;

export const ProductCommentList = () => {
  const navigate = useNavigate();
  const {canAccess: canAccessDeviceList} = useUserResourcesPermissions('DEVICE_LIST');
  if (FEATURE_CUSTOM_ASSET_ROLE_FLAG) {
    useEffect(() => {
      if (!canAccessDeviceList) {
        navigate('/dashboard');
      }
    }, [canAccessDeviceList, navigate]);
    if (!canAccessDeviceList) {
      return null;
    }
  }
  const classes = useStyles();
  const {myInfo} = useMyInfo();

  const [pageSize, setPageSize] = useState<number>(20);
  const [orderKey, setOrderKey] = useState<string | null>('-createdAt');

  const [page, setPage] = useState<number>(1);

  const paper = papers.productCommentList;
  const [tableLayout, setTableLayout] = useTableLayout('dashboardProductCommentList');
  const {subscribe, unsubscribe} = useUpdateDashboardSubject();

  const handleChangePage = useCallback((_event: React.ChangeEvent<unknown>, p: number) => {
    setPage(p);
  }, []);

  const params = useMemo(() => {
    const _p: DashboardProductPinnedCommentsParam = {
      page: page - 1,
      perPage: pageSize,
    };
    if (orderKey) {
      _p.order = orderKey;
    }
    return _p;
  }, [page, pageSize, orderKey]);

  const query = useProductPinnedCommentsQuery(myInfo.hospitalHashId, params);
  const totalPage = useMemo(() => {
    return Math.ceil(query.totalCount / pageSize);
  }, [pageSize, query.totalCount]);

  const startDisplayPosition = useMemo(() => {
    return query.totalCount === 0 ? 0 : Math.ceil((page - 1) * pageSize + 1);
  }, [query.totalCount, page, pageSize]);

  const endDisplayPosition = useMemo(() => {
    const endPosition = Math.ceil(page * pageSize);
    return endPosition > query.totalCount ? query.totalCount : endPosition;
  }, [page, pageSize, query.totalCount]);

  const serializedTableColumn = useMemo(() => {
    const tableColumn = Object.assign<Column<DashboardProductPinnedComment>[], TableLayout[]>(
      [],
      tableLayout?.currentLayout
    );
    return tableColumn.map<Column<DashboardProductPinnedComment>>((item) => {
      switch (item.field) {
        case 'description':
          item.render = ({description}: DashboardProductPinnedComment) =>
            description.length <= MAX_DESCRIPTION_LENGTH
              ? description
              : `${description.slice(0, MAX_DESCRIPTION_LENGTH)}...`;
          break;
        case 'createdAt':
          item.render = ({createdAt}: DashboardProductPinnedComment) => dayjs(createdAt).format('YYYY-MM-DD');
          break;
        case 'createdBy':
          item.render = ({createdBy}: DashboardProductPinnedComment) => {
            return UserFormatter.getFullName(createdBy);
          };
          break;
        case 'managementId':
          item.render = ({hospitalProduct}: DashboardProductPinnedComment) => hospitalProduct.managementId;
          break;
        case 'narrowCategory':
          item.render = ({hospitalProduct}: DashboardProductPinnedComment) =>
            hospitalProduct.wholeProduct?.categories.find((cate) => cate.depth === 1)?.name ?? '';
          break;
        case 'displayName':
          item.render = ({hospitalProduct}: DashboardProductPinnedComment) => hospitalProduct.wholeProduct?.displayName;
          break;
        default:
          break;
      }
      item.noBodyWrap = true;
      return item;
    });
  }, [tableLayout?.currentLayout]);

  const handleOrderChange = useCallback(
    (columnIndex: number, orderDirection: 'asc' | 'desc') => {
      if (columnIndex === -1) {
        setOrderKey(null);
      } else {
        setOrderKey(`${orderDirection === 'desc' ? '-' : ''}${String(tableLayout?.currentLayout[columnIndex].field)}`);
      }
    },
    [tableLayout?.currentLayout]
  );

  const onUpdateDashboardSubject = useCallback(
    (paperType: UpdatePaperType) => {
      if (paperType !== 'all' && paperType !== 'product_comment_list') return;
      query.refetch();
    },
    [query]
  );

  const handleChangeRowsPerPage = useCallback((item: MenuItemType) => {
    setPageSize(item.value);
    setPage(1);
  }, []);

  const handleChangeLayout = useCallback(async () => {
    const currentLayout = await dialogHandler.open<TableLayoutDialogProps>(TableLayoutDialog, {
      tableColumns: tableLayout?.tableLayout ?? [],
      defaultOptions: tableLayout?.currentLayout ?? [],
      forceValue: {managementId: true},
    });
    const newTableLayout = {
      tableLayout: tableLayout?.tableLayout ?? [],
      currentLayout: currentLayout,
    };

    setTableLayout(newTableLayout);
  }, [setTableLayout, tableLayout]);

  const handleRowClick = useCallback(
    (_event: React.MouseEvent, rowData: DashboardProductPinnedComment) => {
      navigate(`/products/${rowData.hospitalProduct.hashId}`);
    },
    [navigate]
  );

  useEffect(() => {
    subscribe(onUpdateDashboardSubject);
    return () => unsubscribe(onUpdateDashboardSubject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper className={classes.paper}>
      <PaperHeader
        title={paper.label}
        onClickSettingMenu={handleChangeLayout}
        settingMenuItems={paper.settingMenuItems}
      />
      <Grid container className={classes.root}>
        <Grid container>
          <Table<DashboardProductPinnedComment>
            stickyHeader={true}
            columns={serializedTableColumn}
            isLoading={query.isLoading}
            data={query.data}
            showSelection={false}
            paperProps={{className: classes.tableContainer}}
            onOrderChange={handleOrderChange}
            onRowClick={handleRowClick}
          />
        </Grid>
        <Grid container justifyContent={'space-between'}>
          <Grid item className={classes.pageDescription}>
            {query.totalCount}件のうち{startDisplayPosition}件目-{endDisplayPosition}件目までを表示しています
          </Grid>
          <Grid item className={classes.paginationContainer}>
            <Pagination page={page} count={totalPage} shape="rounded" onChange={handleChangePage} />
            1ページあたり
            <PopperMenuButton
              placement="bottom"
              hiddenArrow={true}
              buttonProps={{variant: 'text'}}
              menuItemList={pageSizeMenuItems}
              onMenuClick={handleChangeRowsPerPage}>
              <span className={classes.pageSize}>{pageSize}件</span>
              <ArrowDropDownIcon />
            </PopperMenuButton>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '0px 16px 16px',
      borderTop: `4px solid ${theme.palette.primary.dark}`,
      height: 'calc(100% - 20px)',
    },
    root: {
      padding: '32px 24px',
    },
    tableContainer: {
      marginBottom: '24px',
      maxHeight: '360px',
      overflowY: 'scroll',
    },
    managementId: {
      display: 'inline',
      color: theme.palette.info.dark,
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    pageDescription: {
      margin: 'auto 0px',
    },
    paginationContainer: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.875rem',
    },
    pageSize: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  })
);
