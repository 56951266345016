import {FilterOption} from '@components/molecules/Drawers/FilterDrawer/types';
import {classNameOpts} from '../ProductRegistration/constants';

type Option = {
  label: string;
  value: string;
};

export const FilterDrawerOptions: (makerOptions: Option[]) => FilterOption[] = (makerOptions) => [
  {
    label: 'メーカー名',
    value: 'makerHashId',
    optionType: 'selector',
    options: makerOptions,
    isMulti: false,
  },
  {
    label: '承認番号',
    value: 'approvalNumber',
    optionType: 'text',
  },
  {
    label: 'JMDNコード',
    value: 'jmdnCode',
    optionType: 'num',
  },
  {
    label: 'GTINコード',
    value: 'newJanCode',
    optionType: 'text',
  },
  {
    label: 'JANコード',
    value: 'janCode',
    optionType: 'text',
  },
  {
    label: '特定保守製品',
    value: 'isSpecificMaintain',
    optionType: 'selector',
    options: [
      {label: '該当', value: true},
      {label: '非該当', value: false},
    ],
    isMulti: false,
  },
  {
    label: 'クラス分類',
    value: 'className',
    optionType: 'selector',
    options: classNameOpts,
    isMulti: false,
  },
  {
    label: '定価',
    value: 'catalogPrice',
    optionType: 'number',
    min: 0,
    unitLabel: '円',
  },
];
