import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
// FIXME 時期を見てfront-libに移行したい
import {useInspectionResult} from '../common/hooks';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {InnerLoading} from '@components/molecules/Loading';
import {EditInspectionResult} from './EditInspectionResult';
import {ViewInspectionResult} from './ViewInspectionResult';
import {useInspection} from '@modules/inspections/hooks';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';

/**
 * InspectionResultコンポーネントは、検査結果の表示と編集のためのUIを提供します。
 * 検査と検査結果のデータはカスタムフックを通じて取得されます。
 * @returns 検査結果の表示または編集用のコンポーネント
 */
export const InspectionResult = () => {
  const navigate = useNavigate();
  const {canAccess} = useUserResourcesPermissions('INSPECTION');
  if (FEATURE_CUSTOM_ASSET_ROLE_FLAG) {
    useEffect(() => {
      if (!canAccess) {
        navigate('/dashboard');
      }
    }, [canAccess, navigate]);
    if (!canAccess) {
      return null;
    }
  }
  const {inspectionHashId, inspectionResultHashId} = useParams<{
    inspectionHashId?: string;
    inspectionResultHashId?: string;
  }>();

  const {myInfo} = useMyInfo();
  const {data: inspection, isLoading: isInspectionLoading} = useInspection(
    myInfo.hospitalHashId,
    inspectionHashId || '',
    true // HIT-4116 点検表の論理削除で deleted_at をきちんと埋め込むようになったため
  );

  const {data: inspectionResult, isLoading: isInspectionResultLoading} = useInspectionResult(
    myInfo.hospitalHashId,
    inspectionHashId || '',
    inspectionResultHashId || '',
    {noCache: true}
  );

  // ローディング状態のチェック
  if (isInspectionLoading || isInspectionResultLoading) {
    return <InnerLoading />;
  }

  // データの存在を確認
  if (!inspection || !inspectionResult) {
    return null;
  }

  const InspectionComponent = inspectionResult.status === 'completed' ? ViewInspectionResult : EditInspectionResult;

  return (
    <InspectionComponent
      inspection={inspection}
      inspectionManual={inspection.inspectionManual}
      inspectionTable={inspection.table}
      inspectionResult={inspectionResult}
    />
  );
};
