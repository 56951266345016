import {axios} from '@front-libs/core';
import {useQuery} from 'react-query';
import {HospitalSettingKey, HospitalSettingsDetail, UpdateHospitalSettingsParams} from './types';

const getHospitalSettings = async (hospitalHashId: string) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/settings`;
  const {data} = await axios.get<HospitalSettingsDetail>(baseUrl);
  return data;
};

export const updateHospitalSettings = async (hospitalHashId: string, data: UpdateHospitalSettingsParams) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/settings`;
  return axios.post<HospitalSettingsDetail>(baseUrl, data);
};

export const useFetchHospitalSettingsQuery = (hospitalHashId: string) => {
  return useQuery([hospitalHashId, 'hospitalSettings'], () => getHospitalSettings(hospitalHashId), {
    // 以下の変更があったときのみ再レンダリングする
    notifyOnChangeProps: ['data', 'isLoading', 'refetch'],
    refetchOnWindowFocus: false,
  });
};

export const useFetchHospitalSettingQuery = (hospitalHashId: string, key: HospitalSettingKey) => {
  const query = useFetchHospitalSettingsQuery(hospitalHashId);

  return {
    ...query,
    data: query.data ? query.data.data.find((setting) => setting.key === key) : null,
  };
};
