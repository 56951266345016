import {useMemo} from 'react';
import {PermissionLevel, Resource, RESOURCES, RoleResource} from '../types';
import {useMyInfo} from './useMyInfo';

type PermissionResult = {
  canAccess: boolean;
  canRead: boolean;
  canEdit: boolean;
  canDelete: boolean;
};

const getResourcePermissionLevel = (roleResources: RoleResource[], resourceName: string): PermissionLevel => {
  const hasPermission = (isReadOnly: boolean, canDelete: boolean) =>
    roleResources?.some((item) =>
      item.resources.some(
        (resource: Resource) =>
          resource.resource.name === resourceName &&
          resource.isReadOnly === isReadOnly &&
          resource.canDelete === canDelete
      )
    );

  if (hasPermission(false, true)) return PermissionLevel.READ_EDIT_DELETE;
  if (hasPermission(false, false)) return PermissionLevel.READ_EDIT;
  if (hasPermission(true, false)) return PermissionLevel.READ_ONLY;
  return PermissionLevel.NONE;
};

const getPermissionFlags = (level: PermissionLevel) => ({
  canAccess: level > PermissionLevel.NONE,
  canRead: level >= PermissionLevel.READ_ONLY,
  canEdit: level >= PermissionLevel.READ_EDIT,
  canDelete: level >= PermissionLevel.READ_EDIT_DELETE,
});

export const useUserResourcesPermissions = (resource: keyof typeof RESOURCES): PermissionResult => {
  const {myInfo} = useMyInfo();

  if (!myInfo || !myInfo.roleResources) {
    return getPermissionFlags(PermissionLevel.NONE);
  }

  const resourceKey = RESOURCES[resource];
  const level = getResourcePermissionLevel(myInfo.roleResources, resourceKey.id);

  return getPermissionFlags(level);
};
