import {ListScanBarcodeRentalProductsResult} from '@modules/rentals/api';
import {RentalTargetHospitalProducts} from '@Apps/BaseSharedMenu/RentDevices/types';
import {TargetStatusForUnitRelation, UnacceptedStatus} from '@Apps/BaseSharedMenu/types';
import {assertProductStatusToUnacceptedStatus} from '@Apps/BaseSharedMenu/RentDevices/assertions';

export type ValidatedRentalTargetInfo = {
  additionalTargets: RentalTargetHospitalProducts;
  isUnitRelationMode: boolean;
  unitRelationAppendStatus?: TargetStatusForUnitRelation;
  validationError?: UnacceptedStatus;
  managementIdOfErrorOccurred?: string;
};

/**
 * 貸出対象として抽出された機器リストのチェックを行う (親子関係をサポートする)
 */
export const validateRentalTarget = (result: ListScanBarcodeRentalProductsResult): ValidatedRentalTargetInfo => {
  // 対象候補の機器は あらかじめ管理番号でソートしておく (APIでは 親子関係で紐付けられた機器は バーコード値で抽出された機器より後にリストされてるので)
  result.rentableList.sort((a, b) => a.managementId.localeCompare(b.managementId, 'ja'));

  // "not_rentable_list" / "rentable_list" 内に is_unit_relation = true の機器があるか？
  const existsRelationUnitInNotRentable = result.notRentableList.some((i) => i.isUnitRelation);
  const existsRelationUnitInRentable = result.rentableList.some((i) => i.isUnitRelation);

  // ないとき：在来モード(単独貸出・セット貸出モード)
  if (!existsRelationUnitInNotRentable && !existsRelationUnitInRentable) {
    return validateRentalTargetForSingleOrSetProducts(result);
  }

  // あるとき：親子モード
  return validateRentalTargetForUnitRelationProducts(result);
};

/**
 * 在来モード(単独貸出・セット貸出モード) での チェック処理
 */
const validateRentalTargetForSingleOrSetProducts = (
  result: ListScanBarcodeRentalProductsResult
): ValidatedRentalTargetInfo => {
  // "not_rentable_list" 内に 機器があるか？
  if (result.notRentableList.length > 0) {
    // あるとき：エラー → ○○のため貸出出来ません
    const notRentableProduct = result.notRentableList[0];
    return {
      additionalTargets: [],
      validationError:
        notRentableProduct.numberPeriodInspectionResultsInPeriod > 0
          ? 'overdue_inspection'
          : assertProductStatusToUnacceptedStatus(notRentableProduct.status),
      managementIdOfErrorOccurred: notRentableProduct.managementId,
      isUnitRelationMode: false,
    };
  }

  // "rentable_list" 内に 機器があるか？
  if (result.rentableList.length > 0) {
    // あるとき：貸出リストに追加する
    return {
      additionalTargets: result.rentableList,
      isUnitRelationMode: false,
    };
  } else {
    // ないとき：エラー → 機器が見つからなかった旨を表示
    return {
      additionalTargets: [],
      validationError: 'target_not_found',
      isUnitRelationMode: false,
    };
  }
};

/**
 * 親子モードでのチェック処理
 */
const validateRentalTargetForUnitRelationProducts = (
  result: ListScanBarcodeRentalProductsResult
): ValidatedRentalTargetInfo => {
  // "not_rentable_list" 内 に  is_unit_relation = false の機器があるか？
  //  TODO: [FIX ME] 以下、演算コストの面から filter ではなく firstメソッドを array に生やしたい。(条件合致の1個目だけ取得したら終了できる)
  const scannedTargetAndNotRentable = result.notRentableList.filter((i) => !i.isUnitRelation);
  if (scannedTargetAndNotRentable.length > 0) {
    // あるとき：エラー → ○○のため貸出出来ません。
    //  [NOTE] つまり、バーコード読取した機器が 貸出出来ない状態
    const notRentableProduct = scannedTargetAndNotRentable[0];

    return {
      additionalTargets: [],
      validationError:
        notRentableProduct.numberPeriodInspectionResultsInPeriod > 0
          ? 'overdue_inspection'
          : assertProductStatusToUnacceptedStatus(notRentableProduct.status),
      managementIdOfErrorOccurred: notRentableProduct.managementId,
      isUnitRelationMode: true,
    };
  }

  // →貸出リストに 親子関係の機器を追加するため 追加の判定を行う
  let targetOfUnitRelationStatus: TargetStatusForUnitRelation | undefined;

  // "not_rentable_list" 内 に is_unit_relation = true の機器があるか？
  const relationUnitInNotRentable = result.notRentableList.some((i) => i.isUnitRelation);
  if (relationUnitInNotRentable) {
    // あるとき：注意付ダイアログの判定を行う

    // "rentable_list" 内 に is_unit_relation = true の機器があるか？
    const relationUnitInRentable = result.rentableList.some((i) => i.isUnitRelation);
    if (relationUnitInRentable) {
      // あるとき：注意付のダイアログ を表示する (親子関係の内 一部は 貸出出来ない状態)
      targetOfUnitRelationStatus = 'rentalRelationTargetsAppendedExcludeSomeUnits';
    } else {
      // ないとき：注意付のダイアログ を表示 (読取バーコードの機器だけ追加されて 紐付いた機器は全て貸出出来ない状態)
      targetOfUnitRelationStatus = 'rentalRelationTargetsNotAppended';
    }
  } else {
    // ないとき：親子関係が全て読み込まれたダイアログを表示→貸出リスト表示
    targetOfUnitRelationStatus = 'rentalRelationTargetsAppended';
  }

  return {
    additionalTargets: result.rentableList,
    isUnitRelationMode: true,
    unitRelationAppendStatus: targetOfUnitRelationStatus,
  };
};
