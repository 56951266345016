import React, {forwardRef, useMemo} from 'react';
import {Box, MenuItem, Select, SxProps} from '@mui/material';
import {Option} from '@molecules/Selector';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SelectStyle: SxProps = {
  marginTop: '4px',
  padding: '4px 0px 0px',
  marginBottom: '16px',
  width: '100%',
  '& .MuiSelect-select': {
    borderRadius: '10px',
    backgroundColor: '#F3F4F6',
    fontSize: '20px',
    padding: '16px',
    lineHeight: '1.25em',
  },
  '&::before, &::after': {
    borderBottom: 'none',
  },
  '&:hover::before, &:hover::after': {
    borderBottom: 'none !important',
  },
};

const ExpandMoreIconStyle: SxProps = {
  position: 'absolute',
  right: '16px',
};

type Props = {
  name: string;
  selectData: Option<string>[];
  onChange: (val: string) => void;
  value: string;
  error?: boolean;
  helperText?: string;
};
/**
 * 返却待ち受付のセレクトボックスフォーム
 * ReactHookForm使用のため、forwardRefを使用
 */
export const FormSelectBox = forwardRef<HTMLDivElement, Props>(
  ({name, selectData, onChange, value, error, helperText}, ref) => {
    const menuItems = useMemo(
      () =>
        selectData.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        )),
      [selectData]
    );

    return (
      <div ref={ref}>
        <Select
          id={name}
          value={value || ''}
          sx={SelectStyle}
          variant="standard"
          IconComponent={() => <ExpandMoreIcon sx={ExpandMoreIconStyle} />}
          MenuProps={{
            slotProps: {
              paper: {
                sx: {
                  margin: 0,
                  height: `${8 + 36 * 10}px`, // 10行分の高さ 8pxはpaddingTop
                },
              },
            },
          }}
          onChange={(e) => {
            onChange(e.target.value || '');
          }}>
          {menuItems}
        </Select>
        {error && helperText && <Box sx={{color: 'red', marginTop: '4px'}}>{helperText}</Box>}
      </div>
    );
  }
);

FormSelectBox.displayName = 'FromSelectBox'; // デバッグ用にコンポーネント名を設定
