import {MenuItemType, PopperMenuButtonV5} from '@components/molecules/Buttons/PopperMenuButton/PopperMenuButtonV5';
import {WaitingReceptionsStatusType} from '@modules/returns_reservation/type';
import {Box, SxProps} from '@mui/material';
import {FilterButtons} from './FilterButtons';
import React from 'react';
import {ReturnWaitingReceptionsTabData, TabButtons, TabOptionValue} from './TabButtons';

const rootStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const buttonContainerStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
};

const popperButtonStyle: SxProps = {
  color: 'black',
  width: '100%',
  background: '#e7eaee',
  border: '1px solid #C6CBD4',
  minWidth: '150px',
  '&:hover': {
    backgroundColor: '#d5d5d5',
  },
};

const popperItemStyle: SxProps = {
  '&:hover': {
    backgroundColor: 'rgb(226,244,251)',
  },
};

const menuItems = [
  {
    label: '表示項目を変更',
    value: 'changeTableLayout',
  },
];

type ListTableHeaderProps = {
  handleTabChange: (val: TabOptionValue) => void;
  handleFilterChange: (statusList: WaitingReceptionsStatusType[]) => void;
  handleActionMenuClick: (item: MenuItemType) => void;
  tabData: ReturnWaitingReceptionsTabData[];
};

/**
 * 返却待ち受付テーブルのヘッダー
 * 日付タブとフィルターボタン、アクションメニューボタンを表示
 *
 * @param {Function} props.handleTabChange - タブ変更時に呼び出される関数。
 * @param {Function} props.handleFilterChange - フィルター変更時に呼び出される関数。
 * @param {Function} props.handleActionMenuClick - アクションメニュークリック時に呼び出される関数。
 * @returns {JSX.Element} リストテーブルのヘッダーコンポーネント。
 */
export const ListTableHeader = ({
  handleTabChange,
  handleFilterChange,
  handleActionMenuClick,
  tabData,
}: ListTableHeaderProps) => {
  return (
    <Box sx={rootStyle}>
      <Box sx={buttonContainerStyle}>
        <TabButtons handleChange={handleTabChange} tabData={tabData} />
        <FilterButtons onChange={handleFilterChange} />
      </Box>
      <PopperMenuButtonV5<string>
        buttonProps={{
          variant: 'contained',
          disableElevation: true,
          sx: popperButtonStyle,
        }}
        itemProps={{sx: popperItemStyle}}
        menuItemList={menuItems}
        onMenuClick={handleActionMenuClick}>
        アクション
      </PopperMenuButtonV5>
    </Box>
  );
};
