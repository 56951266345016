import React, {useCallback, useState, useMemo} from 'react';
import clsx from 'clsx';
import {Button, createStyles, Grid, makeStyles, Paper, TextField, Theme, Typography} from '@material-ui/core';
import {Formik, Form} from 'formik';
import {CheckCircle, ChevronLeft, Error} from '@material-ui/icons';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {InspectionIndex, InspectionTableIndex} from '@modules/inspections/types';
import {generateInspectionResultPDF, updateInspectionResult} from '@modules/inspection_results/api';
import {InspectionResultIndex, ResultItem} from '@modules/inspection_results/types';
import {sideNavWidth} from '@templates/ContentLayout/InnerSidebarContentLayout';
import {useFetchHospitalProductQuery} from '@modules/hospital_products/api';
import * as yup from 'yup';
import {MainContent} from '@Apps/InspectionResult/pc/ViewInspectionResult/MainContent';
import {openSnackBar} from '@molecules/SnackBar';
import {useNavigate} from 'react-router-dom';
import {formatRFC3339Date, isNullish} from '@front-libs/helpers';
import {InspectionResultStatus} from '@modules/inspection_results/enum';
import {getInspectionStatusMenus} from '@Apps/InspectionResultList/pc/consts';
import * as Yup from 'yup';
import {getItemSchema, getResultMultiSelectItemSchema} from '../../common/validator';
import {useAsyncEffect, useBackPrevious} from '@front-libs/core';
import _ from 'lodash';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {useDeleteInspectionResult} from '@Apps/InspectionResultList/pc/hooks';
import {onlineManager} from 'react-query';
import {styled} from '@material-ui/styles';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {InspectionItem, MultiSelectInspectionItem} from '@modules/inspections/api';
import {fetchFileUrlByPath} from '@modules/files/api';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';

const useResultStatusStyle = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      minWidth: '100px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    status: {
      flex: '0 0 65px',
      borderRadius: '100px',
      padding: '2px 0px',
      fontSize: '12px',
      fontWeight: 'bold',
      textAlign: 'center',
      borderWidth: '2px',
      borderStyle: 'solid',
    },
    unplanned: {
      background: '#feeaed',
      color: '#C7243A',
      borderColor: '#C7243A',
    },
    skipped: {
      background: '#E4E6EC',
      color: '#65676B',
      borderColor: '#65676B',
    },
    uncompleted: {
      background: '#DEEBFF',
      color: '#2A96E8',
      borderColor: '#2A96E8',
    },
    completed: {
      background: '#EAF5EA',
      color: '#3C9E58',
      borderColor: '#3C9E58',
    },
    // NOTE:draftは表示する要件はないがエラーが出たため追加
    draft: {},
  })
);

// TODO: move to shared
type ResultStatusProps = {
  status: InspectionResultStatus;
};

const ResultStatus: React.FC<ResultStatusProps> = (props) => {
  const {status} = props;
  const classes = useResultStatusStyle();

  const text = useMemo(() => getInspectionStatusMenus().find((i) => i.value === status)?.label ?? '', [status]);
  const textClass = useMemo(
    () => (status && status in classes ? clsx(classes.status, classes[status]) : clsx(classes.status)),
    [classes, status]
  );

  return (
    <Grid className={classes.root}>
      <Typography className={textClass} variant="inherit">
        {text}
      </Typography>
    </Grid>
  );
};

type ValidationErrorsProps = {
  inspectionResult: InspectionResultIndex;
  inspectionTable: InspectionTableIndex;
};

type ValidationError = {name: string; valid: boolean};

const ValidationErrors: React.FC<ValidationErrorsProps> = (props) => {
  const {inspectionTable, inspectionResult} = props;

  const [data, setData] = useState<ValidationError[]>([]);

  const GridDiv = styled('div')({
    display: 'grid',
    gridAutoRows: '-webkit-max-content', // Safariのときだけ高さの調整がうまく行かないので修正
    gridTemplateColumns: 'auto 1fr',
    gap: '16px 8px',
  });
  const BlueColorSpan = styled('span')({
    color: '#516F90',
    fontSize: '14px',
  });

  useAsyncEffect(async () => {
    const resultItems: Record<string, ResultItem> = {};
    Object.values(inspectionResult.items).forEach((item) => {
      resultItems[item.id] = item;
    });

    const result = await Promise.all(
      inspectionTable.items.map(async (section): Promise<ValidationError | undefined> => {
        if (section.type !== 'section') return;

        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        const shape: Record<string, any> = {};
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        const value: Record<string, any> = {};
        section.items.forEach((item) => {
          if (!item.id) {
            return;
          }

          const resultItem = resultItems[item.id];
          if (resultItem.type === 'multi-select') {
            shape[item.id] = getResultMultiSelectItemSchema(item as MultiSelectInspectionItem & {id: string}, false);
            value[item.id] = {
              id: item.id,
              values: resultItem.values,
            };
          } else {
            shape[item.id] = getItemSchema(item as InspectionItem & {id: string}, false);
            value[item.id] = {
              id: item.id,
              value: resultItem.value,
            };
          }
        });

        const validator = Yup.object().shape(shape);
        const isValid = await validator
          .validate(value)
          .then(() => {
            return true;
          })
          .catch((error) => {
            return false;
          });

        return {
          name: section.name,
          valid: isValid,
        };
      })
    );

    setData(result.filter((r): r is ValidationError => r !== undefined));
  }, [inspectionResult]);

  return (
    <GridDiv>
      {data.map((x) => (
        <React.Fragment key={x.name}>
          <BlueColorSpan>{x.name}</BlueColorSpan>
          <span>
            {x.valid ? (
              <CheckCircle style={{color: '#3C9E58', fontSize: '16px'}} />
            ) : (
              <Error style={{color: '#C7243A', fontSize: '16px'}} />
            )}
          </span>
        </React.Fragment>
      ))}
    </GridDiv>
  );
};
// NOTE:/Apps/InspectionTemplates/TemplateList/TemplateSelector.tsxのtypeToTextと同義
const typeToText = {
  pre_use: '使用前点検',
  in_use: '使用中点検',
  post_use: '使用後点検',
  periodic: '定期点検',
  // TODO: 以下型定義の為だけに定義
  maker_periodic: '',
  daily: '',
  'pre_use,in_use,post_use': '',
} as const;

const useSideNavStyle = makeStyles((theme: Theme) => ({
  root: {
    height: 'calc(100% - 82px)',
    paddingBottom: '82px',
    overflowY: 'auto',
  },
  actionMenuContainer: {
    paddingTop: '8px',
  },
  actionMenu: {
    color: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  inspectionInfo: {
    padding: '24px 16px',
    minHeight: '120px',
    '& > *:not(:first-child)': {
      marginTop: '8px',
    },
  },
  inspectionResult: {
    padding: '24px 16px',
    '& > *:not(:first-child)': {
      marginTop: '8px',
    },
  },
  inspectionName: {
    fontSize: '16px',
    fontWeight: 'bold',
    fontColor: theme.palette.primary.dark,
  },
  divider: {
    width: '100%',
    marginLeft: '0px',
    marginRight: '0px',
  },
  form: {
    padding: '24px 16px',
    '& > *:not(:first-child)': {
      marginTop: '16px',
    },
  },
  formTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    fontColor: theme.palette.primary.dark,
  },
  managementId: {
    display: 'inline',
    color: theme.palette.info.dark,
    fontWeight: 'bold',
  },
  managementIdLink: {
    cursor: 'pointer',
  },
  commentInput: {
    padding: '24px 16px',
  },
}));

const actionMenuItems = (canDelete: boolean, isAdmin: boolean) =>
  (FEATURE_CUSTOM_ASSET_ROLE_FLAG && canDelete) || (!FEATURE_CUSTOM_ASSET_ROLE_FLAG && isAdmin)
    ? [
        {
          label: 'PDFをダウンロード',
          value: 'download_pdf',
        },
        {
          label: '削除',
          value: 'delete',
        },
      ]
    : [
        {
          label: 'PDFをダウンロード',
          value: 'download_pdf',
        },
      ];

type SideNavProps = {
  inspection: InspectionIndex;
  inspectionResult: InspectionResultIndex;
  inspectionTable: InspectionTableIndex;
};

/**
 * 左サイドナビ
 * @param SideNavProps
 * @returns
 */
export const SideNav: React.FC<SideNavProps> = ({inspection, inspectionResult, inspectionTable}) => {
  const classes = useSideNavStyle();
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();
  const {isReadOnly, isAdmin} = useMyRole();
  const {canEdit: canEditInspection, canDelete: canDeleteInspection} = useUserResourcesPermissions('INSPECTION');
  const {data: hospitalProduct} = useFetchHospitalProductQuery(
    myInfo.hospitalHashId,
    inspectionResult.hospitalProductHashId
  );
  const deleteInspectionResult = useDeleteInspectionResult(myInfo.hospitalHashId, [inspectionResult]);
  const goBackToResultsPage = useBackPrevious('/inspection_v2/results');

  const defaultComment = inspectionResult?.comment ?? '';
  const [comment, setComment] = useState(defaultComment);
  const isOnline = onlineManager.isOnline();

  // const {values} = useFormikContext<FormValue>();

  const FontSizeTypography = styled(Typography)({
    fontSize: '16px',
  });

  const FontBoldMixin = {
    fontSize: '16px',
    fontWeight: 700, // NOTE:Mixinでboldを指定できないので数値で
  };

  const BoldFontTypography = styled(Typography)({
    ...FontBoldMixin,
  });
  const BoldFontML4Typography = styled(Typography)({
    ...FontBoldMixin,
    marginLeft: '4px',
  });

  const updatedAt = useMemo(() => {
    return formatRFC3339Date(inspectionResult?.updatedAt, 'YYYY/MM/DD HH:mm') ?? '';
  }, [inspectionResult.updatedAt]);

  const completedAt = useMemo(() => {
    return formatRFC3339Date(inspectionResult?.completedAt, 'YYYY/MM/DD HH:mm') ?? '';
  }, [inspectionResult]);

  const inspector = useMemo(() => {
    if (inspectionResult?.inspector) {
      return `${inspectionResult.inspector.lastName} ${inspectionResult.inspector.firstName}`;
    }
    return '';
  }, [inspectionResult]);

  const handleClickPrevButton = useCallback(() => {
    goBackToResultsPage();
  }, [goBackToResultsPage]);

  const handleClickManagementID = useCallback(() => {
    if (hospitalProduct) {
      navigate(`/products/${hospitalProduct.hashId}`);
    }
  }, [hospitalProduct, navigate]);

  const handleChangeComment = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  }, []);

  const handleSubmitComment = useCallback(
    async (e: React.MouseEvent) => {
      try {
        await updateInspectionResult(myInfo.hospitalHashId, inspection.hashId, inspectionResult.hashId, {
          comment: comment,
        });
        openSnackBar('コメントを更新しました');
      } catch (error) {
        console.error(error);

        openSnackBar('コメント更新に失敗しました', 'left', 'bottom', 'error');
      }
    },
    [myInfo, inspection, inspectionResult, comment]
  );

  const downloadPDF = useCallback(
    async (hospitalHashId: string, inspectionHashId: string, inspectionResultHashId: string) => {
      try {
        const res = await generateInspectionResultPDF(hospitalHashId, inspectionHashId, inspectionResultHashId);

        const {redirectUrl} = await fetchFileUrlByPath(res.data.s3DownloadPath);
        if (isNullish(redirectUrl)) return;

        const link = document.createElement('a');
        link.href = redirectUrl;
        link.target = 'downloadIframe';
        link.setAttribute('download', '');
        link.click();
      } catch (error) {
        console.error(error);

        openSnackBar('ファイル生成に失敗しました', 'left', 'bottom', 'error');
      }
    },
    []
  );

  const handleActionMenuClick = useCallback(
    async (item: MenuItemType) => {
      switch (item.value) {
        case 'delete':
          await deleteInspectionResult([inspectionResult.hashId], inspectionResult.status);
          goBackToResultsPage();
          break;
        case 'download_pdf':
          await downloadPDF(myInfo.hospitalHashId, inspectionResult.inspectionHashId, inspectionResult.hashId);
          break;
      }
    },
    [
      deleteInspectionResult,
      inspectionResult.hashId,
      inspectionResult.status,
      inspectionResult.inspectionHashId,
      goBackToResultsPage,
      downloadPDF,
      myInfo.hospitalHashId,
    ]
  );

  return (
    <Paper className={classes.root}>
      <Grid container justifyContent={'space-between'} className={classes.actionMenuContainer}>
        <Grid item>
          <Button color="inherit" className={classes.actionMenu} onClick={handleClickPrevButton}>
            <ChevronLeft />
            <span>点検実績に戻る</span>
          </Button>
        </Grid>
        <Grid item>
          <PopperMenuButton
            buttonProps={{color: 'inherit', className: classes.actionMenu}}
            menuItemList={actionMenuItems(canDeleteInspection, isAdmin)}
            onMenuClick={handleActionMenuClick}>
            アクション
          </PopperMenuButton>
        </Grid>
      </Grid>
      <Grid container direction="column" className={classes.inspectionInfo}>
        <Grid item>
          <Typography variant={'h5'} className={classes.inspectionName}>
            {inspection.name}
          </Typography>
        </Grid>
        <Grid item>
          <ResultStatus status={inspectionResult.status} />
        </Grid>
        <Grid item container>
          <FontSizeTypography>{'点検タイプ:'}</FontSizeTypography>
          <Typography style={{marginLeft: '4px', fontSize: '16px', fontWeight: 'bold'}}>
            {inspection.type ? typeToText[inspection.type] : ''}
          </Typography>
        </Grid>
        <Grid
          item
          container
          className={clsx(hospitalProduct && classes.managementIdLink)}
          onClick={handleClickManagementID}>
          <FontSizeTypography>{'管理番号:'}</FontSizeTypography>
          {hospitalProduct?.managementId && (
            <Typography className={classes.managementId} style={{marginLeft: '4px'}}>
              {hospitalProduct.managementId}
            </Typography>
          )}
        </Grid>
        <Grid item container>
          <FontSizeTypography>{'点検者:'}</FontSizeTypography>
          <BoldFontML4Typography>{inspector}</BoldFontML4Typography>
        </Grid>
        <Grid item container>
          <FontSizeTypography>{'点検日時:'}</FontSizeTypography>
          <BoldFontML4Typography>{completedAt}</BoldFontML4Typography>
        </Grid>
        <Grid item container>
          <FontSizeTypography>{'最終編集者:'}</FontSizeTypography>
          <BoldFontML4Typography>{`${UserFormatter.getFullName(inspectionResult?.updatedBy)}`}</BoldFontML4Typography>
        </Grid>
        <Grid item container>
          <FontSizeTypography>{'最終編集日時:'}</FontSizeTypography>
          <BoldFontML4Typography>{updatedAt}</BoldFontML4Typography>
        </Grid>
      </Grid>
      <Grid container direction="column" className={classes.inspectionResult}>
        <Grid item>
          <BoldFontTypography variant={'h5'}>概要</BoldFontTypography>
        </Grid>
        <ValidationErrors inspectionResult={inspectionResult} inspectionTable={inspectionTable} />
      </Grid>
      <Grid container direction="column" className={classes.commentInput}>
        <Grid item>
          <BoldFontTypography variant={'h5'}>コメント</BoldFontTypography>
        </Grid>
        <TextField
          multiline
          defaultValue={defaultComment}
          value={comment}
          onChange={handleChangeComment}
          style={{marginTop: '16px'}}
          InputProps={{
            readOnly:
              !isOnline ||
              (FEATURE_CUSTOM_ASSET_ROLE_FLAG && !canEditInspection) ||
              (!FEATURE_CUSTOM_ASSET_ROLE_FLAG && isReadOnly),
          }}
        />
        <Grid item container justifyContent="flex-end" style={{marginTop: '16px'}}>
          {isOnline &&
            ((FEATURE_CUSTOM_ASSET_ROLE_FLAG && canEditInspection) ||
              (!FEATURE_CUSTOM_ASSET_ROLE_FLAG && !isReadOnly)) && (
              <Button
                variant="contained"
                color="primary"
                disabled={defaultComment === comment}
                onClick={handleSubmitComment}
                style={{width: '80px'}}>
                保存
              </Button>
            )}
        </Grid>
        {/* ApprovalRouteCard TESTのためコメントアウト サーバー側対応後に別途対応予定 */}
        {/* <Grid item container style={{marginTop: '20px'}}>
          <ApprovalRouteCard
            index={1}
            title={'一次承認者'}
            text={'点検作業お疲れ様でした！点検ない内容は問題ないので承認します！'}
            date={`2023-10-01 12:34:56`}
            name={'長谷川 遼'}
            approvalRouteStatus={{state: 'remand', isApproval: false}}
            approvalRouteCardCommentList={[
              {
                title: '一次承認者',
                name: '田中 太郎',
                date: '2023-09-27 10:15:30',
                text: 'この内容で問題ありません。承認します。',
              },
              {
                title: '二次承認者',
                name: '佐藤 次郎',
                date: '2023-09-28 11:20:40',
                text: '内容を確認しました。承認します。',
              },
            ]}
            onClick={(state: ApprovalRouteState, comments?: string) => {
              console.log(state, comments);
            }}
          />
        </Grid> */}
        {/* TEST */}
      </Grid>
    </Paper>
  );
};

const useContentTemplate = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      overflowY: 'hidden',
    },
    sideNav: {
      width: sideNavWidth,
      height: `calc(100vh - 56px)`,
      overflowY: 'auto',
    },
    content: {
      width: `calc(100% - ${sideNavWidth}px)`,
      height: `calc(100vh - 56px)`,
      overflowY: 'auto',
    },
  })
);

type InspectionResultFormProps = {
  inspection: InspectionIndex;
  inspectionTable: InspectionTableIndex;
  inspectionResult: InspectionResultIndex;
};

const InspectionResultForm: React.FC<InspectionResultFormProps> = ({inspection, inspectionTable, inspectionResult}) => {
  const templateClasses = useContentTemplate();

  return (
    <Grid container className={templateClasses.root}>
      <Grid item className={templateClasses.sideNav}>
        <SideNav inspection={inspection} inspectionResult={inspectionResult} inspectionTable={inspectionTable} />
      </Grid>
      <Grid item className={templateClasses.content}>
        <MainContent inspectionTable={inspectionTable} inspectionResult={inspectionResult} />
      </Grid>
    </Grid>
  );
};

type FormValue = {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  newValues: any;
};

type InspectionResultDetailContainerProps = {
  inspectionResult: InspectionResultIndex;
  onSubmit: (data: FormValue) => void;
};

const InspectionResultDetailContainer: React.FC<InspectionResultDetailContainerProps> = ({onSubmit, children}) => {
  const validationSchema = yup.object({
    newValues: yup.object().optional(),
  });

  return (
    <Formik
      initialValues={{newValues: {}}}
      validationSchema={validationSchema}
      isInitialValid={false}
      enableReinitialize={true}
      onSubmit={onSubmit}>
      <Form style={{width: '100%', height: '100%'}}>{children}</Form>
    </Formik>
  );
};

type ViewInspectionResultProps = {
  inspection: InspectionIndex;
  inspectionTable: InspectionTableIndex;
  inspectionResult: InspectionResultIndex;
  onResultUpdate: () => void;
};

export const ViewInspectionResult: React.FC<ViewInspectionResultProps> = (props) => {
  const {inspection, inspectionTable, inspectionResult, onResultUpdate} = props;
  const templateClasses = useContentTemplate();
  const {myInfo} = useMyInfo();

  const handleSubmit = useCallback(
    async (data: FormValue) => {
      const itemsMap: Record<string, ResultItem> = {};
      inspectionResult.items.forEach((item) => {
        itemsMap[item.id] = item;
      });

      _.each(data.newValues, (value, key) => {
        const prevItem = itemsMap[key];
        if (!prevItem) {
          return;
        }

        switch (prevItem.type) {
          case 'multi-select': {
            const values = value as string[];
            itemsMap[key] = {
              ...prevItem,
              // HIT869対応 初期値をnullに統一
              values: values.length !== 0 ? values : null,
            };

            break;
          }
          case 'time': {
            const timeValue = value as string;

            itemsMap[key] = {
              ...prevItem,
              // HIT869対応 空文字の場合バックエンドでパースエラーが発生し、保存できない対応。
              value: timeValue !== '' ? `${timeValue}:00` : null,
            };

            break;
          }
          default:
            itemsMap[key] = {
              ...prevItem,
              value: value as string,
            };

            break;
        }
      });

      try {
        await updateInspectionResult(myInfo.hospitalHashId, inspection.hashId, inspectionResult.hashId, {
          items: _.values(itemsMap),
        });

        onResultUpdate();
        openSnackBar('点検実績を更新しました');
      } catch (error) {
        openSnackBar('点検実績の更新に失敗しました', 'left', 'bottom', 'error');
        throw error;
      }
    },
    [inspection.hashId, inspectionResult.hashId, inspectionResult.items, myInfo.hospitalHashId, onResultUpdate]
  );

  return (
    <Grid container className={templateClasses.root}>
      <InspectionResultDetailContainer inspectionResult={inspectionResult} onSubmit={handleSubmit}>
        <InspectionResultForm
          inspection={inspection}
          inspectionTable={inspectionTable}
          inspectionResult={inspectionResult}
        />
      </InspectionResultDetailContainer>
    </Grid>
  );
};
