import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {useGetUncompletedInspectionsByInspectionTypeQuery} from '@modules/dashboard/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {CircularProgress, Grid, makeStyles, Paper} from '@material-ui/core';
import {styled} from '@material-ui/styles';
import {UpdatePaperType, useUpdateDashboardSubject} from '../hooks';
import {PapersType} from '../types';
import {PaperHeader} from '../PaperHeader';
import {papers} from '../consts';
import {useNavigate} from 'react-router-dom';
import {useInspectionType, useScheduledTimeRange} from '@Apps/InspectionResultList/pc/InternalInspection/states/states';
import dayjs from 'dayjs';
import {convertDateToRFC3339} from '@front-libs/helpers';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';

type Series = {
  total: {
    y: number; // 割合
    value: number; // 件数
  };
  uninspected: {
    y: number;
    value: number;
  };
};

type ChartSeriesData = {
  postUse: Series;
  periodic: Series;
};

const gaugeOptions: (onClickChart: () => void) => Highcharts.Options = (onClickChart) => ({
  chart: {
    type: 'solidgauge',
    height: '200',
    events: {
      click: onClickChart,
    },
  },

  title: undefined,

  pane: {
    center: ['50%', '85%'],
    size: '100%',
    startAngle: -90,
    endAngle: 90,
    background: [
      {
        backgroundColor: '#eeeeee',
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc',
      },
    ],
  },

  exporting: {
    enabled: true,
  },

  tooltip: {
    enabled: false,
  },

  // the value axis
  yAxis: {
    stops: [
      // FIXME: グラフの色はHITOTSUのデザインに合わせる
      [0, '#3C9E58'], // green
      [0.5, '#FF9800'], // yellow
      [0.8, '#C7243A'], // red
    ],
    lineWidth: 0,
    tickWidth: 0,
    minorTickInterval: null,
    tickAmount: 2,
    title: {
      y: -70,
    },
    labels: {
      y: 16,
    },
    cursor: 'pointer',
  },

  plotOptions: {
    solidgauge: {
      dataLabels: {
        y: 5,
        borderWidth: 0,
        useHTML: true,
      },
      cursor: 'pointer',
    },
  },
});

const initialSeries: Series = {
  total: {
    y: 100, // 総数側の割合の初期値は、データがなくてもグラフが表示されるように100%にしておく
    value: 0,
  },
  uninspected: {
    y: 0,
    value: 0,
  },
} as const;

type Props = {
  durationType: 'monthly' | 'yearly';
  inspectionTypes: ('post_use' | 'periodic')[];
};

export const InspectionProgressByType: React.FC<Props> = ({durationType, inspectionTypes}) => {
  const navigate = useNavigate();
  const {canAccess: canAccessInspection} = useUserResourcesPermissions('INSPECTION');
  if (FEATURE_CUSTOM_ASSET_ROLE_FLAG) {
    useEffect(() => {
      if (!canAccessInspection) {
        navigate('/dashboard');
      }
    }, [canAccessInspection, navigate]);
    if (!canAccessInspection) {
      return null;
    }
  }
  // FIXME: Query parameterでinspectionTypeを指定できる様になったらこのロジックを削除する
  const [, setInspectionType] = useInspectionType();
  const [, setScheduledTimeRange] = useScheduledTimeRange();
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const {subscribe, unsubscribe} = useUpdateDashboardSubject();

  const {data, isLoading, refetch} = useGetUncompletedInspectionsByInspectionTypeQuery(
    myInfo.hospitalHashId,
    durationType
  );
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const chartComponentRef2 = useRef<HighchartsReact.RefObject>(null);

  useEffect(() => {
    const current = chartComponentRef.current;
    if (!isLoading) current?.chart.reflow();
  }, [isLoading]);

  const paper = durationType === 'monthly' ? papers.monthlyInspectionPercent : papers.yearlyInspectionPercent;

  const series = useMemo(
    () =>
      (data ?? []).reduce<ChartSeriesData>(
        (prev, curr) => {
          const currSeries: Series = {
            total: {
              value: curr.numberOfTotalInspection,
              y: 100,
            },
            uninspected: {
              value: curr.numberOfUncompletedInspection,
              y:
                curr.numberOfTotalInspection === 0
                  ? curr.numberOfTotalInspection
                  : Math.round((curr.numberOfUncompletedInspection / curr.numberOfTotalInspection) * 100),
            },
          };
          if (curr.inspectionType === 'periodic') {
            prev.periodic = currSeries;
          } else if (curr.inspectionType === 'post_use') {
            prev.postUse = currSeries;
          }
          return prev;
        },
        {postUse: initialSeries, periodic: initialSeries}
      ),
    [data]
  );

  const onUpdateDashboardSubject = useCallback(
    (paperType: UpdatePaperType) => {
      const thisPaperType: PapersType =
        durationType === 'monthly' ? 'monthly_inspection_percent' : 'yearly_inspection_percent';
      if (paperType !== 'all' && paperType !== thisPaperType) return;

      refetch();
    },
    [refetch, durationType]
  );

  useEffect(() => {
    subscribe(onUpdateDashboardSubject);
    return () => unsubscribe(onUpdateDashboardSubject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickChart = useCallback(
    (inspectionType: 'post_use' | 'periodic') => {
      setInspectionType(inspectionType);
      let durationQuery = '';

      if (durationType === 'monthly') {
        const monthFirstDay = dayjs().startOf('month').toDate();
        const monthLastDay = dayjs().endOf('month').toDate();
        setScheduledTimeRange([monthFirstDay, monthLastDay]);
        durationQuery = `scheduledTimeFrom=${convertDateToRFC3339(
          monthFirstDay
        )}&scheduledTimeTo=${convertDateToRFC3339(monthLastDay)}`;
      } else {
        const yearFirstDay = dayjs().startOf('year').toDate();
        const yearLastDay = dayjs().endOf('year').toDate();
        setScheduledTimeRange([yearFirstDay, yearLastDay]);
        durationQuery = `scheduledTimeFrom=${convertDateToRFC3339(yearFirstDay)}&scheduledTimeTo=${convertDateToRFC3339(
          yearLastDay
        )}`;
      }

      navigate(`/inspection_v2/results?type=${inspectionType}&${durationQuery}`);
    },
    [durationType, navigate, setInspectionType, setScheduledTimeRange]
  );

  const periodicInspectionOptions = useMemo(
    () =>
      Highcharts.merge(
        gaugeOptions(() => {
          handleClickChart('periodic');
        }),
        {
          yAxis: {
            min: 0,
            max: 100,
            title: {
              text: '定期点検',
            },
          },
          credits: {
            enabled: false,
          },
          series: [
            {
              name: '定期点検',
              // FIXME: ％を指定
              data: [series.periodic.uninspected.y],
              dataLabels: {
                format:
                  '<div style="text-align:center">' +
                  '<span style="font-size:25px">{y}%</span><br/>' +
                  '<span style="font-size:14px">' +
                  `(${series.periodic.uninspected.value}/${series.periodic.total.value})` + // FIXME: データを指定
                  '</span>' +
                  '</div>',
              },
              tooltip: {
                valueSuffix: '%',
              },
            },
          ],
        }
      ),
    [handleClickChart, series.periodic.total.value, series.periodic.uninspected.value, series.periodic.uninspected.y]
  );

  const postUseInspectionOptions = useMemo(
    () =>
      Highcharts.merge(
        gaugeOptions(() => {
          handleClickChart('post_use');
        }),
        {
          yAxis: {
            min: 0,
            max: 100,
            title: {
              text: '使用後点検',
            },
          },
          credits: {
            enabled: false,
          },
          series: [
            {
              name: '使用後点検',
              // FIXME: ％を指定
              data: [series.postUse.uninspected.y],
              dataLabels: {
                format:
                  '<div style="text-align:center">' +
                  '<span style="font-size:25px">{y}%</span><br/>' +
                  `<span style="font-size:14px">(${series.postUse.uninspected.value}/${series.postUse.total.value})</span>` + // FIXME: ％を指定
                  '</div>',
              },
              tooltip: {
                valueSuffix: '%',
              },
            },
          ],
        }
      ),
    [handleClickChart, series.postUse.total.value, series.postUse.uninspected.value, series.postUse.uninspected.y]
  );

  return (
    <Paper className={classes.paper}>
      <PaperHeader title={paper.label} />
      <div className={classes.root}>
        {isLoading ? (
          <LoadingGrid container justifyContent="center" alignContent="center">
            <CircularProgress />
          </LoadingGrid>
        ) : (
          <Grid container>
            {inspectionTypes.includes('periodic') && (
              <Grid
                item
                xs={6}
                style={{height: '200px', width: 320, cursor: 'pointer'}}
                onClick={() => handleClickChart('periodic')}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={periodicInspectionOptions}
                  ref={chartComponentRef}
                  style={{height: '200px', width: 320, cursor: 'pointer'}}
                />
              </Grid>
            )}
            {inspectionTypes.includes('post_use') && (
              <Grid
                item
                xs={6}
                style={{height: '200px', width: 320, cursor: 'pointer'}}
                onClick={() => handleClickChart('post_use')}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={postUseInspectionOptions}
                  ref={chartComponentRef2}
                  style={{height: '200px', width: 320}}
                />
              </Grid>
            )}
          </Grid>
        )}
      </div>
    </Paper>
  );
};

const LoadingGrid = styled(Grid)({
  height: 120,
});

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '0px 16px 16px',
    borderTop: `4px solid ${theme.palette.primary.dark}`,
    height: `calc(100% - 20px)`,
  },
  root: {
    lineHeight: '24px',
  },
  headerInspectionType: {
    width: 80,
    paddingRight: 8,
  },
  headerAmount: {
    width: 40,
    paddingRight: 8,
  },
}));
