import React, {useMemo, useCallback} from 'react';
import {Box, Divider, Grid, makeStyles, Typography} from '@material-ui/core';
import {Sidebar} from '@components/organisms/Sidebar';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useSettingsContentTemplate} from '@templates/ContentLayout/InnerSidebarContentLayout';
import {
  updateNotificationSettings,
  UpdateNotificationSettingsParams,
  useFetchNotificationSettingsQuery,
} from '@modules/notification_settings/api';
import {InnerLoading} from '@components/molecules/Loading';
import {Formik} from 'formik';
import {openSnackBar} from '@components/molecules/SnackBar';
import {FormikFormSubmitDrawer} from '@components/molecules/Formik/FormSubmitDrawer';
import {NotificationSettingTableForm} from './NotificationSettingTableForm';
import clsx from 'clsx';

export type NotificationSettingsFormType = {
  isEnabledFaultRepairPic: boolean;
  isEnabledRentNotification: boolean;
  isEnabledReturnNotification: boolean;
  isEnabledInspectionReminder: boolean;
  xDaysAgoInspectionReminder: number;
  isEnabledPmdaUploadNotification: boolean;
  isEnabledPmdaRecallNotification: boolean;
  isEnabledFaultReceptionNotification: boolean;
  isEnabledReturnWaitingNotification: boolean;
  // FIXME: ワーカーサーバ動き出したり、要件確定していったら追加してく
  // isEnabledRentalAmountNotification: boolean;
};

export const AccountNotificationSetting = () => {
  const {myInfo} = useMyInfo();
  const {data, isLoading, refetch} = useFetchNotificationSettingsQuery(myInfo.hospitalHashId, myInfo.hashId);
  const templateClasses = useSettingsContentTemplate();
  const classes = useStyles();

  const handleSubmit = useCallback(
    async (res: NotificationSettingsFormType) => {
      try {
        const updateData: UpdateNotificationSettingsParams[] = [
          {notificationType: 'fault_repair_pic_notification', isEnabled: res.isEnabledFaultRepairPic},
          {notificationType: 'rent_notification', isEnabled: res.isEnabledRentNotification},
          {notificationType: 'return_notification', isEnabled: res.isEnabledReturnNotification},
          {
            notificationType: 'inspection_reminder',
            isEnabled: res.isEnabledInspectionReminder,
            additionalCondition: {
              xDaysAgo: res.xDaysAgoInspectionReminder,
            },
          },
          {notificationType: 'pmda_upload_notification', isEnabled: res.isEnabledPmdaUploadNotification},
          {notificationType: 'pmda_recall_notification', isEnabled: res.isEnabledPmdaRecallNotification},
          {notificationType: 'fault_reception_notification', isEnabled: res.isEnabledFaultReceptionNotification},
          {
            notificationType: 'return_waiting_reception_notification',
            isEnabled: res.isEnabledReturnWaitingNotification,
          },
          // FIXME: ワーカーサーバ動き出したり、要件確定していったら追加してく
          // {notificationType: 'rental_amount_notification', isEnabled: res.isEnabledRentalAmountNotification},
        ];
        await updateNotificationSettings(myInfo.hospitalHashId, myInfo.hashId, updateData);
        await refetch();
        openSnackBar('通知設定を更新しました');
      } catch (error) {
        openSnackBar('通知設定の更新に失敗しました', 'left', 'bottom', 'error');
        throw error;
      }
    },
    [myInfo.hashId, myInfo.hospitalHashId, refetch]
  );

  const initialData = useMemo<NotificationSettingsFormType>(() => {
    return {
      isEnabledFaultRepairPic: data?.faultRepairPicNotification.isEnabled ?? false,
      isEnabledRentNotification: data?.rentNotification.isEnabled ?? false,
      isEnabledReturnNotification: data?.returnNotification.isEnabled ?? false,
      isEnabledInspectionReminder: data?.inspectionReminder.isEnabled ?? false,
      xDaysAgoInspectionReminder: data?.inspectionReminder.xDaysAgo ?? 0,
      isEnabledPmdaUploadNotification: data?.pmdaUploadNotification.isEnabled ?? false,
      isEnabledPmdaRecallNotification: data?.pmdaRecallNotification.isEnabled ?? false,
      isEnabledFaultReceptionNotification: data?.faultReceptionNotification.isEnabled ?? false,
      isEnabledReturnWaitingNotification: data?.returnWaitingReceptionNotification.isEnabled ?? false,
      // FIXME: ワーカーサーバ動き出したり、要件確定していったら追加してく
      // isEnabledRentalAmountNotification: data?.rentalAmountNotification.isEnabled ?? false,
    };
  }, [data]);

  return (
    <Grid container className={templateClasses.grid}>
      {isLoading || !data ? (
        <InnerLoading />
      ) : (
        <Formik<NotificationSettingsFormType>
          initialValues={initialData}
          onSubmit={handleSubmit}
          enableReinitialize={true}>
          {(props) => (
            <>
              <Box className={clsx(templateClasses.form, classes.root)}>
                <Grid container className={templateClasses.grid}>
                  <Grid item className={templateClasses.sideBar}>
                    <Sidebar />
                  </Grid>
                  <Grid item className={templateClasses.content}>
                    <Grid container>
                      <Grid item>
                        <Typography variant={'h1'} className={classes.pageTitle}>
                          通知
                        </Typography>
                        <p>これらの設定は自分のみに適用されます。</p>
                      </Grid>
                    </Grid>
                    <Divider variant="middle" className={templateClasses.divider} />
                    <Typography variant={'h2'} className={classes.heading2}>
                      通知を受け取る内容
                    </Typography>
                    <Typography variant={'subtitle1'} className={classes.subtitle1}>
                      自分にとって重要な内容について通知を受け取る方法を選択します。
                    </Typography>
                    <NotificationSettingTableForm {...props} />
                  </Grid>
                </Grid>
              </Box>
              <FormikFormSubmitDrawer />
            </>
          )}
        </Formik>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((_theme) => ({
  root: {
    overflowY: 'auto',
  },
  pageTitle: {
    fontSize: 28,
  },
  heading2: {
    fontSize: 24,
    fontWeight: 'bold',
    marginTop: 48,
  },
  subtitle1: {
    fontSize: 12,
  },
}));
