import {Box, SxProps} from '@mui/material';
import React, {useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {ChildUnitSelectionDialog} from './components/ChildUnitSelectionDialog';
import {HeaderComponent} from './components/HeaderComponent';
import {ChildUnitFormValue, useChildUnitForm} from './useChildUnitForm';
import {ChildUnitTable} from './components/ChildUnitTable';
import {postUnitRelations} from '@modules/unit_relations/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {openSnackBar} from '@front-libs/ui';
import axios from 'axios';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

const rootStyle: SxProps = {
  backgroundColor: '#fff',
  width: '100vw',
  height: '100vh',
};

const contentAreaStyle: SxProps = {
  margin: '24px 32px',
  height: 'calc(100% - 140px)',
  overflow: 'hidden',
};

/** エラーコードとエラーメッセージ */
const errorMessages: Record<number, string> = {
  400: 'こちらの機器は子機ではありません。',
  409: 'こちらの子機は既に親機に紐づいているため、登録できません。',
  403: '入力された管理番号の機器は存在しません。',
  412: '親機の機器情報が更新されたため、子機を登録できません。機器詳細を開き直してご確認ください。',
  404: '親機の機器情報が存在しないため、子機を登録できません。機器一覧で機器の稼働状況をご確認ください。',
};

/**
 * エラーコードに対応するエラーメッセージを取得する
 * @param statusCode エラーコード
 * @returns エラーメッセージ
 */
const getErrorMessage = (statusCode: number): string => {
  return errorMessages[statusCode] || '予期しないエラーが発生しました。';
};

/**
 * 関連する子機の一覧・編集画面
 * @returns
 */
export const ProductChildDeviceEditor = () => {
  const navigate = useNavigate();
  const {hashId} = useParams();
  const {myInfo} = useMyInfo();
  const {isReadOnly} = useMyRole();
  const childRef = useRef<{refetchData: VoidFunction}>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const {handleSubmit, register, errors, isValid, reset} = useChildUnitForm();
  const [statusErrorMessage, setStatusErrorMessage] = useState('');

  const onSubmit = async (data: ChildUnitFormValue) => {
    if (!hashId) return;

    try {
      await postUnitRelations(myInfo.hospitalHashId, hashId, data);
      onCloseDialog();
      openSnackBar('関連する子機を登録しました', 'success', {
        onRendered: () => {
          // 子機の追加が成功したら、子機一覧を再取得する
          childRef.current?.refetchData();
        },
      });
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        setStatusErrorMessage(getErrorMessage(e.response.status));
      } else {
        setStatusErrorMessage('予期しないエラーが発生しました。');
      }
    }
  };

  const handleClickPrevButton = () => {
    navigate(`/products/${hashId}`);
  };

  const handleClickNextButton = () => {
    setIsDialogOpen(true);
  };

  const onCloseDialog = () => {
    reset();
    clearErrorMessage();
    setIsDialogOpen(false);
  };

  const clearErrorMessage = () => {
    setStatusErrorMessage('');
  };

  return (
    <Box sx={rootStyle}>
      <HeaderComponent
        onClickPrevButton={handleClickPrevButton}
        onClickNextButton={handleClickNextButton}
        hideChildUnitButton={isReadOnly}
      />
      <Box sx={contentAreaStyle}>
        <ChildUnitTable hashId={hashId ?? ''} hideDeleteButton={isReadOnly} ref={childRef} />
      </Box>
      <ChildUnitSelectionDialog
        open={isDialogOpen}
        onSubmit={handleSubmit(onSubmit)}
        onClose={onCloseDialog}
        clearErrorMessage={clearErrorMessage}
        register={register}
        errorMessage={errors?.childProductManagementId?.message ?? statusErrorMessage}
        isValid={isValid}
      />
    </Box>
  );
};
