import {HospitalDepartMent} from '../hospital_departments/types';
import {HospitalProductIndex} from '../hospital_products/types';
import {UserIndex} from '../hospital_users/types';
import {repairLogTypeEnum} from './constants';

export type SymptomCategoryType =
  | 'external_damage'
  | 'internal_failure'
  | 'maintenance_required'
  | 'component_deficiency'
  | 'lost'
  | 'no_issue'
  | 'others';

export type SymptomDetailCategoryType =
  | 'power_failure'
  | 'self_check_failure'
  | 'no_output'
  | 'malfunction'
  | 'abnormal_behavior'
  | 'charging_issue'
  | 'others';

export type RootCauseCategoryType =
  | 'degradation'
  | 'staff_related_cause'
  | 'patient_related_cause'
  | 'inspection_not_performed'
  | 'unknown_cause'
  | 'others';

export type DealtCategoryType = 'no_abnormality' | 'internal_repair' | 'request_to_maker' | 'unrepairable';

export type FaultRepairStatusType = {
  hashId: string;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  hospitalHashId: string;
  statusType: string;
  statusName: string;
  sequence: number;
};

export type RepairIndex = {
  hashId: string;
  hospitalHashId: string;
  faultHospitalProduct: HospitalProductIndex;
  hospitalProductHashId: string;
  hospitalDepartment: HospitalDepartMent | null;
  requestDepartMentName: string | null;
  statusHashId: string;
  requestUserHashId: string | null;
  requestUserName: string | null;
  requestForRepairAt: string | null;
  personInChargeHashId: string | null;
  appliedAtToSuppliesDepartment: Date | null;
  applicationToSuppliesDepartment: string | null;
  dealtCategory: DealtCategoryType | null;
  dealDetail: string | null;
  symptomCategory: SymptomCategoryType | null;
  symptomDetailCategory: SymptomDetailCategoryType | null;
  symptomDetail: string | null;
  rootCauseCategory: RootCauseCategoryType | null;
  causeOfIssueDetail: string | null;
  requests: string | null;
  necessityOfSubstitute: boolean | null;
  substitutionDetail: string | null;
  makerContactAt: string | null;
  fixedAt: Date | null;
  makerPicHashId: string | null;
  makerPicName: string | null;
  makerContactTel: string | null;
  makerContactEmail: string | null;
  costOfFix: number | null; // 修理請求額
  estimatedCostOfFix: number | null; // 修理見積額
  note: string | null;
  createdAt: Date;
  updatedAt: Date;
  substitutionHospitalProductHashId: string | null;
  dateOfDisposal: string | null;
  reasonOfDisposal: string | null;
  faultRepairStatus: FaultRepairStatusType;
  createdBy: UserIndex;
  updatedBy: UserIndex;
  faultReceptionHashId: string | null;
  isArchived: boolean;
  repairRegistrationNumber: string;
};

export type CreateFaultRepairParams = {
  faultAt: Date;
  statusHashId: string;
  symptomCategory?: SymptomCategoryType;
  symptomDetailCategory?: SymptomDetailCategoryType;
  rootCauseCategory?: RootCauseCategoryType;
  causeOfIssueDetail?: string;
  requestUserHashId?: string;
  requests?: string;
  substitutionHospitalProductHashId?: string;
  requestForRepairAt?: string;
  makerContactAt?: string;
  appliedAtToSuppliesDepartment?: string;
  fixedAt?: string;
  costOfFix?: number;
  estimatedCostOfFix?: number;
  faultReceptionHashId?: string;
};

export type FetchFaultRepairsParams = Partial<{
  name: string;
  personInChargeHashIds: string[];
  hospitalDepartmentHashIds: string[];
  symptomCategories: SymptomCategoryType[];
  symptomDetailCategories: SymptomDetailCategoryType[];
  rootCategories: RootCauseCategoryType[];
  dealtCategories: DealtCategoryType[];
  requestForRepairAtFrom: Date;
  requestForRepairAtTo: Date;
  statusHashId: string;
  updatedAtFrom: Date;
  page: number;
  perPage: number;
  order: string;
  faultReceptionHashId: string;
}>;

export type FetchFaultRepairsResult = {
  data: RepairIndex[];
  totalCount: number;
};

export type RepairStatusType = 'start' | 'complete' | 'archive';

export type RepairStatuses = {
  hashId: string;
  hospitalHashId: string;
  statusType: RepairStatusType;
  statusName: string;
  sequence: number;
  createdByHashId: string;
  createdAt: Date;
  updatedByHashId: string;
  updatedAt: Date;
};

export type PropertyRequirements = {
  hashId: string;
  hospitalHashId: string;
  property: keyof RepairIndex;
  faultRepairStatusHashId: string;
  isRequired: boolean;
  sequence: number;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
};

export type FetchFaultRepairPropertyRequrementsParams = Partial<{
  faultRepairStatusHashID: string;
  order: string;
}>;

export type UpdateFaultRepairPropertyRequrementsParams = Partial<{
  isRequired: boolean;
}>;

export type ResequenceFaultRepairPropertyRequrementsParams = Partial<{
  sequence: number;
}>;

export type CreateFaultRepairPropertyRequrementsParams = {
  faultRepairStatusHashId: string;
  property: string;
};

export type RepairLogType = (typeof repairLogTypeEnum)[keyof typeof repairLogTypeEnum];

export type FaultRepairLog = {
  hashId: string;
  hospitalHashId: string;
  hospitalProductHashId: string;
  faultRepairHashId: string;
  logType: RepairLogType;
  statusFromHashId?: string;
  statusFromName?: string;
  statusToHashId?: string;
  statusToName?: string;
  pinned: boolean;
  description?: string;
  createdBy: UserIndex;
  createdAt: Date;
  updatedBy: UserIndex;
  updatedAt: Date;
  isArchived: boolean;
  archivedBy?: UserIndex;
  repairRegistrationNumber: string;
};

// {
//   "log_type": "comment",
//   "pinned": false,
//   "description": "コメントするやで"
// }
export type CreateFaultRepairLogParams = {
  logType: RepairLogType;
  pinned?: boolean;
  description?: string;
};

export type UpdateFaultRepairLogParams = {
  pinned?: boolean;
  description?: string;
};

export type RequestExportRepairs = {
  RequestForRepairAtFrom: Date;
  RequestForRepairAtTo: Date;
};

export enum RepairStatusActionType {
  REPAIR_STATUS_SET_MAP = 'REPAIR_STATUS_SET_MAP',
}

export type RepairStatusSetMapAction = {
  type: RepairStatusActionType.REPAIR_STATUS_SET_MAP;
  payload: {
    map: Map<RepairStatuses['hashId'], RepairStatuses>;
  };
};

export type RepairStatusAction = RepairStatusSetMapAction;

export type RepairStatusState = {
  map: Map<RepairStatuses['hashId'], RepairStatuses>;
};
