import {SimpleDialog} from '@front-libs/ui';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import React from 'react';
import {UserTemp} from '@modules/hospital_users/helpers';
import {useForm, Controller} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';
import {MenuItem, Select, TextField, Typography, SxProps} from '@mui/material';
import {statusOptions} from './consts';
import {ResponseAddComponent} from './useColumns';

const InputStyle: SxProps = {
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#0A52CC', // フォーカス時の色 primary.main
  },
  '& .MuiOutlinedInput-input': {
    padding: '10px 16px',
  },
};
const LabelStyle: SxProps = {
  fontSize: '14px',
  padding: '16px 0 10px',
  display: 'inline-block',
};

// Zod スキーマ定義
const schema = z.object({
  statusSelect: z.string().min(1, '選択肢を選んでください'),
  userSelect: z.string().optional(),
  comment: z.string().optional(),
});

// フォームデータ型
export type ReturnWaitingReceptionFormData = z.infer<typeof schema>;

export type ReturnWaitingReceptionEditDialogProps = {
  editItem: ResponseAddComponent;
  userOptions: UserTemp[];
} & DialogProps;

export const ReturnWaitingReceptionEditDialog = (props: ReturnWaitingReceptionEditDialogProps) => {
  const {editItem, userOptions} = props;
  const {
    getValues,
    control,
    formState: {isValid},
    reset,
  } = useForm<ReturnWaitingReceptionFormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      statusSelect: editItem.status ?? 'before_confirmation',
      userSelect: editItem.contactPerson?.hashId ?? '',
      comment: editItem.comment ?? '',
    },
  });

  return (
    <>
      <SimpleDialog
        {...props}
        maxWidth="sm"
        paperSx={{maxWidth: '660px !important'}}
        reverseButton
        boldTitle
        disabledButton={!isValid}
        title="対応内容を編集"
        actions={{
          resolve: async (res) => {
            props.actions.resolve(getValues());
            return res;
          },
          reject: async () => {
            reset();
            props.actions.resolve(null);
            return;
          },
        }}>
        <form>
          <Typography component="label" sx={LabelStyle}>
            対応ステータス
          </Typography>
          <Controller
            name="statusSelect"
            control={control}
            render={({field}) => (
              <Select fullWidth {...field} id="status-select" variant="outlined" sx={InputStyle}>
                {statusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value} sx={{display: 'block'}}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <Typography component="label" sx={LabelStyle}>
            病棟への連絡者
          </Typography>
          <Controller
            name="userSelect"
            control={control}
            render={({field}) => (
              <Select fullWidth {...field} id="users-select" variant="outlined" sx={InputStyle}>
                {userOptions &&
                  userOptions.length > 0 &&
                  userOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{display: 'block'}}>
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
            )}
          />

          <Controller
            name="comment"
            control={control}
            render={({field}) => (
              <>
                <Typography component="label" sx={LabelStyle}>
                  コメント
                </Typography>
                <TextField {...field} value={field.value || ''} label="" fullWidth variant="outlined" sx={InputStyle} />
              </>
            )}
          />
        </form>
      </SimpleDialog>
    </>
  );
};
