import React, {useEffect} from 'react';
import {Paper, Box} from '@mui/material';
import {Header} from './ui/Header';
import {Footer} from './ui/Footer';
import {Error} from './ui/Error';
import {useWholeProductUnregisteredSearch} from './hooks/hooks';
import {isNullish} from '@front-libs/helpers';
import {Control, FieldValues, useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryParams, useQueryUpdateParams} from './hooks/query';
import {WholeProductSearchFormData, schema} from './common/validation';
import {WholeProductRecommendSearchForm} from './ui/WholeProductRecommendSearchForm';
import {ResultDisplay} from './ui/ResultDisplay';
import {NotFound} from './ui/NotFound';
import {SearchStartDisplay} from './ui/SearchStartDisplay';
import {COMPLETE_MODE, ERROR_MODE, LOADING_MODE, NOT_FOUND_MODE, SEARCH_MODE} from './common/const';
import {InnerLoading} from '@molecules/Loading';
import {usePrompt} from '@front-libs/core';
import {useNavigate} from 'react-router-dom';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';
/***
 * 院内の未登録機種を検索し登録する画面
 ***/
export const WholeProductUnregisteredSearch = () => {
  const navigate = useNavigate();
  // 登録用の画面なので、編集権限があるかを確認
  const {canEdit: canEditDeviceList} = useUserResourcesPermissions('DEVICE_LIST');
  if (FEATURE_CUSTOM_ASSET_ROLE_FLAG) {
    useEffect(() => {
      if (!canEditDeviceList) {
        navigate('/dashboard');
      }
    }, [canEditDeviceList, navigate]);
    if (!canEditDeviceList) {
      return null;
    }
  }
  // クエリパラメータを取得
  const query = useQueryParams();

  // クエリパラメータの値を状態として管理
  const {queryParams, handleChange} = useQueryUpdateParams(query);

  // 検索結果を取得
  const {handleSearch, handleEditWholeProduct, handleEditBlankWholeProduct, state} =
    useWholeProductUnregisteredSearch(queryParams);

  //リロード・ブラウザバックの処理
  usePrompt(state.isBlocked, '編集中の内容が破棄されますがよろしいですか？');

  const {
    control,
    handleSubmit,
    formState: {isValid},
  } = useForm<WholeProductSearchFormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: !isNullish(queryParams.name) ? queryParams.name : undefined,
      displayName: !isNullish(queryParams.displayName) ? queryParams.displayName : undefined,
      makerName: !isNullish(queryParams.makerName) ? queryParams.makerName : undefined,
      janCode: !isNullish(queryParams.janCode) ? queryParams.janCode : undefined,
      approvalNumber: !isNullish(queryParams.approvalNumber) ? queryParams.approvalNumber : undefined,
    },
  });

  const handleBackClick = async () => {
    if (state.isBlocked) {
      try {
        await dialogHandler.open(AlertDialog, {
          title: '情報を削除して機器一覧に戻りますか？',
          content: 'このページを離れると、検索条件及び検索結果が削除されます。\n機器一覧に戻りますか？',
          positiveButtonLabel: '機器一覧に戻る',
        });
      } catch (_error) {
        // キャンセル押下時
        return;
      }
    }

    navigate('/products');
  };

  const onSubmit = handleSubmit(async (data: WholeProductSearchFormData) => {
    await handleSearch(data);
  });

  return (
    <Paper sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh', width: '100%'}}>
      <Header onClickNewWholeProductRegister={handleEditBlankWholeProduct} />
      <WholeProductRecommendSearchForm
        control={control as unknown as Control<FieldValues>} //FIXME:型定義WholeProductSearchFormDataを指定するとname属性が被っておりエラーになるため
        onSubmit={onSubmit}
        handleChange={handleChange}
        isSearching={state.isSearching}
        isValid={isValid}
      />
      <Box sx={{flex: 1, backgroundColor: 'white', padding: 2}}>
        {state.mode === SEARCH_MODE && <SearchStartDisplay />}
        {state.mode === LOADING_MODE && <InnerLoading />}
        {state.mode === NOT_FOUND_MODE && <NotFound />}
        {state.mode === COMPLETE_MODE && !isNullish(state.searchResult) && (
          <ResultDisplay searchResult={state.searchResult} />
        )}
        {state.mode === ERROR_MODE && <Error />}
      </Box>
      <Footer
        handleEditWholeProduct={handleEditWholeProduct}
        handleBackClick={handleBackClick}
        disabled={state.mode !== COMPLETE_MODE}
      />
    </Paper>
  );
};
