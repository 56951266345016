import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {useUncompletedInspectionsByNarrowCategoryQuery} from '@modules/dashboard/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {CircularProgress, Divider, Grid, makeStyles, Paper} from '@material-ui/core';
import {styled} from '@material-ui/styles';
import {useUpdateDashboardSubject, UpdatePaperType} from '../hooks';
import {papers} from '../consts';
import {PaperHeader} from '../PaperHeader';
import {useNavigate} from 'react-router-dom';
import {
  useNarrowCategory,
  useInspectionType,
  useInspectionResultStatus,
} from '@Apps/InspectionResultList/pc/InternalInspection/states/states';
import {isNullish} from '@front-libs/helpers';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';

type Series = {
  total: {
    y: number; // 割合
    value: number; // 件数
  };
  uninspected: {
    y: number;
    value: number;
  };
};

type seriesUninspectedDataType = {
  value: number;
  y: number;
  dataLabels: [
    {
      enabled: true;
      useHTML: true;
      format: '{point.y}%';
      align: 'left';
    },
  ];
};

const initialSeries: Series = {
  total: {
    y: 100, // 総数側の割合の初期値は、データがなくてもグラフが表示されるように100%にしておく
    value: 0,
  },
  uninspected: {
    y: 0,
    value: 0,
  },
} as const;

export const UncompletedInspectionByCategory: React.FC = () => {
  const navigate = useNavigate();
  const {canAccess: canAccessInspection} = useUserResourcesPermissions('INSPECTION');
  if (FEATURE_CUSTOM_ASSET_ROLE_FLAG) {
    useEffect(() => {
      if (!canAccessInspection) {
        navigate('/dashboard');
      }
    }, [canAccessInspection, navigate]);
    if (!canAccessInspection) {
      return null;
    }
  }
  const classes = useStyles();
  const {myInfo} = useMyInfo();

  const {
    data: uncompletedInspections,
    isLoading: isLoadingUncompletedInspection,
    refetch,
  } = useUncompletedInspectionsByNarrowCategoryQuery(myInfo.hospitalHashId);
  const [, setNarrowCategory] = useNarrowCategory();
  const [, setInspectionType] = useInspectionType();
  const [, setInspectionResultStatus] = useInspectionResultStatus();

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const {subscribe, unsubscribe} = useUpdateDashboardSubject();

  const paper = papers.inspectionPercentByProduct;

  useEffect(() => {
    const current = chartComponentRef.current;
    if (!isLoadingUncompletedInspection) current?.chart.reflow();
  }, [isLoadingUncompletedInspection]);

  const sortedUncompletedInspections = useMemo(
    () =>
      uncompletedInspections?.sort(
        (a, b) =>
          b.numberOfUncompletedInspection / b.numberOfTotalInspection -
          a.numberOfUncompletedInspection / a.numberOfTotalInspection
      ),
    [uncompletedInspections]
  );

  const hospitalRootCategories = useMemo(
    () => sortedUncompletedInspections?.map((inspection) => inspection.category.name),
    [sortedUncompletedInspections]
  );

  const initialSeriesValue = useMemo(() => {
    const result: {[k: string]: Series} = {};

    hospitalRootCategories?.forEach((category) => {
      result[category] = initialSeries;
    });

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospitalRootCategories, initialSeries]);

  const series = useMemo(
    () =>
      (sortedUncompletedInspections ?? []).reduce((prev, curr) => {
        const currSeries: Series = {
          total: {
            value: curr.numberOfTotalInspection,
            y: 100,
          },
          uninspected: {
            value: curr.numberOfUncompletedInspection,
            y:
              curr.numberOfTotalInspection === 0
                ? curr.numberOfTotalInspection
                : Math.round((curr.numberOfUncompletedInspection / curr.numberOfTotalInspection) * 100),
          },
        };
        prev[curr.category.name] = currSeries;
        return prev;
      }, initialSeriesValue),
    [initialSeriesValue, sortedUncompletedInspections]
  );

  const getSeriesTotalData = useCallback(() => {
    const seriesTotalData: {
      y: number; // 割合
      value: number; // 件数
    }[] = [];

    sortedUncompletedInspections?.forEach((data) => {
      return seriesTotalData.push(series[data.category.name].total);
    });

    return seriesTotalData;
  }, [series, sortedUncompletedInspections]);

  const getSeriesUninspectedData = useCallback(() => {
    const result: seriesUninspectedDataType[] = [];

    sortedUncompletedInspections?.forEach((data) =>
      result.push({
        y: series[data.category.name]?.uninspected.y,
        value: series[data.category.name]?.uninspected.value,
        dataLabels: [
          {
            enabled: true,
            useHTML: true,
            format: '{point.y}%',
            align: 'left',
          },
        ],
      })
    );

    return result;
  }, [sortedUncompletedInspections, series]);

  const handleBarClick = useCallback(
    (narrowCategoryName: string) => {
      const narrowCategory = uncompletedInspections?.find(
        (inspection) => inspection.category.name === narrowCategoryName
      );
      setInspectionResultStatus('unplanned');
      setInspectionType('periodic');
      if (isNullish(narrowCategory) || isNullish(narrowCategory?.category.hashId)) {
        navigate(`/inspection_v2/results?type=periodic&status=unplanned`);
      } else {
        const hashId = narrowCategory?.category.hashId;
        setNarrowCategory(hashId);
        navigate(`/inspection_v2/results?type=periodic&status=unplanned&categories=${hashId}`);
      }
    },
    [navigate, setInspectionResultStatus, setInspectionType, setNarrowCategory, uncompletedInspections]
  );

  const onUpdateDashboardSubject = useCallback(
    (paperType: UpdatePaperType) => {
      if (paperType !== 'all' && paperType !== 'inspection_percent_by_product') return;

      refetch();
    },
    [refetch]
  );

  useEffect(() => {
    subscribe(onUpdateDashboardSubject);
    return () => unsubscribe(onUpdateDashboardSubject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options: Highcharts.Options = useMemo(
    () => ({
      chart: {
        type: 'bar',
        height: Object.keys(series).length * 40,
        marignLeft: 220,
      },
      title: {
        text: '',
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: hospitalRootCategories,
        title: {
          text: null,
        },
        reversed: true,
        labels: {
          enabled: true,
          style: {
            color: '#616C82',
            fontSize: '12px',
            width: 220,
          },
          useHTML: true,
          formatter: (ctx) => {
            const numberOfNotInspection =
              ctx.chart.series[1].data.length > 0 ? ctx.chart.series[1].data[ctx.pos].options.value : '0';
            const numberOfTotalInspection =
              ctx.chart.series[0].data.length > 0 ? ctx.chart.series[0].data[ctx.pos].options.value : '0';
            return `
                <div style="min-width:220px;display:flex;justify-content:space-between;align-items:center;">
                  <span style="font-size:14px;padding-right:12px;">${ctx.value}</span> 
                   <span style="font-weight:800;font-size:14px;line-height:24px;color:#2A96E8;padding-right:12px;">${numberOfNotInspection}/${numberOfTotalInspection}件</span>
                </div>
              `;
          },
        },
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: '',
          align: 'high',
        },
        labels: {
          enabled: false,
        },
        grid: {
          enabled: false,
          borderColor: '#FFF',
        },
        gridLineWidth: 0,
      },
      tooltip: {
        enabled: true,
        backgroundColor: '#FFF',
        positioner: (_labelWidth, _labelHeight, point) => {
          return {
            x: point.plotX + 20,
            y: point.plotY > 30 ? point.plotY - 30 : 0,
          };
        },
        followPointer: true,
        formatter: function () {
          return `
              <span style="font-size: 10px;">${this.x}</span><br />
              <span style="color: ${this.color}">●</span> ${this.series.name}: <span style="font-weight: bold;">${this.point.options.value}件</span><br />
            `;
        },
      },
      plotOptions: {
        bar: {
          grouping: false,
          borderRadius: 4,
          borderWidth: 0,
          maxPointWidth: 10,
          states: {
            inactive: {
              opacity: 1,
            },
            hover: {
              enabled: false,
            },
          },
        },
        series: {
          cursor: 'pointer',
        },
      },
      series: [
        {
          type: 'bar',
          color: '#F6F6F7',
          pointPlacement: 0,
          name: '総数',
          states: {
            inactive: {
              opacity: 1,
            },
            hover: {
              enabled: false,
            },
          },
          point: {
            events: {
              click() {
                handleBarClick(this.category as string);
              },
            },
          },
          data: getSeriesTotalData(),
        },
        {
          type: 'bar',
          color: '#4571AB',
          pointPlacement: 0,
          name: '未実施',
          states: {
            inactive: {
              opacity: 1,
            },
            hover: {
              enabled: false,
            },
          },
          point: {
            events: {
              click() {
                handleBarClick(this.category as string);
              },
            },
          },
          data: getSeriesUninspectedData(),
        },
      ],
    }),
    [series, hospitalRootCategories, getSeriesTotalData, getSeriesUninspectedData, handleBarClick]
  );

  return (
    <Paper className={classes.paper}>
      <PaperHeader title={paper.label} />
      <div className={classes.root}>
        <Grid container alignItems="center">
          <ChartHeaderGrid item className={classes.headerInspectionType}>
            小分類
          </ChartHeaderGrid>
          <ChartHeaderGrid item className={classes.headerAmount}>
            件数
          </ChartHeaderGrid>
          <ChartHeaderGrid item>割合</ChartHeaderGrid>
        </Grid>
        <ChartHeaderBorder />
        {isLoadingUncompletedInspection ? (
          <LoadingGrid container justifyContent="center" alignContent="center">
            <CircularProgress />
          </LoadingGrid>
        ) : (
          <HighChartContainer>
            <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />
          </HighChartContainer>
        )}
      </div>
    </Paper>
  );
};

const LoadingGrid = styled(Grid)({
  height: 120,
});

const HighChartContainer = styled('div')({
  maxHeight: '80%',
  overflowY: 'scroll',
});

const ChartHeaderGrid = styled(Grid)({
  color: '#6F798D',
  fontSize: 16,
  textAlign: 'center',
  height: 24,
  lineHeight: '24px',
});

const ChartHeaderBorder = styled(Divider)({
  height: '2px',
  backgroundColor: '#DFE1E5',
});

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '0px 16px 16px',
    borderTop: `4px solid ${theme.palette.primary.dark}`,
    height: `calc(100% - 20px)`,
  },
  root: {
    marginTop: 24,
    lineHeight: '24px',
    height: '80%',
  },
  headerInspectionType: {
    width: 110,
    paddingLeft: 8,
    textAlign: 'left',
  },
  headerInspectionTypeThreeCols: {
    width: 50,
    paddingLeft: 8,
    textAlign: 'left',
  },
  headerAmount: {
    width: 110,
    marginRight: 20,
    textAlign: 'right',
  },
  headerAmountThreeCols: {
    width: 50,
    marginRight: 8,
    textAlign: 'right',
  },
}));
