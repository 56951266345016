import {ListPageLayout} from '@components/layouts/ListPageLayout';
import {TableViewLayout} from '@components/layouts/TableViewLayout';
import {Accordion} from '@components/molecules/Accordion';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';
import {Grid, createStyles, makeStyles} from '@material-ui/core';
import {useHospitalDepartmentsOptions} from '@modules/hospital_departments/hooks';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {useStoredAccordionSetting} from '@modules/localstorage';
import {type TableLayoutResult, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import clsx from 'clsx';
import {useAtomValue} from 'jotai';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {RepairPageHeader} from './RepairPageHeader';
import {RepairKanban} from './contents/RepairKanban';
import {RepairTable} from './contents/RepairTable';
import {repairTab} from './jotai';
import {KanbanToolbar} from './toolbars/KanbanToolbar';
import {ListToolbar} from './toolbars/ListToolbar';

export const RepairList = () => {
  const navigate = useNavigate();
  const {canAccess: canAccessRepair} = useUserResourcesPermissions('REPAIR');
  useEffect(() => {
    if (FEATURE_CUSTOM_ASSET_ROLE_FLAG && !canAccessRepair) {
      navigate('/dashboard');
    }
  }, [canAccessRepair, navigate]);

  const classes = useStyles();
  const tab = useAtomValue(repairTab);
  const [cardLayout, setCardLayout] = useTableLayout('repairKanban');
  const {hospitalDepartmentOptions} = useHospitalDepartmentsOptions();
  const handleChangeCardLayout = useCallback(
    (layout: TableLayoutResult) => {
      setCardLayout(layout);
    },
    [setCardLayout]
  );

  const [tableLayout, setTableLayout] = useTableLayout(
    'repairList',
    useMemo(
      () => ({
        managementId: true,
        symptomCategory: true,
      }),
      []
    )
  );

  const handleChangeTableLayout = useCallback(
    (layout: TableLayoutResult) => {
      setTableLayout(layout);
    },
    [setTableLayout]
  );

  const ToolBar = useMemo(
    () =>
      tab === 'list' ? (
        <ListToolbar
          onChangeTableLayout={handleChangeTableLayout}
          hospitalDepartmentOptions={hospitalDepartmentOptions}
        />
      ) : (
        <KanbanToolbar
          onChangeCardLayout={handleChangeCardLayout}
          hospitalDepartmentOptions={hospitalDepartmentOptions}
        />
      ),
    [handleChangeCardLayout, hospitalDepartmentOptions, handleChangeTableLayout, tab]
  );
  const Content = useMemo(
    () =>
      tab === 'list' ? (
        <RepairTable currentTableLayout={tableLayout?.currentLayout} />
      ) : (
        <RepairKanban currentCardLayout={cardLayout?.currentLayout} />
      ),
    [tab, cardLayout, tableLayout]
  );

  const accordionSettingProps = useStoredAccordionSetting('repairs_page_header');

  if (FEATURE_CUSTOM_ASSET_ROLE_FLAG && !canAccessRepair) return null;

  return (
    <ListPageLayout page="repairs" className={classes.root}>
      <Accordion {...accordionSettingProps}>
        <Grid container className={classes.headerContainer}>
          <RepairPageHeader />
        </Grid>
      </Accordion>
      <ListPageLayout.Content>
        <TableViewLayout
          className={
            tab === 'list'
              ? classes.content
              : clsx(
                  classes.content,
                  accordionSettingProps.defaultOpen
                    ? classes.rootKanbanByExpanedHeader
                    : classes.rootKanbanByCollapseedHeader
                )
          }>
          <TableViewLayout.Header>{ToolBar}</TableViewLayout.Header>
          {Content}
        </TableViewLayout>
      </ListPageLayout.Content>
    </ListPageLayout>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexDirection: 'column',
    },
    headerContainer: {
      width: '100%',
      boxSizing: 'border-box',
      padding: '16px 24px 4px',
    },
    content: {
      width: '100%',
      flexDirection: 'column',
    },
    rootKanbanByExpanedHeader: {
      height: 'calc(100vh - 170px)',
    },
    rootKanbanByCollapseedHeader: {
      height: 'calc(100vh - 110px)',
    },
    stageContainer: {
      flex: 2,
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      userSelect: 'none',
      flexGrow: 1,
      flexShrink: 0,
    },
  })
);
