import {useAtom} from 'jotai';
import {useReducer, useMemo} from 'react';
import {returnWaitingReceptionPageSizeAtom} from './jotai';
import {
  ReturnWaitingReceptionsOrderType,
  WaitingReceptionsStatusType,
  returnsReservationRequestParam,
} from '@modules/returns_reservation/type';
import dayjs from 'dayjs';
import {TabOptionValue} from './TabButtons';
import {WaitingReceptionsStatusArray} from './consts';

const currentDayStart = dayjs().startOf('day').format(); // 午前0時 (00:00:00)
const currentDayEnd = dayjs().endOf('day').format(); // 午後11時59分59秒 (23:59:59)

const initialState = {
  page: 1,
  statusList: WaitingReceptionsStatusArray,
  listTab: 'today' as TabOptionValue,
  order: '-reception_at',
};

type Action =
  | {type: 'SET_PAGE'; payload: number}
  | {type: 'SET_STATUS_LIST'; payload: WaitingReceptionsStatusType[]}
  | {type: 'SET_LIST_TAB'; payload: TabOptionValue}
  | {type: 'SET_ORDER'; payload: ReturnWaitingReceptionsOrderType};

const reducer = (state: typeof initialState, action: Action): typeof initialState => {
  switch (action.type) {
    case 'SET_PAGE':
      return {...state, page: action.payload};
    case 'SET_STATUS_LIST':
      return {...state, page: 1, statusList: action.payload.length > 0 ? action.payload : WaitingReceptionsStatusArray};
    case 'SET_LIST_TAB':
      return {...state, listTab: action.payload, page: 1};
    case 'SET_ORDER':
      return {...state, order: action.payload};
    default:
      return state;
  }
};

/**
 * 返却待ち受付の状態(state)を管理するカスタムフック
 * @returns
 */
export const useReturnWaitingReceptionState = () => {
  // useReducerので複数stateを一括管理
  const [state, dispatch] = useReducer(reducer, initialState);
  // AtomはuseReducerに含められないのでここで定義
  const [pageSize, setPageSize] = useAtom(returnWaitingReceptionPageSizeAtom);

  const {page, statusList, listTab, order} = state;

  const todayParam = useMemo(
    () => ({
      receptionAtFrom: currentDayStart,
      receptionAtTo: currentDayEnd,
    }),
    []
  );

  const param = useMemo<returnsReservationRequestParam>(
    () => ({
      perPage: pageSize,
      page: page - 1,
      statuses: statusList.join(','),
      order: order,
      ...(listTab === 'today' ? todayParam : {}),
    }),
    [page, pageSize, statusList, listTab, order, todayParam]
  );

  return {
    dispatch,
    param,
    page,
    pageSize,
    setPageSize,
  };
};
