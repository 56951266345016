import {styled} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {RepairListItem} from '@molecules/RepairListItem';
import {useFaultRepairPropertyRequirements, useFetchFaultRepairStatuses} from '@modules/repairs/hooks';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {PropertyProposalDialog} from '@organisms/FaultRepairs/dialogs/PropertyProposalDialog';
import {updateFaultRepair} from '@modules/repairs/api';
import {RepairCreateIconButton} from '@molecules/Buttons/RepairCreateIconButton';
import {RepairIndex} from '@modules/repairs/types';
import {useNavigate} from 'react-router-dom';
import {
  RegistrationRepairDialog,
  RegistrationRepairDialogProps,
} from '@organisms/FaultRepairs/dialogs/RegistrationRepairDialog';
import {createFaultRepair} from '@modules/repairs/api';
import {isNullish} from '@front-libs/helpers';
import {UseQueryResult} from 'react-query';
import {FetchHospitalProductFaultRepairHistoriesResult} from 'src/modules/hospital_products/api';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
import {FaultRepairConfirmDialog} from '@components/organisms/FaultRepairConfirmDialog/FaultRepairConfirmDialog';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';

type Props = {
  hospitalProductHashId: string;
  faultQuery: UseQueryResult<FetchHospitalProductFaultRepairHistoriesResult, unknown>;
};

/*
 * 機器詳細の点検・修理タブの修理のタブ
 */
export const RepairTab = ({hospitalProductHashId, faultQuery}: Props) => {
  const {canEdit: canEditRepair} = useUserResourcesPermissions('REPAIR');
  const {canEdit: canEditDeviceList} = useUserResourcesPermissions('DEVICE_LIST');
  const [openRepairDialog, setOpenRepairDialog] = useState(false);
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();
  const {isReadOnly} = useMyRole();
  const {hospitalUsers} = useHospitalUsers();
  const {data: faultRepairPropertyRequirements} = useFaultRepairPropertyRequirements();
  const faultRepairStatus = useFetchFaultRepairStatuses(myInfo.hospitalHashId);

  const registrationActions = {
    resolve: async (result: Partial<RegistrationRepairDialogProps> | undefined) => {
      if (result) {
        if (isNullish(result.statusHashId)) {
          result.statusHashId =
            faultRepairStatus.data.find((item) => item.statusType === 'start')?.hashId ??
            faultRepairStatus.data[0].hashId;
        }
        const {repairRegistrationNumber} = await createFaultRepair(
          myInfo.hospitalHashId,
          result.hospitalProductHashId + '',
          result
        );

        faultQuery.refetch();
        setOpenRepairDialog(false);
        await dialogHandler.open(FaultRepairConfirmDialog, {repairRegistrationNumber});
      } else {
        faultQuery.refetch();
        setOpenRepairDialog(false);
      }
    },
    reject: async () => {
      setOpenRepairDialog(false);
    },
  };

  const handleUpdateStatus = useCallback(
    async (faultRepair: RepairIndex, toStatusHashId: string, toStatusName: string) => {
      if (isNullish(faultRepairPropertyRequirements)) return;
      const proposalProps = faultRepairPropertyRequirements
        .filter((proposal) => proposal.faultRepairStatusHashId === toStatusHashId)
        .sort((a, b) => a.sequence - b.sequence);

      const validatedRepair = await dialogHandler.open(PropertyProposalDialog, {
        statusName: toStatusName,
        propertyRequirements: proposalProps ?? [],
        defaultFaultRepair: faultRepair,
        hospitalUsers,
      });
      await updateFaultRepair(myInfo.hospitalHashId, faultRepair.hospitalProductHashId, faultRepair.hashId, {
        ...validatedRepair,
        statusHashId: toStatusHashId,
      });
      await faultQuery.refetch();
    },
    [faultQuery, faultRepairPropertyRequirements, hospitalUsers, myInfo.hospitalHashId]
  );

  const handleClickCauseBtn = useCallback(
    (faultRepairHashId: string) => navigate(`/repairs/${faultRepairHashId}`),
    [navigate]
  );

  const handleCreateFaultRepair = useCallback(async () => {
    await createFaultRepair(myInfo.hospitalHashId, hospitalProductHashId, {});
    faultQuery.refetch();
  }, [faultQuery, hospitalProductHashId, myInfo.hospitalHashId]);

  return (
    <RootContainer>
      <RepairHeader>
        {((FEATURE_CUSTOM_ASSET_ROLE_FLAG && canEditRepair) || (!FEATURE_CUSTOM_ASSET_ROLE_FLAG && !isReadOnly)) && (
          <RepairCreateIconButton
            onAddRelatedContent={
              faultRepairPropertyRequirements?.length === 0
                ? () => handleCreateFaultRepair()
                : () => setOpenRepairDialog(true)
            }
          />
        )}
      </RepairHeader>
      <RepairContent>
        {(faultQuery.data?.data ?? []).map((item, index) => (
          <RepairListItem
            key={index}
            repair={item}
            isReadOnly={
              (FEATURE_CUSTOM_ASSET_ROLE_FLAG && !canEditRepair) || (!FEATURE_CUSTOM_ASSET_ROLE_FLAG && isReadOnly)
            }
            repairStatuses={faultRepairStatus.data ?? []}
            onUpdateStatus={(toStatusHashId, toStatusName) =>
              handleUpdateStatus(item, toStatusHashId, toStatusName as string)
            }
            onClickRepairLink={handleClickCauseBtn}
          />
        ))}
      </RepairContent>
      <RegistrationRepairDialog
        open={openRepairDialog}
        actions={registrationActions}
        hospitalHashId={myInfo.hospitalHashId}
        hospitalUsers={hospitalUsers}
        repairStatuses={faultRepairStatus.data ?? []}
        defaultValues={{
          hospitalProductHashId: hospitalProductHashId,
        }}
        readOnlyKeys={['hospitalProductHashId']}
      />
    </RootContainer>
  );
};

const RootContainer = styled('div')({
  margin: '16px 0',
  display: 'flex',
  flexDirection: 'column',
});

const RepairHeader = styled('div')({
  padding: '0 32px',
  display: 'flex',
  justifyContent: 'flex-end',
});

// スクロールしない部分の高さ
const FIXED_HEIGHT = 320;

const RepairContent = styled('div')({
  marginBottom: '24px',
  padding: '0 32px',
  height: `calc(100vh - ${FIXED_HEIGHT}px)`,
  overflowY: 'auto',
  overflowX: 'hidden',
});
