import {Box, DialogTitle, Typography, SxProps, Theme, Dialog, Button, DialogActions} from '@mui/material';
import React from 'react';
import {DialogContent, DialogProps} from '@front-libs/ui';
import {StrUtil} from '@front-libs/helpers';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {TargetStatusForUnitRelation} from '@Apps/BaseSharedMenu/types';

const informationRootStyle: SxProps = {
  padding: '16px',
  color: '#C77700',
  backgroundColor: 'rgb(255,245,229)',
  marginBottom: '32px',
  display: 'flex',
  gap: '16px',
};
const informationMessageStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
};

const getFontSizeStyle = (size: string): SxProps => ({fontSize: size});

const DialogInformation = ({message}: {message: string}) => {
  const isRemarks = message.includes('※');
  return (
    <Box sx={informationRootStyle}>
      <Box>
        <ErrorOutlineIcon sx={getFontSizeStyle('36px')} />
      </Box>
      <Box sx={informationMessageStyle}>
        <Typography sx={getFontSizeStyle('24px')}>{StrUtil.nl2br(message)}</Typography>
        {isRemarks && <Typography sx={getFontSizeStyle('20px')}>※修理中、点検待ち、貸出中ステータスの機器</Typography>}
      </Box>
    </Box>
  );
};

/** タイトル定義 */
const Titles = {
  addToRentalList: '関連する親機・子機が\n「貸出する機器リスト」に追加されました。',
  notAddToRentalList: '関連する親機・子機が\n「貸出する機器リスト」に追加されませんでした。',
  addToReturnList: '関連する親機・子機が\n「返却する機器リスト」に追加されました。',
  notAddToReturnList: '関連する親機・子機が\n「返却する機器リスト」に追加されませんでした。',
} as const;

/** メッセージ定義 */
const Messages = {
  checkReturnList: '「閉じる」を押して、返却する機器リストをご確認ください。',
  checkRentalList: '「閉じる」を押して、貸出する機器リストをご確認ください。',
} as const;

/** ステータスインフォメーション定義 */
const InformationMessages = {
  rentalRelationTargetsNotAppended: '関連する親機・子機が貸出不可※の機器であるため、\nリストに追加されませんでした。',
  rentalRelationTargetsAppendedExcludeSomeUnits:
    '関連する親機・子機に貸出不可の機器※が含まれているため、\n一部の親機・子機がリストに追加されませんでした。',
  returnRelationTargetsNotAppended:
    '関連する親機・子機が返却対象でない機器であるため、\nリストに追加されませんでした。',
  returnRelationTargetsAppendedExcludeSomeUnits:
    '関連する親機・子機に返却対象でない機器が含まれているため、\n一部の親機・子機がリストに追加されませんでした。',
} as const;

type DialogPatternData = {title: string; message: string; statusInformation?: string};

type DialogPatternKey = TargetStatusForUnitRelation;

const DialogPatterns: Record<DialogPatternKey, DialogPatternData> = {
  /** 親子関係にある機器も貸出対象に追加された (全ての構成機器が追加された) */
  rentalRelationTargetsAppended: {
    title: Titles.addToRentalList,
    message: Messages.checkRentalList,
  },
  /** 親子関係にある機器も貸出対象に追加された (一部追加されない) */
  rentalRelationTargetsAppendedExcludeSomeUnits: {
    title: Titles.addToRentalList,
    message: Messages.checkRentalList,
    statusInformation: InformationMessages.rentalRelationTargetsAppendedExcludeSomeUnits,
  },
  /** 親子関係が貸出対象に追加されなかった (関連する親子機器は含まれない) */
  rentalRelationTargetsNotAppended: {
    title: Titles.notAddToRentalList,
    message: Messages.checkRentalList,
    statusInformation: InformationMessages.rentalRelationTargetsNotAppended,
  },
  /** 親子関係にある機器も返却対象に追加された (全ての構成機器が追加された) */
  returnRelationTargetsAppended: {
    title: Titles.addToReturnList,
    message: Messages.checkReturnList,
  },
  /** 親子関係にある機器も返却対象に追加された (一部追加されない) */
  returnRelationTargetsAppendedExcludeSomeUnits: {
    title: Titles.addToReturnList,
    message: Messages.checkReturnList,
    statusInformation: InformationMessages.returnRelationTargetsAppendedExcludeSomeUnits,
  },
  /** 親子関係が返却対象に追加されなかった (関連する親子機器は含まれない) */
  returnRelationTargetsNotAppended: {
    title: Titles.notAddToReturnList,
    message: Messages.checkReturnList,
    statusInformation: InformationMessages.returnRelationTargetsNotAppended,
  },
} as const;

export type ParentChildDeviceDialogProps = {
  status: DialogPatternKey;
} & DialogProps;

/**
 *
 * 親機・子機の貸出や返却に関連するダイアログコンポーネント
 * 親子関係機器のリスト追加状況に応じて異なるメッセージやタイトルを表示
 *
 * @param {boolean} open - ダイアログの表示状態を制御するフラグ`true` の場合、ダイアログが表示されます
 * @param {DialogPatternKey} status - 表示するダイアログのパターンを指定するキー
 * 対応するキーは以下の通りです:
 *  - `rentalRelationTargetsAppended`: 親子関係にある機器も貸出対象に追加された (全ての構成機器が追加された)
 *  - `rentalRelationTargetsAppendedExcludeSomeUnits`: 親子関係にある機器も貸出対象に追加された (一部の親子機器は追加されない)
 *  - `rentalRelationTargetsNotAppended`: 親子関係が貸出対象に追加されなかった (バーコード読み取り対象機以外の構成機器が貸出出来ない状態)
 *  - `returnRelationTargetsAppended`: 親子関係にある機器も返却対象に追加された (全ての構成機器が追加された)
 *  - `returnRelationTargetsAppendedExcludeSomeUnits`: 親子関係にある機器も返却対象に追加された (一部の親子機器は追加されない)
 *  - `returnRelationTargetsNotAppended`: 親子関係が返却対象に追加されなかった (バーコード読み取り対象機以外の構成機器が返却出来ない状態)
 * @param {object} actions - ダイアログのアクションを制御するオブジェクト
 *  - `resolve`: ダイアログを閉じる処理を実行するコールバック関数
 *
 * @example
 * ```tsx
 * <ParentChildDeviceDialog
 *   open={true}
 *   status="rentalRelationTargetsAppendedExcludeSomeUnits"
 *   actions={{ resolve: () => console.log('ダイアログを閉じる') }}
 * />
 * ```
 *
 */
export const ParentChildDeviceDialog = ({open, status, actions}: ParentChildDeviceDialogProps) => {
  const dialogTextData = DialogPatterns[status];
  return (
    <Dialog open={!!open} maxWidth="xl" data-testid={'rental-error-dialog'} PaperProps={{sx: dialogStyle}}>
      <DialogTitle sx={dialogTitleStyle}>
        <Typography>{StrUtil.nl2br(dialogTextData.title)}</Typography>
      </DialogTitle>
      <DialogContent>
        {dialogTextData.statusInformation ? <DialogInformation message={dialogTextData.statusInformation} /> : null}
        <Typography sx={dialogContentTypographyStyle}>{StrUtil.nl2br(dialogTextData.message)}</Typography>
      </DialogContent>
      <DialogActions>
        <Button sx={closeButtonStyle} onClick={() => actions.resolve()}>
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const dialogStyle: SxProps = {
  padding: '120px 64px',
  '@media (max-height: 800px)': {
    padding: '64px 64px',
  },
  borderRadius: '10px',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '32px',
};

const dialogTitleStyle: SxProps = {
  textAlign: 'center',
  '& > .MuiTypography-root': {
    fontWeight: 'bold',
    fontSize: '36px',
  },
};

const dialogContentTypographyStyle: SxProps = {
  fontSize: '24px',
};

// themeが読み込めないことがあるのでMuiV5ThemeProviderから抜粋
const RentColorList = {
  light: '#2A96E8',
  main: '#0052CC',
  dark: '#0E3870',
};

const closeButtonStyle: SxProps<Theme> = (theme) => ({
  cursor: 'pointer',
  borderRadius: '20px',
  boxShadow: `0px 8px 0px 0px ${RentColorList.dark}`,
  backgroundColor: RentColorList.main,
  height: '80px',
  width: '256px',
  margin: '0 auto',
  fontSize: '24px',
  fontWeight: 'bold',
  color: theme.palette.common.white,
  marginTop: '88px', // figma指定の120pxからgapの32pxを引いた値
  '@media (max-height: 800px)': {
    marginTop: '0px',
  },
  '&:hover': {
    backgroundColor: RentColorList.dark,
  },
});
