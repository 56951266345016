import React, {useCallback, useState} from 'react';
import {Tab, Tabs, styled} from '@material-ui/core';
import {HospitalProductIndex} from '@modules/hospital_products/types';
import {InspectionTab} from '@organisms/InspectionTab';
import {RepairTab} from '@organisms/RepairTab';
import {useFetchHospitalProductFaultRepairHistoriesQuery} from '@modules/hospital_products/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useFetchInspectionResultsQuery} from '@modules/inspection_results/api';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';

type Props = {
  hospitalProduct: HospitalProductIndex;
};

/**
 * 機器詳細右側の点検・修理予定タブ
 */
export const InspectionAndRepairTab = ({hospitalProduct}: Props) => {
  const {canAccess: canAccessInspection} = useUserResourcesPermissions('INSPECTION');
  const [tab, setTab] = useState('inspection');
  const {myInfo} = useMyInfo();
  const faultQuery = useFetchHospitalProductFaultRepairHistoriesQuery(myInfo.hospitalHashId, hospitalProduct.hashId);
  const {
    data: inspectionResults,
    totalCount: inspectionResultsTotalCount,
    isFetched: inspectionResultsIsFetched,
  } = useFetchInspectionResultsQuery(myInfo.hospitalHashId, 'inspectionHashId', {
    hospitalProductHashId: hospitalProduct.hashId,
    statuses: 'unplanned,uncompleted',
    order: 'scheduledTime',
  });

  const tabs =
    FEATURE_CUSTOM_ASSET_ROLE_FLAG && !canAccessInspection
      ? [
          {
            label: '修理予定',
            count: faultQuery.data?.totalCount ?? 0,
            value: 'repair',
          },
        ]
      : [
          {
            label: '点検予定',
            count: inspectionResultsTotalCount ?? 0,
            value: 'inspection',
          },
          {
            label: '修理予定',
            count: faultQuery.data?.totalCount ?? 0,
            value: 'repair',
          },
        ];

  const handleChangeTab = useCallback(
    (_, key: string) => {
      setTab(key);
    },
    [setTab]
  );

  return (
    <div>
      <Tabs
        value={tab}
        TabIndicatorProps={{
          style: {
            backgroundColor: '#172B4D',
          },
        }}
        onChange={handleChangeTab}
        style={{borderBottom: 'solid 1px #F0F2F5'}}>
        {tabs.map((item, index) => (
          <Tab
            label={
              <div>
                <span style={item.value === tab ? {color: '#172B4D', fontWeight: '600'} : {}}>{item.label}</span>
                {item.count > 0 && <StyledBadge>{item.count}</StyledBadge>}
              </div>
            }
            value={item.value}
            key={index}
          />
        ))}
      </Tabs>
      {tab === 'inspection' && (
        <InspectionTab
          // biome-ignore lint/style/noNonNullAssertion: 非nullアサーション演算子(!)
          wholeProductHashId={hospitalProduct?.wholeProductHashId!}
          hospitalProductHashId={hospitalProduct.hashId}
          isFetched={inspectionResultsIsFetched}
          inspectionResults={inspectionResults}
        />
      )}
      {tab === 'repair' && <RepairTab hospitalProductHashId={hospitalProduct.hashId} faultQuery={faultQuery} />}
    </div>
  );
};

const StyledBadge = styled('span')({
  backgroundColor: '#172B4D',
  color: 'white',
  marginLeft: '8px',
  padding: '1px 6px',
  borderRadius: '16px',
});
