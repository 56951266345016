import React, {useCallback} from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {Typography} from '@mui/material';
import {MenuItemType, PopperMenuButtonV5} from '@components/molecules/Buttons/PopperMenuButton/PopperMenuButtonV5';
import {Option} from '../Selector';

/** SEE:他の箇所でも使うのであればPropsに含める */
const defaultPageSizeMenuItems = [
  {
    label: '20件',
    value: 20,
  },
  {
    label: '50件',
    value: 50,
  },
  {
    label: '100件',
    value: 100,
  },
];

export type Options = Option<number>[];

type props = {
  pageSize: number;
  pageSizeMenuItems?: Options;
  update?: (selectNum: number) => void;
};

/**
 *
 * 件数選択メニューのMuiV5板
 * @param pageSize - 表示件数
 * @param update -件数選択時に選択した数値を返すコールバック
 * @returns
 */
export const DisplayNumberSelectV5 = ({pageSize, pageSizeMenuItems, update}: props) => {
  const handleChangeRowsPerPage = useCallback(
    (item: MenuItemType) => {
      // 件数選択矢印が出ていなければクリック時に動作しない
      if (update) update(Number(item.value));
    },
    [update]
  );

  return (
    <PopperMenuButtonV5<number>
      placement="bottom"
      hiddenArrow={true}
      buttonProps={{variant: 'text'}}
      itemProps={{sx: {'&:hover': {backgroundColor: '#E2F4FB'}}}}
      menuItemList={pageSizeMenuItems ?? defaultPageSizeMenuItems}
      onMenuClick={handleChangeRowsPerPage}>
      <Typography sx={{color: '#0A52CC', fontWeight: 'bold'}}>{pageSize}件</Typography>
      <ArrowDropDownIcon />
    </PopperMenuButtonV5>
  );
};
