import {WaitingReceptionsStatusType} from '@modules/returns_reservation/type';
import React from 'react';
import {PopperSelectBoxButton} from '@components/molecules/Buttons/PopperSelectBoxButton';
import {statusOptions} from './consts';

type Props = {
  onChange: (status: WaitingReceptionsStatusType[]) => void;
};
export const FilterButtons = ({onChange}: Props) => {
  return (
    // TODO:PopperSelectBoxButtonをMuiV5対応にしジェネリクスでonChangeの型を指定する
    <PopperSelectBoxButton
      buttonLabel={'ステータス'}
      options={statusOptions.map((v) => v)}
      isMulti={true}
      onChange={(val) => onChange(val?.map((v) => v.value as WaitingReceptionsStatusType) ?? [])}
      searchable={false}
    />
  );
};
