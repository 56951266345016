import {Box} from '@mui/material';
import React from 'react';
import {BaseSharedListTable, BaseSharedListTableColumn, tableTitleStyle} from '../BaseSharedListTable';
import {CancelButton} from '../CancelButton';
import {RentalTargetHospitalProducts} from '@Apps/BaseSharedMenu/RentDevices/types';

type RowData = {
  managementId: string;
  rentRoom: string | undefined;
  narrowCategoryName: string;
  displayName: string;
  cancelButton: JSX.Element | null;
};

const columns: BaseSharedListTableColumn<RowData>[] = [
  {id: 'managementId', label: '管理番号', minWidth: '140px'},
  {id: 'rentRoom', label: '貸出場所', minWidth: '80px'},
  {id: 'narrowCategoryName', label: '小分類', minWidth: '80px'},
  {id: 'displayName', label: '機種名', minWidth: '120px'},
  {id: 'cancelButton', label: '', minWidth: 'fit-content', sx: {width: 'fix-content'}},
];

export type TableProps = {
  rentalTargetHospitalProducts: RentalTargetHospitalProducts;
  rentRoom: string;
  onClickCancel: (rowIndex: number) => void;
};

export const RentProductTable = ({
  rentalTargetHospitalProducts /* 貸出対象機器リスト(親子関係対応済) */,
  rentRoom,
  onClickCancel,
}: TableProps) => {
  const tableRows: RowData[] = rentalTargetHospitalProducts.map((item, index) => ({
    managementId: item.managementId ?? '',
    narrowCategoryName: item.narrowCategoryName,
    displayName: item.displayName ?? '',
    rentRoom: rentRoom ?? '',
    cancelButton: item.managementId ? (
      <CancelButton
        onClickCancel={() => {
          onClickCancel(index);
        }}
      />
    ) : null,
  }));

  return (
    <>
      <Box sx={tableTitleStyle}>貸出する医療機器リスト</Box>
      <BaseSharedListTable<RowData> tableRows={tableRows} columns={columns} />
    </>
  );
};
