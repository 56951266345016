import React from 'react';
import {Grid, Button} from '@mui/material';
import {Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import BlockIcon from '@mui/icons-material/Block';

export const NotFound = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Grid
      container
      sx={{height: '80vh', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '0 68px'}}>
      <BlockIcon sx={{fontSize: 100, color: '#8E95A6'}} />
      <Typography variant="h4" gutterBottom sx={{marginTop: 3, marginBottom: 3}}>
        <b>こちらの機器情報は閲覧できません</b>
      </Typography>{' '}
      <Typography variant="h6" gutterBottom align="center">
        対象の機器が存在していない、あるいは機器情報へのアクセス権限がないため、
        <br />
        こちらの機器情報は閲覧できません。
        <br />
        閲覧したい場合は、Asset管理者へお問い合わせください。
      </Typography>
      <Button
        sx={{
          marginTop: 3,
          background: '#FFFFFF',
          marginRight: '8px',
        }}
        variant={'outlined'}
        color={'primary'}
        onClick={handleGoBack}>
        前の画面に戻る
      </Button>
    </Grid>
  );
};
