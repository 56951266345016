import {atom} from 'jotai';
import {DealtCategoryType, FetchFaultRepairsParams, SymptomCategoryType} from '@modules/repairs/types';
import {atomWithReset, atomWithStorage, selectAtom} from 'jotai/utils';
import {isNullish} from '@front-libs/helpers';
import {ResultType, SelectorConditionValue} from '@molecules/Drawers/FilterDrawer/types';

const LOCAL_STORAGE_KEY_FAULT_REPAIR_TAB = 'hitotsu/fault_repair_tab';
const LOCAL_STORAGE_KEY_FAULT_REPAIR_KANBAN_ORDER = 'hitotsu/fault_repair/kanban_order';
const LOCAL_STORAGE_KEY_FAULT_REPAIR_KANBAN_OVERDUE_SETTING = 'hitotsu/fault_repair/kanban_overdue_setting';
const LOCAL_STORAGE_KEY_REPAIR_LIST = 'hitotsu/repair_page_size_status';

const repairTabAtom = atomWithStorage<'kanban' | 'list'>(LOCAL_STORAGE_KEY_FAULT_REPAIR_TAB, 'kanban');

type DetailFilter = Partial<{
  symptomCategories: SymptomCategoryType[];
  hospitalDepartmentHashIds: string[];
}>;

export const repairTab = atom(
  (get) => get(repairTabAtom),
  (_, set, newTab: 'kanban' | 'list') => {
    set(repairTabAtom, newTab);
    localStorage.setItem(LOCAL_STORAGE_KEY_FAULT_REPAIR_TAB, newTab);
  }
);

export const searchNameAtom = atom<string | undefined>('');
export const searchPersonInChargeHashIDsAtom = atom<string[]>([]);
export const searchReq4RepairAtFromAtom = atom<Date | null>(null);
export const searchReq4RepairAtToAtom = atom<Date | null>(null);
export const searchSymptomCategoriesAtom = atom<SymptomCategoryType[]>([]);
export const searchDealtCategoriesAtom = atom<DealtCategoryType[]>([]);
export const searchDetailFiltersAtom = atom<ResultType[]>([]);
export const repairListPageAtom = atomWithReset<number>(1);
export const repairListTablePageSizeAtom = atomWithStorage<number>(LOCAL_STORAGE_KEY_REPAIR_LIST, 20);

export const kanbanOrder = atomWithStorage<{orderColumn: string; direction: 'asc' | 'desc'}>(
  LOCAL_STORAGE_KEY_FAULT_REPAIR_KANBAN_ORDER,
  {
    orderColumn: 'createdAt',
    direction: 'desc',
  }
);

export const listOrder = atom<{orderColumn: string; direction: 'asc' | 'desc'}>({
  orderColumn: 'createdAt',
  direction: 'desc',
});

export const overdueSetting = atomWithStorage<{dateNumber: number; unit: 'day' | 'week'}>(
  LOCAL_STORAGE_KEY_FAULT_REPAIR_KANBAN_OVERDUE_SETTING,
  {
    dateNumber: 15,
    unit: 'day',
  }
);

export const isActiveDetailFilterAtom = atom<boolean>((get) => {
  const searchDetailFilter = get(searchDetailFiltersAtom);
  const keys = Object.keys(searchDetailFilter);

  // searchDetailFilterに、searchDetailFilterの各キーに対応する値が1つでも入力されていれば、DetailFilterがアクティブとする
  return keys.some((item) => !isNullish(searchDetailFilter[item as keyof typeof searchDetailFilter]));
});

export const faultRepairParams = atom<FetchFaultRepairsParams>((get) => {
  const tab = get(repairTab);
  const _p: FetchFaultRepairsParams = {
    page: tab === 'list' ? get(repairListPageAtom) - 1 : 0,
    perPage: tab === 'list' ? get(repairListTablePageSizeAtom) : 100,
  };
  if (get(searchNameAtom)) _p.name = get(searchNameAtom);
  if (get(searchPersonInChargeHashIDsAtom).length > 0) _p.personInChargeHashIds = get(searchPersonInChargeHashIDsAtom);
  if (get(searchSymptomCategoriesAtom).length > 0) _p.symptomCategories = get(searchSymptomCategoriesAtom);
  if (get(searchDealtCategoriesAtom).length > 0) _p.dealtCategories = get(searchDealtCategoriesAtom);
  if (get(searchReq4RepairAtFromAtom)) _p.requestForRepairAtFrom = get(searchReq4RepairAtFromAtom) ?? undefined;
  if (get(searchReq4RepairAtToAtom)) _p.requestForRepairAtTo = get(searchReq4RepairAtToAtom) ?? undefined;
  if (get(searchDetailFiltersAtom)) {
    const {symptomCategories, hospitalDepartmentHashIds} = convertFilterResults(get(searchDetailFiltersAtom));
    if (!isNullish(symptomCategories)) {
      _p.symptomCategories = symptomCategories;
    }
    if (!isNullish(hospitalDepartmentHashIds)) {
      _p.hospitalDepartmentHashIds = hospitalDepartmentHashIds;
    }
  }

  const orderParam = tab === 'list' ? get(listOrder) : get(kanbanOrder);
  _p.order = `${orderParam.direction === 'desc' ? '-' : ''}${orderParam.orderColumn}`;

  return _p;
});

const convertFilterResults = (filterResults: ResultType[]): DetailFilter => {
  const detailFilter: DetailFilter = {};
  filterResults.forEach((item) => {
    switch (item.key as keyof DetailFilter) {
      case 'symptomCategories':
        detailFilter.symptomCategories = (item.resultValue as SelectorConditionValue).map((item) => item.value);
        break;
      case 'hospitalDepartmentHashIds':
        detailFilter.hospitalDepartmentHashIds = (item.resultValue as SelectorConditionValue).map((item) => item.value);
        break;
      default:
        break;
    }
  });
  return detailFilter;
};
