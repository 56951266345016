import {MenuItemType} from '@components/molecules/Buttons/PopperMenuButton/PopperMenuButtonV5';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandlerV5';
import {EnhancedTable, NoContentMessage, openSnackBar} from '@front-libs/ui';
import {UserTemp} from '@modules/hospital_users/helpers';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {
  patchReturnWaitingReceptions,
  ReturnWaitingReceptionsUpdateRequestParams,
} from '@modules/returns_reservation/api';
import {useFetchReturnWaitingReceptions, useReturnWaitingReceptionsCount} from '@modules/returns_reservation/hook';
import {
  ReturnWaitingReceptionsOrderType,
  ReturnWaitingReceptionsResponse,
  WaitingReceptionsStatusType,
} from '@modules/returns_reservation/type';
import {Box, SxProps} from '@mui/material';
import {PaginationAndPerPage} from '@organisms/PaginationAndPerPage';
import {TableColumns, TableLayoutDialog, TableLayoutDialogProps} from '@organisms/Table/TableLayoutDialog';
import React, {useEffect, useMemo} from 'react';
import {
  ReturnWaitingReceptionEditDialog,
  ReturnWaitingReceptionFormData,
  ReturnWaitingReceptionEditDialogProps,
} from './ReturnWaitingReceptionEditDialog';
import {ListTableHeader} from './ListTableHeader';
import {ReturnWaitingReceptionsTabData, TabOptionValue} from './TabButtons';
import {ResponseAddComponent, useColumns} from './useColumns';
import {useUserOptions} from './useUserOptions';
import {useReturnWaitingReceptionState} from './useReturnWaitingReceptionState';

const rootStyle: SxProps = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
};

const EditDialogHandler = async (editItem: ResponseAddComponent, userOptions: UserTemp[]) => {
  return await dialogHandler.open<ReturnWaitingReceptionEditDialogProps, ReturnWaitingReceptionFormData>(
    ReturnWaitingReceptionEditDialog,
    {
      editItem,
      userOptions,
      injectFirst: false, // FIXME:MuiSelectのスタイルが適用されないので一時的にfalseにしている
    }
  );
};

const openEditDialog = async (hospitalHashId: string, editItem: ResponseAddComponent, userOptions: UserTemp[]) => {
  const res = await EditDialogHandler(editItem, userOptions);
  if (!res) return;

  const updateColumns = ['status'];
  const requestBody: ReturnWaitingReceptionsUpdateRequestParams = {
    updateColumns,
    status: res.statusSelect,
  };

  // comment が存在する場合にのみ updateColumns とリクエストボディに追加
  if (res.comment) {
    updateColumns.push('comment');
    requestBody.comment = res.comment;
  }
  if (res.userSelect) {
    updateColumns.push('contact_person_hash_id');
    requestBody.contactPersonHashId = res.userSelect;
  }

  try {
    await patchReturnWaitingReceptions(hospitalHashId, editItem.hashId, requestBody);
    openSnackBar('対応内容を編集しました');
  } catch (_e) {
    console.error(_e);
    openSnackBar('対応内容を編集時にエラーが発生しました', 'error');
  }

  return;
};

/**
 * 返却待ち受付一覧テーブル
 */
export const ListTable = React.memo(
  () => {
    const {columns, tableLayout, setTableLayout, editItem, setEditItem} = useColumns();
    const {userOptions} = useUserOptions();
    const {myInfo} = useMyInfo();
    const {dispatch, param, page, pageSize, setPageSize} = useReturnWaitingReceptionState();
    const {data, isLoading, refetch} = useFetchReturnWaitingReceptions(myInfo.hospitalHashId, param);
    const {count: todayCount, refetch: todayCountRefetch} = useReturnWaitingReceptionsCount(myInfo.hospitalHashId);
    const {count: allCount, refetch: allCountRefetch} = useReturnWaitingReceptionsCount(myInfo.hospitalHashId, true);

    const tabData: ReturnWaitingReceptionsTabData[] = useMemo(() => {
      return [
        {label: '本日分', value: 'today', count: todayCount},
        {label: '全日分', value: 'all', count: allCount},
      ];
    }, [todayCount, allCount]);

    useEffect(() => {
      if (!editItem || userOptions.length === 0) return;
      (async () => {
        await openEditDialog(myInfo.hospitalHashId, editItem, userOptions);
        setEditItem(null);
        // ダイアログを閉じた後にリフェッチを行う 合わせてタブに表示している件数を更新する
        await Promise.all([refetch(), todayCountRefetch(), allCountRefetch()]);
      })();
    }, [editItem, userOptions, myInfo.hospitalHashId]);

    /** ページ変更 */
    const onPageChange = (newPage: number) => {
      dispatch({type: 'SET_PAGE', payload: newPage});
    };

    /** ページ件数変更 */
    const onPageSizeChange = (newPageSize: number) => {
      setPageSize(newPageSize);
      dispatch({type: 'SET_PAGE', payload: 1}); // ページサイズ変更時は1ページ目にリセット
    };

    /** タブ切り替え変更 */
    const TabChange = (val: TabOptionValue) => {
      dispatch({type: 'SET_LIST_TAB', payload: val});
    };

    /** フィルター変更 */
    const onFilterChange = (statusList: WaitingReceptionsStatusType[]) => {
      dispatch({
        type: 'SET_STATUS_LIST',
        payload: statusList,
      });
    };
    const onOrderChange = (order: keyof ReturnWaitingReceptionsResponse, orderDirection: 'desc' | 'asc') => {
      if (
        order === 'hashId' ||
        order === 'contactPersonHashId' ||
        order === 'narrowCategoryHashId' ||
        order === 'hospitalHashId' ||
        typeof order !== 'string'
      )
        return;

      const orderStr: ReturnWaitingReceptionsOrderType = `${orderDirection === 'desc' ? '-' : ''}${order === 'narrowCategoryName' ? 'narrowCategory' : order}`;
      dispatch({type: 'SET_ORDER', payload: orderStr});
    };

    /** アクションメニュークリック時 */
    const onActionMenuClick = async (item: MenuItemType) => {
      if (item.value !== 'changeTableLayout') return;
      try {
        const currentLayout = await dialogHandler.open<TableLayoutDialogProps, TableColumns[]>(TableLayoutDialog, {
          tableColumns: tableLayout?.tableLayout ?? [],
          defaultOptions: tableLayout?.currentLayout ?? [],
          forceValue: tableLayout?.forceValue ?? {},
        });

        setTableLayout({
          tableLayout: tableLayout?.tableLayout ?? [],
          currentLayout: currentLayout,
        });
      } catch (_e) {}
    };

    return (
      <Box sx={rootStyle}>
        <ListTableHeader
          handleTabChange={TabChange}
          handleFilterChange={onFilterChange}
          handleActionMenuClick={onActionMenuClick}
          tabData={tabData}
        />
        <EnhancedTable<ResponseAddComponent>
          data={data?.data ?? []}
          columns={columns}
          isLoading={isLoading}
          stickyHeader
          stickyLeftColumnsCount={1}
          noDataComponent={<NoContentMessage title={'返却待ち受付が登録されていません'} />}
          onOrderChange={(order, orderDirection) => order !== 'editButton' && onOrderChange(order, orderDirection)}
          options={{
            tableRowSx: {
              // MEMO:テーブルの最後の行はデフォルトでborder 0が指定されているのを上書きする
              '&:last-child td:first-of-type': {borderRight: '1px solid #E0E0E0'},
            },
          }}
        />
        <PaginationAndPerPage
          totalCount={data?.totalCount ?? 0}
          currentPage={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
        />
      </Box>
    );
  },
  () => true
);
