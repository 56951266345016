import React, {useMemo, ComponentProps, useCallback} from 'react';
import {StandardSelect} from '@components/molecules/Formik/fields/StandardSelectorField';
import {FormField, FormSection} from '../FormFieldsSection';
import {CategorySelect} from './CategorySelect';
import {HospitalProductDetail} from '@modules/hospital_products/types';
import {MakerSelect} from './MakerSelect';
import {StandardTextField} from '@components/molecules/Formik/fields/StandardTextField';
import dayjs from 'dayjs';
import {classNameOpts} from '@Apps/ProductRegistration/constants';
import {StandardDateField} from '@components/molecules/Formik/fields/StandardDateField';
import {productStatusOptions} from '@constants/constants';
import {useFetchHospitalRooms} from '@modules/hospital_places/api';
import {HospitalDealerParams, useHospitalDealerOption} from '@modules/hospital_dealer/api';
import {HospitalDepartmentParams, useHospitalDepartmentOption} from '@modules/hospital_departments/api';
import {
  formatManagementId,
  formatSerialNumber,
  formatLotNumber,
  formatBarcode,
  formatGS1Barcode,
  formatAssetRegisterNumber,
} from '@modules/hospital_products/utls';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {HospitalRoomFormatter} from '@modules/hospital_wards/helpers';
import {ProductDetailFormType} from '../../';
import {useFormikContext} from 'formik';
import {IsBaseUnitLabel} from './IsBaseUnitLabel';

const CreateStandardTextFieldWithFormatter = (key: keyof ProductDetailFormType, formatter: (s: string) => string) => {
  return (props: ComponentProps<typeof StandardTextField>) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {setFieldValue, values} = useFormikContext<ProductDetailFormType>();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const onBlur = useCallback(() => {
      if (values[key] && !props.disabled) {
        setFieldValue(key, formatter(`${values[key]}`));
      }
    }, [setFieldValue, values, props.disabled]);

    return <StandardTextField {...props} onBlur={onBlur} />;
  };
};

export const deviceTypes: FormSection = {
  sectionName: '機種情報',
  fields: [
    {
      type: 'text',
      label: '大分類',
      name: 'rootCategory',
      InputComponent: CategorySelect,
      readOnly: (product: HospitalProductDetail) => product.isShared,
    },
    {
      type: 'text',
      label: '小分類',
      name: 'narrowCategory',
      InputComponent: CategorySelect,
      readOnly: (product: HospitalProductDetail) => product.isShared,
    },
    {
      type: 'text',
      label: 'メーカー名',
      name: 'maker',
      getValue: (product: HospitalProductDetail) => product.maker?.name ?? '',
      InputComponent: MakerSelect,
      readOnly: (product: HospitalProductDetail) => product.isShared,
    },
    {
      type: 'textarea',
      label: '機種名',
      name: 'displayName',
      InputComponent: StandardTextField,
      readOnly: (product: HospitalProductDetail) => product.isShared,
      multiline: true,
    },
    {
      type: 'text',
      label: '型式',
      name: 'name',
      InputComponent: StandardTextField,
      readOnly: (product: HospitalProductDetail) => product.isShared,
    },
    {
      type: 'text',
      label: '承認番号',
      name: 'approvalNumber',
      // onBlurでフォーマットするとValidationエラー表示のonBlurとバッテイングするのでTextFelidに置き換え
      // InputComponent: CreateStandardTextFieldWithFormatter('approvalNumber', formatApprovalNumber),
      InputComponent: StandardTextField,
      readOnly: (product: HospitalProductDetail) => product.isShared,
    },
    {
      type: 'text',
      label: 'JMDNコード',
      name: 'jmdnCode',
      InputComponent: StandardTextField,
      readOnly: (product: HospitalProductDetail) => product.isShared,
    },
    {
      type: 'text',
      label: 'JANコード',
      name: 'janCode',
      InputComponent: StandardTextField,
      readOnly: (product: HospitalProductDetail) => product.isShared,
    },
    {
      type: 'text',
      label: 'GTINコード',
      name: 'newJanCode',
      InputComponent: StandardTextField,
      readOnly: true,
    },
    {
      type: 'boolean',
      label: '特定保守製品',
      name: 'isSpecificMaintain',
      readOnly: (product: HospitalProductDetail) => product.isShared,
      InputComponent: StandardSelect,
      options: [
        {label: '該当', value: true},
        {label: '非該当', value: false},
      ],
    },
    {
      type: 'text',
      label: 'クラス分類',
      name: 'className',
      readOnly: (product: HospitalProductDetail) => product.isShared,
      InputComponent: StandardSelect,
      options: classNameOpts,
    },
    {
      type: 'number',
      label: '定価',
      name: 'catalogPrice',
      readOnly: (product: HospitalProductDetail) => product.isShared,
      InputComponent: StandardTextField,
    },
  ],
};

// FIXME 何も指定しない場合20件表示。21件以上の場合表示できないincident発生したため、一時的に100件表示に変更
const params: HospitalDepartmentParams = {
  page: 0,
  perPage: 100,
};

export const devices: FormSection = {
  sectionName: '機器情報',
  fields: [
    {
      type: 'text',
      label: '管理番号',
      name: 'managementId',
      InputComponent: CreateStandardTextFieldWithFormatter('managementId', formatManagementId),
    },
    {
      type: 'text',
      label: 'シリアル番号',
      name: 'serialNumber',
      InputComponent: CreateStandardTextFieldWithFormatter('serialNumber', formatSerialNumber),
    },
    {
      type: 'text',
      label: 'ロット番号',
      name: 'lotNumber',
      InputComponent: CreateStandardTextFieldWithFormatter('lotNumber', formatLotNumber),
    },
    {
      type: 'select',
      label: '貸出区分',
      name: 'permanentlyAssigned',
      InputComponent: StandardSelect,
      options: [
        {label: '貸出不可', value: true},
        {label: '貸出可', value: false},
      ],
    },
    {
      type: 'text',
      label: '稼働状況',
      name: 'status',
      InputComponent: StandardSelect,
      options: productStatusOptions,
    },
    {
      type: 'select',
      label: '所在（院内/院外）',
      name: 'isOutsideOfHospital',
      InputComponent: StandardSelect,
      options: [
        {label: '院内', value: false},
        {label: '院外', value: true},
      ],
    },
    {
      type: 'select',
      label: '管理部署',
      // NOTE:ACC-316の要件にて管理部署と所属部署のデータを統合。データ統合に伴い、keyをhospitalDepartmentHashIdに変更している。
      name: 'hospitalDepartmentHashId',
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      InputComponent: (props: any) => {
        const {myInfo} = useMyInfo();
        const hospitalDepartmentOption = useHospitalDepartmentOption(myInfo.hospitalHashId, params);
        return <StandardSelect {...props} options={hospitalDepartmentOption} />;
      },
    },
    {
      type: 'text',
      label: '機器管理場所',
      name: 'hospitalRoomHashId',
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      InputComponent: (props: any) => {
        const {myInfo} = useMyInfo();
        const hospitalRooms = useFetchHospitalRooms(myInfo.hospitalHashId);
        const options = useMemo(
          () =>
            (hospitalRooms?.data ?? []).map((room) => ({
              label: HospitalRoomFormatter.getFullRoom(room),
              value: room.hashId,
            })),
          [hospitalRooms.data]
        );
        return hospitalRooms?.isFetched ? <StandardSelect {...props} options={options} /> : null;
      },
    },
    {
      type: 'text',
      label: '貸出場所',
      name: 'rentHospitalRoomHashId',
      readOnly: (_product: HospitalProductDetail) => true,
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      InputComponent: (props: any) => {
        const {myInfo} = useMyInfo();
        const hospitalRooms = useFetchHospitalRooms(myInfo.hospitalHashId);
        const options = useMemo(
          () =>
            (hospitalRooms?.data ?? []).map((room) => ({
              label: HospitalRoomFormatter.getFullRoom(room),
              value: room.hashId,
            })),
          [hospitalRooms.data]
        );
        return hospitalRooms?.isFetched ? <StandardSelect {...props} options={options} /> : null;
      },
    },
    {
      type: 'text',
      label: <IsBaseUnitLabel />,
      name: 'isBaseUnit',
      InputComponent: StandardSelect,
      options: [
        {label: '親機', value: true},
        {label: '子機', value: false},
        {label: '非該当', value: ''},
      ],
    },
    {
      type: 'text',
      label: '購入日',
      name: 'dateOfPurchase',
      InputComponent: StandardDateField,
      clearable: true,
    },
    {
      type: 'text',
      label: 'バーコード読み取り値',
      name: 'optionalBarcode',
      InputComponent: CreateStandardTextFieldWithFormatter('optionalBarcode', formatBarcode),
    },
    {
      type: 'text',
      label: 'GS1バーコード',
      name: 'gs1Barcode',
      InputComponent: CreateStandardTextFieldWithFormatter('gs1Barcode', formatGS1Barcode),
    },
    // FIXME: 販売代理店を追加する
  ].filter(Boolean) as FormField[],
};

export const deviceDetails: FormSection = {
  sectionName: '詳細情報',
  fields: [
    {
      type: 'select',
      label: '担当代理店',
      name: 'hospitalDealerHashId',
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      InputComponent: (props: any) => {
        const {myInfo} = useMyInfo();
        // FIXME 何も指定しない場合20件表示。21件以上の場合表示できないincident発生したため、一時的に100件表示に変更
        const params: HospitalDealerParams = {
          page: 0,
          perPage: 100,
        };
        const hospitalDealerOptions = useHospitalDealerOption(myInfo.hospitalHashId, params);
        return <StandardSelect {...props} options={hospitalDealerOptions} />;
      },
    },
    {
      type: 'text',
      label: '購入区分',
      name: 'waysOfPurchase',
      InputComponent: StandardSelect,
      options: [
        {label: '購入', value: 'purchase'},
        {label: 'リース', value: 'lease'},
        {label: 'レンタル', value: 'rental'},
        {label: '代替品', value: 'alternative'},
        {label: 'デモ機', value: 'demo'},
        {label: '寄贈', value: 'gift'},
        {label: '移管', value: 'transfer_control'},
      ],
    },
    // FIXME: レンタル関連はここ
    {
      type: 'select',
      label: '購入元',
      name: 'purchasedNationalExpense',
      InputComponent: StandardSelect,
      options: [
        {label: '国費', value: true},
        {label: '院費', value: false},
      ],
    },
    {
      type: 'number',
      label: '納入価',
      name: 'deliveryPrice',
      isVisible: (values: HospitalProductDetail) => values.waysOfPurchase === 'purchase',
      InputComponent: StandardTextField,
    },
    {
      type: 'select',
      label: '税込／税抜き',
      name: 'taxIncluded',
      isVisible: (values: HospitalProductDetail) => values.waysOfPurchase === 'purchase',
      InputComponent: StandardSelect,
      options: [
        {label: '税込', value: true},
        {label: '税抜き', value: false},
      ],
    },
    {
      type: 'number',
      label: '税率',
      name: 'taxRate',
      isVisible: (values: HospitalProductDetail) => values.waysOfPurchase === 'purchase',
      InputComponent: StandardTextField,
    },
    {
      type: 'text',
      label: '資産番号',
      name: 'assetRegisterNumber',
      InputComponent: CreateStandardTextFieldWithFormatter('assetRegisterNumber', formatAssetRegisterNumber),
    },
    {
      type: 'number',
      label: '院内耐用年数（年）',
      name: 'legalDurableYear',
      InputComponent: StandardTextField,
    },
    {
      type: 'number',
      label: '残存年数（年）',
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      name: 'remainingYear' as any,
      readOnly: (_product: HospitalProductDetail) => true,
      getValue: (product: HospitalProductDetail) => {
        const {legalDurableYear, dateOfPurchase} = product;
        if (!legalDurableYear || !dateOfPurchase) return '';

        const now = dayjs();
        const diff = now.diff(dateOfPurchase, 'year');
        return Math.max(0, legalDurableYear - diff);
      },
      InputComponent: StandardTextField,
    },
    // {
    //   type: 'number',
    //   label: '減価償却費（円）',
    //   name: 'depreciationAmount',
    //   InputComponent: StandardTextField,
    // },
    // {
    //   type: 'number',
    //   label: '帳簿価格（円）',
    //   name: 'bookValue',
    //   InputComponent: StandardTextField,
    // },
    {
      type: 'text',
      label: '廃棄日',
      name: 'dateOfDisposal',
      InputComponent: StandardDateField,
      clearable: true,
    },
    {
      type: 'textarea',
      label: '廃棄理由',
      name: 'reasonOfDisposal',
      InputComponent: StandardTextField,
      multiline: true,
    },
  ],
};

export const leaseSection: FormSection = {
  sectionName: 'リース情報',
  fields: [
    {
      type: 'text',
      label: 'リース番号',
      name: 'leaseId',
      InputComponent: StandardTextField,
    },
    {
      type: 'text',
      label: 'リース企業名',
      name: 'leaseDealerName',
      InputComponent: StandardTextField,
    },
    {
      type: 'number',
      label: 'リース金額',
      name: 'leaseFee',
      InputComponent: StandardTextField,
    },
    {
      type: 'text',
      label: 'リース開始日',
      name: 'leaseStartDate',
      InputComponent: StandardDateField,
      clearable: true,
    },
    {
      type: 'text',
      label: 'リース終了予定日',
      name: 'leaseDueDate',
      InputComponent: StandardDateField,
      clearable: true,
    },
    {
      type: 'text',
      label: 'リース機器返却日',
      name: 'leaseReturnDate',
      InputComponent: StandardDateField,
      clearable: true,
    },
  ],
};

export const rentalSection: FormSection = {
  sectionName: 'レンタル情報',
  fields: [
    {
      type: 'text',
      label: 'レンタル番号',
      name: 'rentalId',
      InputComponent: StandardTextField,
    },
    {
      type: 'text',
      label: 'レンタル企業名',
      name: 'rentalDealerName',
      InputComponent: StandardTextField,
    },
    {
      type: 'number',
      label: 'レンタル金額',
      name: 'rentalFee',
      InputComponent: StandardTextField,
    },
    {
      type: 'text',
      label: 'レンタル開始日',
      name: 'rentalStartDate',
      InputComponent: StandardDateField,
      clearable: true,
    },
    {
      type: 'text',
      label: 'レンタル終了予定日',
      name: 'rentalDueDate',
      InputComponent: StandardDateField,
      clearable: true,
    },
    {
      type: 'text',
      label: 'レンタル機器返却日',
      name: 'rentalReturnDate',
      InputComponent: StandardDateField,
      clearable: true,
    },
  ],
};

/**
 * デモ機情報のFormSection
 */
export const demonstrationFormSection: FormSection = {
  sectionName: 'デモ機情報',
  fields: [
    {
      type: 'text',
      label: 'デモの目的',
      name: 'purposeOfDemonstration',
      InputComponent: StandardTextField,
    },
    {
      type: 'text',
      label: 'デモ開始日',
      name: 'demonstrationStartDate',
      InputComponent: StandardDateField,
      clearable: true,
    },
    {
      type: 'text',
      label: 'デモ終了日',
      name: 'demonstrationEndDate',
      InputComponent: StandardDateField,
      clearable: true,
    },
  ],
};

export const makerInspectionFormSection: FormSection = {
  sectionName: 'メーカー保守点検設定',
  fields: [
    {
      type: 'text',
      label: '保守契約',
      name: 'isMaintenanceContract',
      InputComponent: StandardSelect,
      options: [
        {label: '保守契約', value: true},
        {label: '保守契約外', value: false},
      ],
    },
    {
      type: 'text',
      label: '保守契約期限',
      name: 'makerInspectionSetting.dueDateOfMakerInspection',
      InputComponent: StandardDateField,
      clearable: true,
    },
    {
      type: 'text',
      label: '次回点検予定日',
      name: 'makerInspectionSetting.nextInspectionDate',
      InputComponent: StandardDateField,
      clearable: true,
    },
  ],
};
