import {Box, SxProps, Typography} from '@mui/material';
import {ProductThumbnail} from './ProductThumbnail';
import React from 'react';
import {WholeProductIndex} from '@modules/products/types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {PopperMenuButtonV5} from '@components/molecules/Buttons/PopperMenuButton/PopperMenuButtonV5';
import {useNavigate} from 'react-router-dom';
import {useCopyWholeProduct} from '@Apps/WholeProductList/hooks';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';

const boxStyle: SxProps = {
  width: '100%',
  height: '44px',
  backgroundColor: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
  borderRadius: '20px 20px 0 0',
  borderBottom: '1px solid #D1D5DB',
};

const displayTextStyle: SxProps = {
  fontWeight: 'bold',
  marginLeft: '124px',
};

type ValueType = 'register' | 'reuse' | 'delete';

type ProductHeaderProps = {
  wholeProduct: WholeProductIndex;
};

export const ProductHeader = ({wholeProduct}: ProductHeaderProps) => {
  const {canEdit: canEditDeviceList} = useUserResourcesPermissions('DEVICE_LIST');
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();
  const {isReadOnly} = useMyRole();

  const copyWholeProduct = useCopyWholeProduct({
    hospitalHashId: myInfo.hospitalHashId,
  });

  const handleDelete = () => {
    // FIXME:削除実装時にAPIを呼び出す
  };

  const MenuClickActions: Record<ValueType, () => void> = {
    register: () => navigate(`/products/registration/Step2/${wholeProduct.hashId}`),
    reuse: () => copyWholeProduct(wholeProduct),
    delete: () => handleDelete(),
  };

  return (
    <Box sx={boxStyle}>
      <Typography component="p" sx={displayTextStyle}>
        {`${wholeProduct.displayName} ${wholeProduct.name}`}
      </Typography>
      <ProductThumbnail wholeProductHashId={wholeProduct.hashId} src={wholeProduct.thumbnailUrl ?? ''} />
      {((FEATURE_CUSTOM_ASSET_ROLE_FLAG && canEditDeviceList) || (!FEATURE_CUSTOM_ASSET_ROLE_FLAG && !isReadOnly)) && (
        <PopperMenuButtonV5<ValueType>
          buttonProps={{variant: 'text', size: 'small', disableElevation: true}}
          menuItemList={[
            {label: '機器を登録', value: 'register'},
            {label: '機種を複製して機器を登録', value: 'reuse'},
            // FIXME: 削除は初期リリースのスコープ外 削除API実装時にコメントを外す
            // ...(isAdmin ? [{label: '削除', value: 'delete' as ValueType, sx: {color: '#C7243A'}}] : []),
          ]}
          hiddenArrow
          onMenuClick={(action) => MenuClickActions[action.value]()}>
          <MoreVertIcon sx={{color: 'rgba(0, 0, 0, 0.87)'}} />
        </PopperMenuButtonV5>
      )}
    </Box>
  );
};
