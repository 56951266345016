import React, {useEffect} from 'react';
import {createStyles, makeStyles, Paper, Theme} from '@material-ui/core';
import {RentableTable} from './RentableTable';
import {PaperHeader} from '../PaperHeader';
import {papers} from '../consts';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {useNavigate} from 'react-router-dom';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '0px 16px 16px',
      borderTop: `4px solid ${theme.palette.primary.dark}`,
      height: 'calc(100% - 20px)',
    },
  })
);

export const Rentables: React.FC = () => {
  const {canAccess: canAccessLendingReturn} = useUserResourcesPermissions('LENDING_RETURN');
  const navigate = useNavigate();
  if (FEATURE_CUSTOM_ASSET_ROLE_FLAG) {
    useEffect(() => {
      if (!canAccessLendingReturn) {
        navigate('/dashboard');
      }
    }, [canAccessLendingReturn, navigate]);
    if (!canAccessLendingReturn) {
      return null;
    }
  }
  const classes = useStyles();
  const paper = papers.rentables;

  return (
    <Paper className={classes.paper}>
      <PaperHeader title={paper.label} />
      <RentableTable />
    </Paper>
  );
};
