import {HeaderBackwardButton} from '@components/molecules/Buttons/HeaderBackwardButton';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useFetchWholeProductQuery} from '@modules/products/api';
import {InnerLoading} from '@molecules/Loading';
import {Box} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {WholeProductContent} from './WholeProductContent';
import React, {useEffect} from 'react';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';

const BackProductsButton = () => {
  const navigate = useNavigate();
  const handleClickProductsLink = () => {
    navigate('/products/whole_products');
  };
  return <HeaderBackwardButton label="院内機種一覧" onClick={handleClickProductsLink} />;
};

export const WholeProductDetail = () => {
  const navigate = useNavigate();
  const {canAccess} = useUserResourcesPermissions('DEVICE_LIST');
  if (FEATURE_CUSTOM_ASSET_ROLE_FLAG) {
    useEffect(() => {
      if (!canAccess) {
        navigate('/dashboard');
      }
    }, [canAccess, navigate]);
    if (!canAccess) {
      return null;
    }
  }
  const {myInfo} = useMyInfo();
  const {hashId: wholeProductHashId} = useParams();
  const {data, refetch} = useFetchWholeProductQuery(myInfo.hospitalHashId, wholeProductHashId ?? '');

  if (!data) {
    return <InnerLoading />;
  }
  return (
    <>
      <Box sx={{width: '100%', padding: '0px 32px'}}>
        <BackProductsButton />
      </Box>
      <WholeProductContent wholeProduct={data} refetch={refetch} />
    </>
  );
};
