import {BlueBackgroundCounter} from '@front-libs/ui';
import {Box, Tab, Tabs} from '@mui/material';
import React, {useState} from 'react';

export type TabOptionValue = 'all' | 'today';

export type ReturnWaitingReceptionsTabData = {
  label: string;
  value: TabOptionValue;
  count: number;
};

const TabText = ({label, count}: {label: string; count: number}) => {
  return (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      {label}
      {count > 0 && <BlueBackgroundCounter count={count} />}
    </Box>
  );
};

/**
 * 返却待ち受付一覧画面のタブボタン
 *
 * @returns
 */
export const TabButtons = ({
  handleChange,
  tabData: TabData,
}: {handleChange: (val: TabOptionValue) => void; tabData: ReturnWaitingReceptionsTabData[]}) => {
  const [value, setValue] = useState<TabOptionValue>('today');

  return (
    <Tabs
      value={value}
      onChange={(_e, val) => {
        setValue(val);
        handleChange(val);
      }}
      sx={{'& button': {fontWeight: 'bold'}}}
      aria-label="returnWaitingReceptions tabs">
      {TabData.map((tab) => (
        <Tab key={tab.value} label={<TabText label={tab.label} count={tab.count} />} value={tab.value} />
      ))}
    </Tabs>
  );
};
