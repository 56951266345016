import {useMemo} from 'react';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {UserFormatter} from '@modules/hospital_users/helpers';

export const useUserOptions = () => {
  const {hospitalUsers, isLoading: isUsersLoading} = useHospitalUsers();

  const userOptions = useMemo(
    () => UserFormatter.getOptions(hospitalUsers, {withAlias: true, withSubLabel: true}),
    [hospitalUsers]
  );

  return {userOptions, isUsersLoading};
};
