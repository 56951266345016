import {Typography, styled} from '@material-ui/core';
import React from 'react';
import {Build} from '@material-ui/icons';
import dayjs from 'dayjs';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {UserIndex} from '@modules/hospital_users/types';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

export type RepairRegistrationCardProps = {
  user?: UserIndex;
  faultRepairHashId: string;
  displayDate: Date;
  repairRegistrationNumber?: string;
};

export const RepairRegistrationCard = ({
  user,
  faultRepairHashId,
  displayDate,
  repairRegistrationNumber,
}: RepairRegistrationCardProps) => {
  const {canRead: canReadRepair} = useUserResourcesPermissions('REPAIR');
  return (
    <RootContainer>
      <IconContainer>
        <Build style={{height: '22px'}} />
      </IconContainer>
      <Container>
        <Header>
          <Title>
            {(FEATURE_CUSTOM_ASSET_ROLE_FLAG && canReadRepair) || !FEATURE_CUSTOM_ASSET_ROLE_FLAG ? (
              <Link href={`/repairs/${faultRepairHashId}`} target="_blank" rel="noreferrer">
                修理情報
              </Link>
            ) : (
              '修理情報'
            )}
            の登録
          </Title>
          <CommentDate>{dayjs(displayDate).format('MM/DD HH:mm')}</CommentDate>
        </Header>
        <Content>
          <ContentTypography bottom={4}>修理番号：{repairRegistrationNumber} </ContentTypography>
          <ContentTypography>登録者　：{user ? UserFormatter.getFullName(user) : '不明なユーザー'} </ContentTypography>
        </Content>
      </Container>
    </RootContainer>
  );
};

const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '16px',
});

const IconContainer = styled('div')({
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F0F2F5',
});

const CommentDate = styled(Typography)({
  fontSize: '12px',
  color: '#65676B',
});

const ContentTypography = styled(Typography)(({bottom}: {bottom?: number}) => ({
  fontSize: '14px',
  color: '#172B4D',
  ...(bottom && {marginBottom: `${bottom}px`}),
}));

const Container = styled('div')({
  paddingLeft: '8px',
  width: '100%',
});

const Content = styled('div')({});

const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '32px',
  paddingBottom: '8px',
  justifyContent: 'space-between',
});

const Title = styled('div')({
  fontWeight: 600,
  color: '#172B4D',
});

const Link = styled('a')({
  color: '#0052CC',
  textDecoration: 'none',
});
