import React from 'react';
import {Box} from '@mui/material';
import {
  BaseSharedListTable,
  BaseSharedListTableColumn,
  tableTitleStyle,
} from '@Apps/BaseSharedMenu/BaseSharedListTable';
import {CancelButton} from '@Apps/BaseSharedMenu/CancelButton';
import {ReturnTargetHospitalProducts} from '@Apps/BaseSharedMenu/ReturnDevices/types';

type RowData = {
  managementId: string;
  cancelButton: JSX.Element | null;
};

const columns: BaseSharedListTableColumn<RowData>[] = [
  {id: 'managementId', label: '管理番号', minWidth: '140px'},
  {id: 'cancelButton', label: '', minWidth: 'fit-content', sx: {width: 'fix-content'}},
];

export type TableProps = {
  returnTargetHospitalProducts: ReturnTargetHospitalProducts;
  onClickCancel: (rowIndex: number) => void;
};

export const ScannedProductTable: React.FC<TableProps> = ({
  returnTargetHospitalProducts /* 返却対象機器リスト(親子関係対応済) */,
  onClickCancel,
}) => {
  const tableRows: RowData[] = returnTargetHospitalProducts.map((item, index) => ({
    managementId: item.managementId ?? '',
    cancelButton: item.managementId ? (
      <CancelButton
        onClickCancel={() => {
          onClickCancel(index);
        }}
      />
    ) : null,
  }));
  return (
    <>
      <Box sx={tableTitleStyle}>返却する医療機器リスト</Box>
      <BaseSharedListTable<RowData> tableRows={tableRows} columns={columns} />
    </>
  );
};
