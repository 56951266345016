import React, {useEffect} from 'react';
import {
  Button,
  CSSObject,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import {LabelAndText} from '@components/molecules/LabelAndText';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {ProductIndex} from '@modules/products/types';
import {productClassTypeToClassNameLabel} from '@modules/products/constants';
import {isNullish} from '@front-libs/helpers';
import {useNavigate} from 'react-router-dom';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';

const dialogTitleStyle: CSSObject = {
  '& h2': {
    color: '#172B4D',
    fontSize: '20px',
    fontWeight: 'bold',
  },
};

const labelColor = '#5D6B82';

export type WholeProductConfirmDialogProps = {
  wholeProduct: ProductIndex;
} & DialogProps;

export const WholeProductConfirmDialog: React.FC<WholeProductConfirmDialogProps> = ({open, actions, wholeProduct}) => {
  const navigate = useNavigate();
  // 登録用の画面なので、編集権限があるかを確認
  const {canEdit: canEditDeviceList} = useUserResourcesPermissions('DEVICE_LIST');
  if (FEATURE_CUSTOM_ASSET_ROLE_FLAG) {
    useEffect(() => {
      if (!canEditDeviceList) {
        navigate('/dashboard');
      }
    }, [canEditDeviceList, navigate]);
    if (!canEditDeviceList) {
      return null;
    }
  }
  const handleClose = () => {
    actions.reject();
  };
  return (
    <Dialog open={!!open} onClose={handleClose} fullWidth={true} maxWidth="sm" aria-labelledby="form-dialog-title">
      <DialogTitle sx={{padding: '24px 32px 0px'}}>
        <Grid container justifyContent="space-between" alignItems="center" sx={dialogTitleStyle}>
          <Typography variant="h2">読み込み内容の確認</Typography>
          <IconButton onClick={handleClose} sx={{padding: '0px'}}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{padding: '0 32px'}}>
        <Typography sx={{padding: '32px 0 0', fontSize: '14px'}}>
          登録済みの院内機種がヒットしました。
          <br />
          内容を確認し、よろしければ「機種を確定して次へ」をクリックしてください。
        </Typography>
        <Content wholeProduct={wholeProduct} />
      </DialogContent>
      <DialogActions>
        <Button variant={'contained'} color="primary" onClick={actions.resolve}>
          機種を確定して次へ
        </Button>
        <Button onClick={handleClose} color="primary">
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Content: React.FC<{wholeProduct: ProductIndex}> = ({wholeProduct}) => {
  const {categories, maker, isSpecificMaintain, displayName, name, approvalNumber, className, catalogPrice} =
    wholeProduct;
  const broadCategory = (categories && categories.find(({depth}) => depth === 0)?.name) || '';
  const narrowCategory = (categories && categories.find(({depth}) => depth === 1)?.name) || '';
  const makerName = maker?.name || '';
  const specificMaintain = isSpecificMaintain == null ? '' : isSpecificMaintain ? '該当' : '非該当';
  const classNameText = (className && productClassTypeToClassNameLabel[className]) || '';
  const price = !isNullish(catalogPrice) ? `${catalogPrice.toLocaleString()}円` : '';

  return (
    <Grid
      container
      direction="column"
      sx={{
        width: '100%',
        marginBottom: '16px',
        '& > :not(:first-child)': {
          marginTop: '16px',
        },
      }}>
      <LabelAndText label="大分類" text={broadCategory} labelColor={labelColor} />
      <LabelAndText label="小分類" text={narrowCategory} labelColor={labelColor} />
      <LabelAndText label="機種名" text={displayName} labelColor={labelColor} />
      <LabelAndText label="型式" text={name} labelColor={labelColor} />
      <LabelAndText label="メーカー名" text={makerName} labelColor={labelColor} />
      <LabelAndText label="承認番号" text={approvalNumber || ''} labelColor={labelColor} />
      <LabelAndText
        label="JMDNコード"
        text={wholeProduct.jmdnCode != null ? String(wholeProduct.jmdnCode) : ''}
        labelColor={labelColor}
      />
      <LabelAndText label="JANコード" text={wholeProduct.janCode || ''} labelColor={labelColor} />
      <LabelAndText label="特定保守製品" text={specificMaintain} labelColor={labelColor} />
      <LabelAndText label="クラス分類" text={classNameText} labelColor={labelColor} />
      <LabelAndText label="定価" text={price} labelColor={labelColor} />
    </Grid>
  );
};
