import {Box, Grid, styled, useMediaQuery} from '@material-ui/core';
import React, {useCallback, useEffect, useState} from 'react';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {openSnackBar} from '@molecules/SnackBar';
import {uploadFile} from '@modules/files/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {HospitalProductIndex} from '@modules/hospital_products/types';
import {PmdaFileUploadIconButton} from '@molecules/Buttons/PmdaFileUploadIconButton';
import {UploadDocumentDialog} from '@organisms/Files/FileList/UploadDocumentDialog';
import {createPmdaFile, deletePmdaFile, useHospitalProductPmdaFilesQuery} from '@modules/pmda_files/api';
import {PmdaPopperVersionButton} from '@organisms/PmdaPopperVersionButton';
import {PDFDisplay} from '@molecules/Utils/PDFDisplay';
import {isNullish} from '@front-libs/helpers';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {APPENDIX_WIDTH_BREAK_POINT} from '@Apps/ProductDetail/styled';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
import {ZoomButtons} from '@components/molecules/Dialogs/FilePreviewDialog';
import {FilePreview} from '@components/molecules/FilePreview';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';

// 調整用の長さ(HITOTSUのヘッダーメニュー、ナビゲーション、詳細のヘッダー、補足情報のヘッダー)
const OTHER_ADJUST_HEIGHT_PC = '287px';
const OTHER_ADJUST_HEIGHT_TABLET = '306px';

const ZOOM_COEFFICIENT = 1.2;
const MAX_ZOOM = 3;
const MIN_ZOOM = 1;

const RootContainer = styled('div')({
  margin: '16px 32px',
  flexGrow: 1,
});

const FileHeader = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px',
  width: '100%',
});

const PmdaVersionContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between', // 子要素間のスペースを最大化
  marginBottom: '16px',
  width: '100%', // 親要素の幅を最大化
  marginTop: '13px',
});

const PmdaVersionContainerInTablet = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '16px',
  width: '100%', // 親要素の幅を最大化
  marginTop: '8px',
});

const PdfViewer = styled(Grid)({
  width: '100%',
  height: `calc(100vh - ${OTHER_ADJUST_HEIGHT_PC})`,
});

const PdfViewerInTablet = styled(Grid)({
  width: '100%',
  height: `calc(100vh - ${OTHER_ADJUST_HEIGHT_TABLET})`,
});

const ButtonBox = styled(Box)({
  padding: '8px 0px 0px',
  display: 'flex',
  gap: 16,
});

type Props = {
  hospitalProduct: HospitalProductIndex;
};

export const PmdaFileTab = ({hospitalProduct}: Props) => {
  const {canEdit: canEditDeviceList, canDelete: canDeleteDeviceList} = useUserResourcesPermissions('DEVICE_LIST');
  const {myInfo} = useMyInfo();
  const {isReadOnly, isAdmin} = useMyRole();
  const [fileHashId, setFileHashId] = useState<string | null>(null);
  // ファイル取得
  const {data, isLoading, refetch} = useHospitalProductPmdaFilesQuery(myInfo.hospitalHashId, hospitalProduct.hashId);
  const isPdfDisplay = !isNullish(data?.data) && !isLoading;
  const isTabletDisplay = useMediaQuery(`(max-width:${APPENDIX_WIDTH_BREAK_POINT})`);

  const [scale, setScale] = useState(1.0);

  const handleZoomIn = useCallback(() => {
    const newScale = scale * ZOOM_COEFFICIENT;
    setScale(newScale > MAX_ZOOM ? MAX_ZOOM : newScale);
  }, [scale]);

  const handleZoomOut = useCallback(() => {
    const newScale = scale / ZOOM_COEFFICIENT;
    setScale(newScale < MIN_ZOOM ? MIN_ZOOM : newScale);
  }, [scale]);

  const handleUploadDocument = useCallback(async () => {
    const fileData = await dialogHandler.open(UploadDocumentDialog, {
      acceptFile: ['application/pdf'],
    });
    try {
      const newFile = await uploadFile({
        ...fileData,
        category: 'pmda',
      });

      await createPmdaFile(myInfo.hospitalHashId, hospitalProduct.hashId, {
        file_hash_id: newFile.data.hashId,
        is_user_upload: true,
      });
      refetch();
      openSnackBar('ファイルをアップロードしました');
    } catch (_e) {
      openSnackBar('ファイルのアップロードに失敗しました', 'left', 'bottom', 'error');
    }
  }, [hospitalProduct.hashId, myInfo.hospitalHashId, refetch]);

  const handleClickSettingMenu = useCallback((newFileHashId: string) => {
    setFileHashId(newFileHashId);
  }, []);

  const handleDelete = useCallback(
    async (hashId: string) => {
      await dialogHandler.open(AlertDialog, {
        title: '添付文書の削除',
        content: '手動でアップロードされた添付文書を削除します。\nこの操作は元に戻せません。',
      });
      await deletePmdaFile(myInfo.hospitalHashId, hospitalProduct.hashId, hashId);
      refetch();
      openSnackBar('添付文書を削除しました');
    },
    [hospitalProduct.hashId, myInfo.hospitalHashId, refetch]
  );

  useEffect(() => {
    if (isNullish(data) || isNullish(data.data)) {
      setFileHashId(null);
      return;
    } else {
      setFileHashId(data.data[0].file.hashId);
    }
  }, [data]);

  const PmdaVersionContent = () => {
    return (
      <>
        バージョン：
        {isPdfDisplay &&
          ((FEATURE_CUSTOM_ASSET_ROLE_FLAG && canDeleteDeviceList) || (!FEATURE_CUSTOM_ASSET_ROLE_FLAG && isAdmin)) && (
            <PmdaPopperVersionButton
              pmdaFiles={data?.data || []}
              onClickSettingMenu={handleClickSettingMenu}
              onClickDelete={handleDelete}
            />
          )}
      </>
    );
  };

  return (
    <RootContainer>
      <FileHeader>
        {((FEATURE_CUSTOM_ASSET_ROLE_FLAG && canEditDeviceList) ||
          (!FEATURE_CUSTOM_ASSET_ROLE_FLAG && !isReadOnly)) && (
          <PmdaFileUploadIconButton onUploadFile={handleUploadDocument} />
        )}
      </FileHeader>
      {isTabletDisplay ? (
        <>
          <PmdaVersionContainerInTablet>
            <PmdaVersionContent />
            <ButtonBox>
              <ZoomButtons handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut} />
            </ButtonBox>
          </PmdaVersionContainerInTablet>
          <PdfViewerInTablet>
            {!isNullish(fileHashId) ? (
              <FilePreview
                fileHashId={fileHashId}
                fileName={'PMDA添付文書'}
                fileType={'application/pdf'}
                scale={scale}
              />
            ) : (
              <NoPmdaFile />
            )}
          </PdfViewerInTablet>
        </>
      ) : (
        <>
          <PmdaVersionContainer>
            <PmdaVersionContent />
          </PmdaVersionContainer>
          <PdfViewer>
            {!isNullish(fileHashId) ? (
              <FilePreview
                fileHashId={fileHashId}
                fileName={'PMDA添付文書'}
                fileType={'application/pdf'}
                scale={scale}
              />
            ) : (
              <NoPmdaFile />
            )}
          </PdfViewer>
        </>
      )}
    </RootContainer>
  );
};

export const NoPmdaFile = () => {
  return (
    <div>
      表示できる添付文書はありません。
      <br />
      以下のいずれかの場合に、当該機器について添付文書が表示されます。
      <ol>
        <li>
          以下の両方を満たすことにより、当該機器の添付文書を特定できる場合
          <PmdaConditionsOl>
            <li>
              ユーザーの機器台帳に当該機器の承認番号、JANコード、GS1標準バーコードのいずれかが登録されている
              <Note>※ 上記番号等の登録翌日に添付文書が表示されます</Note>
            </li>
            <li>
              <PmdaLink href="https://www.pmda.go.jp/" target="_blank" rel="noreferrer">
                独立行政法人医薬品医療機器総合機構(PMDA)のウェブサイト
              </PmdaLink>
              に当該機器の添付文書が掲載されている
            </li>
          </PmdaConditionsOl>
        </li>
        <li>ユーザーが添付文書を手動アップロードした場合(同一病院内で有効)</li>
      </ol>
    </div>
  );
};

const PmdaConditionsOl = styled('ol')({
  listStyleType: 'lower-latin',
});

const Note = styled('p')(({theme}) => ({
  margin: '0px 0px 4px 2px',
  color: theme.palette.info.dark,
  fontWeight: 'bold',
}));

const PmdaLink = styled('a')(({theme}) => ({
  color: theme.palette.info.dark,
  fontWeight: 700,
  textDecoration: 'none',
  marginLeft: 8,
}));
