import {ProductStatus} from '@Apps/BaseSharedMenu/RentDevices/types';
import {UnacceptedStatus} from '@Apps/BaseSharedMenu/types';

export const assertProductStatusToUnacceptedStatus = (status: ProductStatus): UnacceptedStatus => {
  if (status === 'ready') {
    throw new Error('貸出できない機器にて貸出可能の稼働状況が検出されました。'); // TODO FIX ME: 共通の abstract ErrorType を Extend して作るべき場面
  } else {
    return status as UnacceptedStatus;
  }
};
