import React from 'react';
import {Tooltip, Typography} from '@mui/material';
import {StrUtil} from '@front-libs/helpers';

type Props = {
  message: string;
  maxLength?: number;
};

const MessageWrap = ({message}: {message: string}) => {
  return (
    <Typography component="span" sx={{fontSize: '16px'}}>
      {message}
    </Typography>
  );
};

/**
 * 指定された文字列が最大文字数を超える場合、切り詰めて三点リーダーを表示し、
 * ツールチップで全文を表示するコンポーネント
 *
 * @param {string} message - 表示するメッセージ。
 * @param {number} [maxLength=20] - メッセージの最大表示文字数。デフォルトは20。
 */
export const TooltipMessage = ({message, maxLength = 20}: Props) => {
  if (!message) return null;
  const isLongMessage = message.length > maxLength;
  const displayedMessage = isLongMessage ? `${message.slice(0, maxLength)}...` : message;

  if (!isLongMessage) {
    return <>{displayedMessage}</>;
  }

  return (
    <Tooltip title={<MessageWrap message={message} />} placement="top" disableInteractive>
      <Typography variant="inherit"> {StrUtil.nl2br(displayedMessage)}</Typography>
    </Tooltip>
  );
};
