import {RentButton} from '@Apps/BaseSharedMenu/RentDevices/RentButton';
import {VerticalPairTable} from '@Apps/BaseSharedMenu/VerticalPairTable';
import {useAsyncEffect} from '@front-libs/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {bulkUpdateRentals, DeviceLender} from '@modules/rentals/api';
import {Box, SxProps, Theme} from '@mui/material';
import {contentFixedStyle} from '@templates/RentalTemplate';
import {useAtom, useAtomValue, useSetAtom} from 'jotai';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Footer} from '../../../Footer';
import {Header} from '../../../Header';
import {rentalSettingAtom, rentStepperAtom, returnLenderNameAtom} from '../../../states';
import {RentalTable} from '../../RentalTable';
import {scannedReturnTargetHospitalProductsAtom} from '../states';

/**
 * カメラ読み取り後の返却画面
 * 返却する医療機器リストと返却ボタンのあるページ
 */
export const ConfirmReturn = () => {
  const {myInfo} = useMyInfo();
  const [returnTargetHospitalProducts, setReturnTargetHospitalProducts] = useAtom(
    scannedReturnTargetHospitalProductsAtom
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const returnLenderName = useAtomValue(returnLenderNameAtom);
  const setRentalStepper = useSetAtom(rentStepperAtom);
  const hospitalRentalSetting = useAtomValue(rentalSettingAtom);

  const deviceLender: DeviceLender | undefined = useMemo(() => {
    if (hospitalRentalSetting === 'not_need') return undefined;

    return {
      userId: hospitalRentalSetting === 'by_barcode' ? returnLenderName ?? undefined : undefined,
      userName: hospitalRentalSetting === 'by_direct_input' ? returnLenderName ?? undefined : undefined,
    };
  }, [hospitalRentalSetting, returnLenderName]);

  const handleClickCancel = useCallback(
    (rowIndex: number) => {
      if (isSubmitting) {
        return;
      }

      setReturnTargetHospitalProducts(returnTargetHospitalProducts.filter((_item, idx) => idx !== rowIndex));
    },
    [returnTargetHospitalProducts, setReturnTargetHospitalProducts]
  );

  const handleSubmit = async () => {
    if (!isSubmitting) setIsSubmitting(true);
  };
  // 返却登録処理の開始
  useAsyncEffect(async () => {
    if (!isSubmitting) return;

    await bulkUpdateRentals(
      myInfo.hospitalHashId,
      returnTargetHospitalProducts.map((i) => ({
        rentalHashId: i.rentalHashId,
        returnUserHashId: myInfo.hashId,
        returnDeviceLender: deviceLender,
      }))
    );

    navigate('/shared/complete');
    // 状態変数の初期化
    setRentalStepper(2);
  }, [isSubmitting]);

  useEffect(() => {
    setRentalStepper(1);
  }, [setRentalStepper]);

  return (
    <>
      <Header title={'返却'} />
      <Box sx={contentFixedStyle}>
        <Box sx={boxContainerStyle}>
          <Box sx={rentalTableContainerStyle}>
            <RentalTable
              returnTargetHospitalProducts={returnTargetHospitalProducts}
              onClickCancel={handleClickCancel}
            />
          </Box>
          <Box sx={operationContainerStyle}>
            {returnLenderName && (
              <Box alignItems="flex-end" sx={operatorTableGridItemStyle}>
                <VerticalPairTable label="返却者" name={returnLenderName} />
              </Box>
            )}
            <Box sx={{width: '100%'}}>
              <RentButton label={'返却する'} onChange={handleSubmit} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer text={'返却する医療機器の\nバーコードを読み取って下さい'} />
    </>
  );
};

const boxContainerStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
  margin: '0 60px',
  gap: '24px',
};

const rentalTableContainerStyle: SxProps<Theme> = (theme) => ({
  maxHeight: '100%',
  overflow: 'auto',
  height: 'fit-content',
  width: '65%',
  [theme.breakpoints.up('laptop')]: {
    width: '70%',
  },
});

const operationContainerStyle: SxProps<Theme> = {
  width: '25%',
  minHeight: '408px',
  gap: '48px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
};

const operatorTableGridItemStyle: SxProps<Theme> = (theme) => ({
  width: '100%',
  marginTop: '48px',
  [theme.breakpoints.up('laptop')]: {
    marginTop: '28px',
  },
});
