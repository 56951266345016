import {Grid} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import {MakerInspectionResultIndex} from '@modules/maker_inspection_results/types';
import {createMakerInspectionResultFiles, updateMakerInspectionResult} from '@modules/maker_inspection_results/api';
import {FileList} from '@organisms/Files/FileList';
import {useFetchMakerInspectionResultFilesQuery} from '@modules/maker_inspection_results/hooks';
import {FileIndex} from '@modules/files/types';
import {SideNav} from './SideNav';
import {useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
import {openSnackBar} from '@molecules/SnackBar';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';

type Props = {
  hospitalHashId: string;
  makerInspectionResult: MakerInspectionResultIndex;
  refetch: () => void;
};

export const MakerInspectionResultContent: React.FC<Props> = ({
  hospitalHashId,
  makerInspectionResult,
  refetch: mrRefetch,
}) => {
  const [inspectionFee, setInspectionFee] = useState<number | null>(makerInspectionResult.inspectionFee ?? null);
  const [comment, setComment] = useState<string>(makerInspectionResult.comment ?? '');
  const [tableLayout] = useTableLayout('fileList');
  const [order, setOrder] = useState<string>();
  const {isReadOnly} = useMyRole();
  const {canEdit: canEditInspection} = useUserResourcesPermissions('INSPECTION');

  const {
    data: files,
    isLoading,
    refetch,
  } = useFetchMakerInspectionResultFilesQuery(hospitalHashId, makerInspectionResult.hashId, order);

  const handleRefetch = () => refetch();

  const handleUploadFile = useCallback(
    async (file: FileIndex) => {
      try {
        await createMakerInspectionResultFiles(hospitalHashId, makerInspectionResult.hashId, {
          fileHashIds: [file.hashId],
        });
        refetch();
      } catch (_err) {
        // empty function
      }
    },
    [hospitalHashId, makerInspectionResult.hashId, refetch]
  );

  const handleEdit = useCallback(async () => {
    await updateMakerInspectionResult(hospitalHashId, {
      makerInspectionResultHashId: makerInspectionResult.hashId,
      status: 'completed',
      inspectionFee: inspectionFee !== 0 ? inspectionFee ?? undefined : undefined,
      comment: comment,
    });
    mrRefetch();
    openSnackBar('メーカー点検情報を更新しました。', 'left', 'bottom', 'success');
  }, [comment, hospitalHashId, inspectionFee, makerInspectionResult.hashId]);

  const handleOrderChange = useCallback(
    (columnIndex: number, orderDirection: 'asc' | 'desc') => {
      setOrder(`${orderDirection === 'desc' ? '-' : ''}${String(tableLayout.currentLayout[columnIndex].field)}`);
    },
    [tableLayout.currentLayout]
  );

  return (
    <Grid container>
      <Grid item style={{width: 352, height: 'calc(100vh - 56px)'}}>
        <SideNav
          makerInspectionResult={makerInspectionResult}
          onChangeInspectionFee={setInspectionFee}
          onChangeComment={setComment}
          onSubmit={handleEdit}
          inspectionFee={inspectionFee}
          comment={comment}
          isReadOnly={
            (FEATURE_CUSTOM_ASSET_ROLE_FLAG && !canEditInspection) || (!FEATURE_CUSTOM_ASSET_ROLE_FLAG && isReadOnly)
          }
        />
      </Grid>
      <Grid item style={{height: '100%', width: 'calc(100vw - 352px)', background: '#f0f2f5'}}>
        <div style={{padding: 24}}>
          <FileList
            files={files}
            fileCategory={'maker_inspection_result'}
            isLoading={isLoading}
            disableEdit={
              (FEATURE_CUSTOM_ASSET_ROLE_FLAG && !canEditInspection) || (!FEATURE_CUSTOM_ASSET_ROLE_FLAG && isReadOnly)
            }
            disableUpload={
              (FEATURE_CUSTOM_ASSET_ROLE_FLAG && !canEditInspection) || (!FEATURE_CUSTOM_ASSET_ROLE_FLAG && isReadOnly)
            }
            onDeleteFile={handleRefetch}
            onEditFileName={handleRefetch}
            onUploadFile={handleUploadFile}
            onOrderChange={handleOrderChange}
          />
        </div>
      </Grid>
    </Grid>
  );
};
