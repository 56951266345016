import {axios, BaseListResult} from '@front-libs/core';
import {ReturnsReservationRequest, returnsReservationRequestParam, ReturnWaitingReceptionsResponse} from './type';

/**
 * 返却待ち受付保存API
 * @param hospitalHashId
 * @param params returnsReservationRequest
 */
export const postReturnsReservation = async (hospitalHashId: string, params: ReturnsReservationRequest) => {
  params;
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/return_waiting_receptions`;
  await axios.post(baseUrl, params);
};

/**
 * 返却待ち受付 取得API
 * @param hospitalHashId
 */
export const getReturnWaitingReceptions = async (hospitalHashId: string, params: returnsReservationRequestParam) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/return_waiting_receptions`;
  const {data} = await axios.get<BaseListResult<ReturnWaitingReceptionsResponse>>(baseUrl, {params});
  return data;
};

export type ReturnWaitingReceptionsUpdateRequestParams = {
  // 更新するカラム名のリスト
  updateColumns: string[];
  // ステータス
  status: string;
  // コメント
  comment?: string;
  // 担当者のハッシュID
  contactPersonHashId?: string;
};

export const patchReturnWaitingReceptions = async (
  hospitalHashId: string,
  returnWaitingReceptionsHashId: string,
  params: ReturnWaitingReceptionsUpdateRequestParams
) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/return_waiting_receptions/${returnWaitingReceptionsHashId}`;

  return await axios.patch<unknown>(baseUrl, params);
};
