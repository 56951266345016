import {SxProps, Typography} from '@mui/material';
import React from 'react';

const countStyle: SxProps = {
  backgroundColor: '#0052CC',
  borderRadius: '14px',
  fontSize: '14px',
  color: 'white',
  padding: '0px 8px',
  marginLeft: '8px',
  minWidth: '14px',
  fontWeight: 600,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

type BlueBackgroundCounterProps = {
  count?: number;
};

/**
 * 青背景に白地でカウントを表示するコンポーネント名
 * @param props.count
 * @returns
 */
export const BlueBackgroundCounter = ({count}: BlueBackgroundCounterProps) => {
  return (
    <Typography component="span" sx={countStyle}>
      {count}
    </Typography>
  );
};
