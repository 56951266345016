import {Option} from '@components/molecules/Selector';
import {WaitingReceptionsStatusType} from '@modules/returns_reservation/type';

export const statusOptions: readonly Option<WaitingReceptionsStatusType>[] = [
  {label: '確認前', value: 'before_confirmation'},
  {label: '準備中', value: 'preparation'},
  {label: '連絡済み', value: 'contacted'},
  {label: '完了', value: 'completed'},
  {label: 'キャンセル', value: 'canceled'},
] as const;

// TODO:Statusesをなしで取得できるAPIが実装されたら、以下の配列を削除する
export const WaitingReceptionsStatusArray: WaitingReceptionsStatusType[] = [
  'completed',
  'contacted',
  'preparation',
  'before_confirmation',
  'canceled',
];
