import React, {useEffect, useMemo} from 'react';
import {ListPageLayout} from '@components/layouts/ListPageLayout';
import {WholeProductsListHeader} from './Header';
import {WholeProductsListContent} from './Content';
import {WholeProductFilterDrawer} from './FilterDrawer';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {useNavigate} from 'react-router-dom';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';

/**
 * 院内機種一覧ページ
 */
export const WholeProductsList = () => {
  const navigate = useNavigate();
  const {canAccess: canAccessDeviceList} = useUserResourcesPermissions('DEVICE_LIST');
  if (FEATURE_CUSTOM_ASSET_ROLE_FLAG) {
    useEffect(() => {
      if (!canAccessDeviceList) {
        navigate('/dashboard');
      }
    }, [canAccessDeviceList, navigate]);
    if (!canAccessDeviceList) {
      return null;
    }
  }
  return (
    <ListPageLayout page="wholeProducts">
      <WholeProductsListHeader />
      <WholeProductsListContent />
      <WholeProductFilterDrawer />
    </ListPageLayout>
  );
};
