import {axios} from '@front-libs/core';
import {SelfUnreadChatMessageCounts} from './types';
import {useQuery} from 'react-query';
import {getLinkUnreadChatMessageCountsKey} from '@constants/api';

const getLinkUnreadChatMessageCountsQuery = `
  query GetLinkUnreadChatMessageCounts {
      self {
        unreadChatMessageCounts {
          newJoinChatRoomIds
          unreadMentionedMessageCounts {
            chatRoomId
            count
          }
          unreadMessageCounts {
            chatRoomId
            count
          }
        }
      }
  }
`;

export const getLinkUnreadChatMessageCounts = async () => {
  try {
    const baseUrl = `${import.meta.env.VITE_SERVER_URL}/graphql`;

    const response = await axios.post(
      baseUrl,
      {query: getLinkUnreadChatMessageCountsQuery},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.data.errors) {
      throw new Error(response.data.errors[0].message);
    }

    return response.data.data as SelfUnreadChatMessageCounts;
  } catch (error) {
    console.error('Error fetching unread chat message counts:', error);
    throw error;
  }
};

export const useFetchLinkUnreadChatMessageCountsQuery = () => {
  return useQuery(getLinkUnreadChatMessageCountsKey, () => getLinkUnreadChatMessageCounts(), {
    refetchInterval: 1000 * 60, // 1分
  });
};
