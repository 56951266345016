import React, {useCallback, useEffect} from 'react';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {PageHeader} from './PageHeader';
import {PlanList} from './PlanList';
import {TrainingPlanDialog, TrainingPlanDialogProps, TrainingPlanDialogResult} from './dialogs/TrainingPlanDialog';
import {useCreateTrainingPlanMutation} from './hooks';
import {openSnackBar} from '@molecules/SnackBar';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {requestExportTrainingPlan} from '@modules/training_plans/api';
import {ListPageLayout} from '@components/layouts/ListPageLayout';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';
import {useNavigate} from 'react-router-dom';

export const TrainingManagementPlan = () => {
  const navigate = useNavigate();
  const {canAccess: canAccessTraining, canEdit: canEditTraining} = useUserResourcesPermissions('TRAINING');
  if (FEATURE_CUSTOM_ASSET_ROLE_FLAG) {
    useEffect(() => {
      if (!canAccessTraining) {
        navigate('/dashboard');
      }
    }, [canAccessTraining, navigate]);
    if (!canAccessTraining) return null;
  }

  const {myInfo} = useMyInfo();
  const {mutate} = useCreateTrainingPlanMutation();
  const {isReadOnly} = useMyRole();

  const handleRegisterPlan = useCallback(async () => {
    const newTrainingPlan = await dialogHandler.open<TrainingPlanDialogProps, TrainingPlanDialogResult>(
      TrainingPlanDialog,
      {
        isEdit: false,
      }
    );
    mutate({...newTrainingPlan});
    openSnackBar('研修計画マスタを登録しました');
  }, [mutate]);

  const handleExportPlan = useCallback(async () => {
    await requestExportTrainingPlan(myInfo.hospitalHashId);
    openSnackBar(
      '研修計画マスタの出力を受け付けました。\n処理完了後、通知をご確認ください。',
      'center',
      'top',
      'success',
      {
        autoHideDuration: 5000,
      }
    );
  }, [myInfo.hospitalHashId]);

  return (
    <ListPageLayout page="training_managements_plans">
      <ListPageLayout.Header>
        <PageHeader
          isReadOnly={
            (FEATURE_CUSTOM_ASSET_ROLE_FLAG && !canEditTraining) || (!FEATURE_CUSTOM_ASSET_ROLE_FLAG && isReadOnly)
          }
          onRegisterPlan={handleRegisterPlan}
          onExportPlan={handleExportPlan}
        />
      </ListPageLayout.Header>
      <ListPageLayout.Content>
        <PlanList />
      </ListPageLayout.Content>
    </ListPageLayout>
  );
};
