import React, {useEffect} from 'react';
import {withSuspense} from '@front-libs/core';
import {Header} from './Header';
import {ListPageLayout} from '@components/layouts/ListPageLayout';
import {FaultReceptionList} from './FaultReceptionList';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';
import {useNavigate} from 'react-router-dom';
// 現状だとTableLayoutのキーを後から変更できないので、キーが変わったら再描画する形式にしています
const _FaultReceptions = () => {
  const navigate = useNavigate();
  const {canAccess: canAccessRepair} = useUserResourcesPermissions('REPAIR');
  if (FEATURE_CUSTOM_ASSET_ROLE_FLAG) {
    useEffect(() => {
      if (!canAccessRepair) {
        navigate('/dashboard');
      }
    }, [canAccessRepair, navigate]);
    if (!canAccessRepair) return null;
  }

  return (
    <ListPageLayout page="fault_reception">
      <ListPageLayout.Header>
        <Header />
      </ListPageLayout.Header>
      <ListPageLayout.Content>
        <FaultReceptionList />
      </ListPageLayout.Content>
    </ListPageLayout>
  );
};

export const FaultReceptions = withSuspense(_FaultReceptions, null);
