import React, {HTMLAttributes, useContext} from 'react';
import {CollapseHeader} from '@components/organisms/CollapseHeader/CollapseHeaderV5';
import {Box, styled} from '@mui/material';

type Props = HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  page: string;
  defaultOpen?: boolean;
};

type SubProps = HTMLAttributes<HTMLDivElement> & {children: React.ReactNode; defaultOpen?: boolean};

/**
 * 一覧ページで使うレイアウトMuiV5板
 *
 * @example
 * ```tsx
 * const Page = () => {
 *   return (
 *     // pageにアコーディオン開閉状態を永続化するためのユニークキーを入れる
 *     <ListPageLayout page="debug">
 *        一番上のアコーディオンで閉じられるヘッダ部分
 *       <ListPageLayout.Header>
 *         <HeaderToolBar />
 *       </ListPageLayout.Header>
 *
 *        メインの表示UI
 *       <ListPageLayout.Content>
 *         <ToolBar />
 *         <TableData />
 *       </ListPageLayout.Content>
 *
 *        レイアウトに影響しないダイアログなど
 *       <FilterDrawer />
 *     </ListPageLayout>
 *   );
 * };
 * ```
 **/
export const ListPageLayout: React.FC<Props> & {
  Header: React.FC<SubProps>;
  Content: React.FC<SubProps>;
} = (props) => {
  const {page, children, ...rest} = props;

  return (
    <Page {...rest}>
      <pageNameContext.Provider value={page}>{children}</pageNameContext.Provider>
    </Page>
  );
};

const pageNameContext = React.createContext('');

ListPageLayout.Header = ({children, defaultOpen, ...props}) => {
  const pageName = useContext(pageNameContext);

  return (
    <CollapseHeader settingKey={`${pageName}_header`} defaultOpen={defaultOpen}>
      <InnerHeaderContainer {...props}>{children}</InnerHeaderContainer>
    </CollapseHeader>
  );
};

ListPageLayout.Content = ({children, ...props}) => <Content {...props}>{children}</Content>;

const Page = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  padding: '4px 0px 0px',
  overflow: 'hidden',
});

const InnerHeaderContainer = styled(Box)({
  width: '100%',
  boxSizing: 'border-box',
});

const Content = styled(Box)({
  width: '100%',
  minHeight: 0,
  flex: 1,
  overflow: 'hidden',
  marginTop: '4px',
  // FIXME: bottomはChatbotのアイコンサイズに合わせる
  padding: '0px 24px 16px',
  boxSizing: 'border-box',
});
