import {z} from 'zod';

/**
 * 返却待ち受付APIのリクエストのバリデーションスキーマ
 */
export type returnsReservationRequest = {
  narrowCategoryHashId: string;
  roomHashId: string;
  reservationCount: number;
  contactInformation: string;
  transitionMessage: string;
};

export type returnsReservationRequestParam = {
  /** 1ページあたりのアイテム数 */
  perPage: number;
  /** ページ番号 */
  page: number;
  /** 開始日時 (ISO8601形式) */
  receptionAtFrom?: string;
  /** 終了日時 (ISO8601形式) */
  receptionAtTo?: string;
  /** ステータス */
  statuses?: string;
  /** 絞り込み用カテゴリIDリスト */
  narrowCategoryHashIds?: string[];
  /** 絞り込み用部屋IDリスト */
  roomHashIds?: string[];
  /** ソート順 */
  order?: string;
};

/** 完了 連絡済み 準備中 確認前 キャンセル*/
export type WaitingReceptionsStatusType =
  | 'completed'
  | 'contacted'
  | 'preparation'
  | 'before_confirmation'
  | 'canceled';

type UseRoom = {
  hashId: string;
  hospitalHashId: string;
  createdAt: string | null;
  updatedAt: string | null;
  createdBy: string | null;
  updatedBy: string | null;
  hospitalWard: {
    hashId: string;
    hospitalHashId: string;
    createdAt: string | null;
    updatedAt: string | null;
    createdBy: string | null;
    updatedBy: string | null;
    name: string;
  };
  name: string;
  isGroundFloor: boolean;
  floorNumber: number;
  showRentalPlace: boolean;
};

type ContactPerson = {
  hashId: string;
  username: string;
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  lastSignInAt: string | null;
  email: string;
  emailVerified: boolean;
  permissions: string[];
  baseOrganizationType: string;
  baseOrganizationHashId: string;
  hospitalHashId: string;
  createdAt: string | null;
  updatedAt: string | null;
  createdBy: string | null;
  updatedBy: string | null;
  deletedBy: string | null;
  occupations: string | null;
  isSafetyControlManager: boolean;
  isShared: boolean;
  thumbnailFile: string | null;
  thumbnailFileHashId: string | null;
  hospitalDepartment: string | null;
  hospitalDepartmentHashId: string | null;
  nextInitializeStep: number;
  initialized: boolean;
  sessionExpirationTimeSec: number | null;
  canAccessMgmtDashboard: boolean;
  zendeskUserId: string | null;
};

export type ReturnWaitingReceptionsResponse = {
  hashId: string;
  hospitalHashId: string;
  status: WaitingReceptionsStatusType;
  receptionAt: string;
  narrowCategoryHashId: string;
  narrowCategoryName: string;
  quantity: number;
  contactInformation: string;
  transitionMessage: string;
  contactPersonHashId: string | null;
  comment: string;
  useRoom: UseRoom;
  contactPerson: ContactPerson;
};

export const returnWaitingReceptionsSchema = z.object({
  narrowCategoryHashId: z.string().min(1),
  roomHashId: z.string().min(1),
  reservationCount: z
    .number()
    .min(1) // 1以上の数値
    .max(20),
  contactInformation: z.string().optional(),
  transitionMessage: z.string().optional(),
});

export type ReturnsReservationRequest = z.infer<typeof returnWaitingReceptionsSchema>;

type OrderKeys =
  | 'receptionAt'
  | 'status'
  | 'useRoom'
  | 'narrowCategory'
  | 'quantity'
  | 'contactInformation'
  | 'transitionMessage'
  | 'contactPerson'
  | 'comment';

/** ソート用の型 接頭詞にマイナス(‐)をつけたものも含まれる  */
export type ReturnWaitingReceptionsOrderType = OrderKeys | `-${OrderKeys}`;
