import {Box, SxProps, Typography} from '@mui/material';
import React from 'react';
import {WaitingReceptionsStatusType} from '@modules/returns_reservation/type';

type StatusInfo = {
  name: string;
  backgroundColor: string;
  color: string;
};

const statusMap: Record<WaitingReceptionsStatusType, StatusInfo> = {
  completed: {name: '完了', backgroundColor: 'rgb(237,247,237)', color: '#3B873E'},
  contacted: {name: '連絡済み', backgroundColor: 'rgb(233,244,254)', color: '#0B79D0'},
  preparation: {name: '準備中', backgroundColor: 'rgb(255,245,229)', color: '#C77700'},
  before_confirmation: {name: '確認前', backgroundColor: 'rgb(245,245,245)', color: '#616161'},
  canceled: {name: 'キャンセル', backgroundColor: 'rgb(245,245,245)', color: '#616161'},
};

type StatusIconProps = {status: WaitingReceptionsStatusType; sx?: SxProps; nameSx?: SxProps};

/**
 * ステータスに応じて、対応する名前やスタイルを持つアイコンを表示します
 * ステータスごとに背景色、テキスト色、ボーダー色が異なります
 *
 * @param {WaitingReceptionsStatusType} props.status - 表示するステータス。
 * 次のいずれかの値を指定できます:
 * ```
 * - 'completed': 完了
 * - 'contacted': 連絡済み
 * - 'preparation': 準備中
 * - 'before_confirmation': 確認前
 * - 'cancel': キャンセル
 *```
 * @returns {JSX.Element} 指定されたステータスに応じたスタイルと名前を持つボックス。
 *
 * @example
 * ```tsx
 * <StatusIcon status="completed" />
 * ```
 * この例では、"完了" ステータスに対応する緑色のアイコンが表示されます。
 */
export const StatusIcon = ({status, sx, nameSx}: StatusIconProps) => {
  const info = statusMap[status];
  if (!info) return <Box>{status}</Box>;
  const boxStyle: SxProps = {
    borderRadius: '12px',
    color: info.color,
    backgroundColor: info.backgroundColor,
    borderColor: info.color,
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  return (
    <Box
      sx={{
        ...boxStyle,
        ...sx,
      }}>
      <Typography variant="inherit" sx={nameSx}>
        {info.name}
      </Typography>
    </Box>
  );
};
