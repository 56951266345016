import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, createStyles, Grid, makeStyles, TextField, Theme, useMediaQuery} from '@material-ui/core';
import {Search, Tune} from '@material-ui/icons';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {PopperSelectBoxButton, SelectOptionProps} from '@molecules/Buttons/PopperSelectBoxButton';
import {useAtom, useAtomValue} from 'jotai';
import {
  searchNameAtom,
  searchReq4RepairAtFromAtom,
  searchReq4RepairAtToAtom,
  searchPersonInChargeHashIDsAtom,
  repairListPageAtom,
  isActiveDetailFilterAtom,
  searchDetailFiltersAtom,
} from '../jotai';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {openSnackBar} from '@molecules/SnackBar';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {TableLayoutDialog, TableLayoutDialogProps} from '@organisms/Table/TableLayoutDialog';
import {TableLayoutResult, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {DatePicker} from '@molecules/DatePicker';
import {RequestExportRepairs} from '@modules/repairs/types';
import {useDebounceCallback} from '@front-libs/core';
import {useResetAtom} from 'jotai/utils';
import dayjs from 'dayjs';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {CreateDatePickerDialog} from '@components/molecules/Dialogs/DatePickerDialog';
import {requestExportRepairs} from '@modules/repairs/api/exportApi';
import {FilterDrawer} from '@molecules/Drawers/FilterDrawer';
import {detailFilterDrawerOptions} from '../constants';

const actionMenuItems = [
  {
    label: 'エクスポート',
    value: 'export',
  },
  {
    label: '表示項目を変更',
    value: 'changeLayout',
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterContainer: {
      marginRight: '32px',
    },
    flex: {
      flexGrow: 1,
    },
    actionMenu: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
      marginLeft: '8px',
    },
    searchText: {
      backgroundColor: theme.palette.common.white,
      fontSize: '14px',
    },
    dateSelector: {
      marginTop: '-4px',
      display: 'flex',
      alignItems: 'center',
    },
    date: {
      marginLeft: '16px',
      fontSize: 14,
    },
    actionBtnContainer: {
      minWidth: '150px',
    },
    actionBtn: {
      width: '100%',
      background: 'rgba(9, 30, 66, 0.04)',
      border: '1px solid #C6CBD4',
    },
    filterBtnActive: {
      backgroundColor: theme.palette.primary.light,
      '&&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  })
);

type ListToolbarProp = {
  onChangeTableLayout: (layout: TableLayoutResult) => void;
  hospitalDepartmentOptions: {
    label: string;
    value: string;
  }[];
};

export const ListToolbar: React.FC<ListToolbarProp> = (props) => {
  const {onChangeTableLayout, hospitalDepartmentOptions} = props;
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:1024px)');
  const {myInfo} = useMyInfo();
  const {hospitalUsers} = useHospitalUsers();
  const [tableLayout] = useTableLayout(
    'repairList',
    useMemo(
      () => ({
        managementId: true,
        symptomCategory: true,
      }),
      []
    )
  );

  const [searchName, setSearchName] = useAtom(searchNameAtom);
  const resetRepairListPage = useResetAtom(repairListPageAtom);
  const [personInChargeHashIDs, setPersonInChargeHashIDs] = useAtom(searchPersonInChargeHashIDsAtom);
  const [initialPersonInChargeHashIDs, setInitialPersonInChargeHashIDs] = useState<SelectOptionProps[] | undefined>(
    undefined
  );
  const [req4RepairAtFrom, setReq4RepairAtFrom] = useAtom(searchReq4RepairAtFromAtom);
  const [req4RepairAtTo, setReq4RepairAtTo] = useAtom(searchReq4RepairAtToAtom);
  const [searchDetailFilters, setSearchDetailFilters] = useAtom(searchDetailFiltersAtom);
  const isActiveDetailFilter = useAtomValue(isActiveDetailFilterAtom);

  const [openDetailFilterDrawer, setOpenDetailFilterDrawer] = useState(false);

  const detailFilterOptions = useMemo(
    () => detailFilterDrawerOptions(hospitalDepartmentOptions),
    [hospitalDepartmentOptions]
  );

  const handleChangeName = useDebounceCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value) setSearchName(e.target.value);
      else setSearchName(undefined);
      resetRepairListPage();
    },
    300,
    [resetRepairListPage, setSearchName]
  );

  const handleChangePic = useCallback(
    (selectedValues?: SelectOptionProps[]) => {
      setPersonInChargeHashIDs(selectedValues?.map((item) => item.value) || []);
      resetRepairListPage();
    },
    [setPersonInChargeHashIDs, resetRepairListPage]
  );

  const handleChangeReq4RepairAtFrom = useCallback(
    (date: Date | null) => {
      setReq4RepairAtFrom(date ? dayjs(date).toDate() : date);
      resetRepairListPage();
    },
    [resetRepairListPage, setReq4RepairAtFrom]
  );

  const handleChangeReq4RepairAtTo = useCallback(
    (date: Date | null) => {
      setReq4RepairAtTo(date);
      resetRepairListPage();
    },
    [resetRepairListPage, setReq4RepairAtTo]
  );

  const handleExport = useCallback(async () => {
    try {
      const res = await dialogHandler.open(CreateDatePickerDialog, {
        title: '修理履歴のエクスポート範囲を指定',
        description: '修理受付日の範囲を設定してください',
        initialStartDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
        initialEndDate: dayjs().format('YYYY-MM-DD'),
        startDateLabel: '開始日',
        endDateLabel: '終了日',
      });

      if (!res) {
        return;
      }

      const data: RequestExportRepairs = {
        RequestForRepairAtFrom: res.startDate,
        RequestForRepairAtTo: res.endDate,
      };

      const result = await requestExportRepairs(myInfo.hospitalHashId, data);
      if (result.status === 200) {
        openSnackBar('ファイルのエクスポートを受け付けました。処理完了後、通知をご確認ください。', 'center', 'top');
      }
    } catch (_e) {
      openSnackBar('ファイルのエクスポートに失敗しました。', 'center', 'top', 'error');
    }
  }, [myInfo.hospitalHashId]);

  const handleChangeLayout = useCallback(async () => {
    try {
      const currentLayout = await dialogHandler.open<TableLayoutDialogProps>(TableLayoutDialog, {
        tableColumns: tableLayout?.tableLayout ?? [],
        defaultOptions: tableLayout?.currentLayout ?? [],
        forceValue: tableLayout?.forceValue ?? {},
      });
      const newTableLayout = {
        tableLayout: tableLayout?.tableLayout ?? [],
        currentLayout: currentLayout,
      };
      onChangeTableLayout(newTableLayout);
    } catch (_e) {
      console.error(_e);
    }
  }, [onChangeTableLayout, tableLayout]);

  const handleActionMenuClick = useCallback(
    (item: MenuItemType) => {
      switch (item.value) {
        case 'export':
          handleExport();
          break;

        case 'changeLayout':
          handleChangeLayout();
          break;
      }
    },
    [handleChangeLayout, handleExport]
  );

  useEffect(() => {
    if (personInChargeHashIDs.length > 0) {
      const initOptions = UserFormatter.getOptions(hospitalUsers, {withAlias: true, withSubLabel: true}).filter((i) =>
        personInChargeHashIDs.includes(i.value)
      );
      setInitialPersonInChargeHashIDs(initOptions);
    }
  }, [hospitalUsers, personInChargeHashIDs]);

  return (
    <>
      <Grid container style={{marginTop: '8px'}}>
        <Grid item sm={4} md={3} className={classes.filterContainer}>
          <TextField
            label={'機種名・型式・管理番号・修理番号で検索'}
            variant={'outlined'}
            fullWidth
            size={'small'}
            InputProps={{
              endAdornment: <Search />,
            }}
            InputLabelProps={{
              style: {
                fontSize: 14,
              },
            }}
            defaultValue={searchName}
            className={classes.searchText}
            onChange={handleChangeName}
          />
        </Grid>
        <Grid item>
          <PopperSelectBoxButton
            buttonLabel={'担当者'}
            options={UserFormatter.getOptions(hospitalUsers, {withAlias: true, withSubLabel: true})}
            initialOption={initialPersonInChargeHashIDs}
            isMulti={true}
            onChange={handleChangePic}
            searchable={true}
          />
        </Grid>
        {!matches && (
          <Grid item className={classes.dateSelector}>
            <span className={classes.date}>修理受付日時: </span>
            <DatePicker
              value={req4RepairAtFrom}
              placeholder="日付を選択"
              disableFuture={true}
              maxDate={req4RepairAtTo ?? undefined}
              onChange={handleChangeReq4RepairAtFrom}
            />
            <span>から</span>
            <DatePicker
              value={req4RepairAtTo}
              placeholder="日付を選択"
              disableFuture={true}
              minDate={req4RepairAtFrom ?? undefined}
              onChange={handleChangeReq4RepairAtTo}
            />
          </Grid>
        )}
        <Grid item>
          <Button
            color="inherit"
            onClick={() => setOpenDetailFilterDrawer(true)}
            style={{marginLeft: 8}}
            className={isActiveDetailFilter || openDetailFilterDrawer ? classes.filterBtnActive : undefined}>
            <Tune />
            <span className={classes.actionMenu}>詳細で絞り込む</span>
          </Button>
        </Grid>
        <div className={classes.flex} />
        <Grid item className={classes.actionBtnContainer}>
          <PopperMenuButton
            buttonProps={{variant: 'contained', disableElevation: true, className: classes.actionBtn}}
            menuItemList={actionMenuItems}
            onMenuClick={handleActionMenuClick}>
            アクション
          </PopperMenuButton>
        </Grid>
      </Grid>
      <FilterDrawer
        open={openDetailFilterDrawer}
        onDrawerClose={() => setOpenDetailFilterDrawer(false)}
        currentCount={0}
        filterOptions={detailFilterOptions}
        onFilterChange={setSearchDetailFilters}
        defaultFilterResults={searchDetailFilters}
      />
    </>
  );
};
