import React, {useEffect} from 'react';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {PageHeader} from './PageHeader';
import {ScheduleList} from './ScheduleList';
import {
  TrainingScheduleDialog,
  TrainingScheduleDialogProps,
  TrainingScheduleDialogResult,
} from '@organisms/TrainingManagements/TrainingScheduleDialog';
import {useCreateTrainingScheduleMutation, useSearchVariables} from './hooks';
import {useLocation, useNavigate} from 'react-router-dom';
import {useEffectOnce} from 'react-use';
import {isNullish} from '@front-libs/helpers';
import {openSnackBar} from '@molecules/SnackBar';
import {ListPageLayout} from '@components/layouts/ListPageLayout';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';

type ScheduleRouteState = {
  trainingPlanHashId?: string;
};

export const TrainingManagementSchedules: React.FC = () => {
  const navigate = useNavigate();
  const {canAccess: canAccessTraining, canEdit: canEditTraining} = useUserResourcesPermissions('TRAINING');
  if (FEATURE_CUSTOM_ASSET_ROLE_FLAG) {
    useEffect(() => {
      if (!canAccessTraining) {
        navigate('/dashboard');
      }
    }, [canAccessTraining, navigate]);
    if (!canAccessTraining) return null;
  }
  const {mutate} = useCreateTrainingScheduleMutation();
  const {setTrainingPlanHashIds} = useSearchVariables();
  const {state} = useLocation();
  const {isReadOnly} = useMyRole();

  useEffectOnce(() => {
    const trainingPlanHashId = (state as ScheduleRouteState)?.trainingPlanHashId;
    if (isNullish(trainingPlanHashId)) return;
    setTrainingPlanHashIds([trainingPlanHashId]);
  });

  const handleRegisterSchedule = async () => {
    const result = await dialogHandler.open<TrainingScheduleDialogProps, TrainingScheduleDialogResult>(
      TrainingScheduleDialog,
      {
        isEdit: false,
      }
    );
    try {
      mutate({
        ...result,
        trainingPlanTrainingTypes: result.trainingTypes ?? [],
        wholeProductHashIds: result.wholeProductHashIds ?? [],
      });
      openSnackBar('点検予定を登録しました');
    } catch (e) {
      openSnackBar(`点検予定の登録に失敗しました。詳細:${e}`, 'left', 'bottom', 'error');
    }
  };

  return (
    <ListPageLayout page="training_managements_schedules">
      <ListPageLayout.Header>
        <PageHeader
          isReadOnly={
            (FEATURE_CUSTOM_ASSET_ROLE_FLAG && !canEditTraining) || (!FEATURE_CUSTOM_ASSET_ROLE_FLAG && isReadOnly)
          }
          onRegisterSchedule={handleRegisterSchedule}
        />
      </ListPageLayout.Header>
      <ListPageLayout.Content>
        <ScheduleList />
      </ListPageLayout.Content>
    </ListPageLayout>
  );
};
