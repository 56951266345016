import {Footer} from '@Apps/BaseSharedMenu/Footer';
import {Header} from '@Apps/BaseSharedMenu/Header';
import {ErrorAdditionalInfo, RentalErrorDialog} from '@Apps/BaseSharedMenu/RentalErrorDialog';
import {InnerLoading} from '@components/molecules/Loading';
import {contentFixedStyle} from '@components/templates/RentalTemplate';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {ListScanBarcodeReturnProductsParams, listScanBarcodeReturnProducts} from '@modules/rentals/api';
import {Box, SxProps, Theme, styled} from '@mui/material';
import {useAtom} from 'jotai';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Scanner} from '../../../Scanner';
import {scannedReturnTargetHospitalProductsAtom} from '../states';
import {ScannedProductTable} from './ScannedProductTable';
import {TargetStatusForUnitRelation, UnacceptedStatus} from '@Apps/BaseSharedMenu/types';
import {validateReturnTarget} from '../../supportsForUnitRelation/validateReturnTarget';
import {getAxiosErrorData} from '@front-libs/core';
import {openSnackBar} from '@front-libs/ui';
import {dialogHandler} from '@molecules/Dialogs/DialogHandlerV5';
import {ParentChildDeviceDialog, ParentChildDeviceDialogProps} from '@Apps/BaseSharedMenu/ParentChildDeviceDialog';

/**
 * 【返却】機器のバーコードをカメラでスキャンする画面
 * @todo 貸出にほぼ同じUIのページがあるので共通化したい
 */
export const ScanReturnDeviceBarcode = () => {
  const scannerRef = useRef(null);
  const {myInfo} = useMyInfo();
  const navigate = useNavigate();
  const [scanning, setScanning] = useState(false);
  const [rentalErrorDialogIsOpened, setRentalErrorDialogIsOpened] = useState(false);
  const [returnErrorStatus, setReturnErrorStatus] = useState<UnacceptedStatus>(null);
  const [returnErrorAdditionalInfo, setReturnErrorAdditionalInfo] = useState<ErrorAdditionalInfo>(undefined);
  const [openReturnDevicesAddedDialog, setOpenReturnDevicesAddedDialog] = useState(false);
  const [targetStatusForUnitRelation, setTargetStatusForUnitRelation] = useState<
    TargetStatusForUnitRelation | undefined
  >(undefined);
  const [returnTargetHospitalProducts, setReturnTargetHospitalProducts] = useAtom(
    scannedReturnTargetHospitalProductsAtom
  );
  const [isSearching, setIsSearching] = useState(false);
  // NOTE: isDisabled === trueとなるのは returnTargetHospitalProducts が 要素数0 の場合 → 確認画面に進めない状況にする
  const isDisabled = useMemo(() => !(returnTargetHospitalProducts.length > 0), [returnTargetHospitalProducts.length]);
  const constraints = useMemo(() => ({width: window.innerWidth * 0.6, height: window.innerHeight * 0.5}), []);

  // 読み取ったバーコードより返却対象リストを生成 (親子関係対応版)
  const searchScannedBarcodeAndValidateReturnTargets = useCallback(
    async (scannedCode: string) => {
      // 既に検索動作中の場合(イベントでの多重呼出) は処理しない
      if (isSearching) return;

      /* [動作フロー]
        読取ったバーコード用の検索APIから対象機器リスト取得
        →親子関係を分類
        →追加する機器を抽出
        →1件以上追加 かつ 親子関係がある場合は情報ダイアログ表示
       */

      const listScanBarcodeReturnProductsParams: ListScanBarcodeReturnProductsParams = {
        scanBarcode: scannedCode,
      };

      try {
        // クリティカルセクション
        setIsSearching(true);

        const listScanBarcodeReturnProductsResponse = await listScanBarcodeReturnProducts(
          myInfo.hospitalHashId,
          listScanBarcodeReturnProductsParams
        );

        const validated = validateReturnTarget(listScanBarcodeReturnProductsResponse.data);

        if (validated.validationError) {
          // エラーの場合は エラーダイアログ表示 して処理を完了
          setReturnErrorStatus(validated.validationError);
          setReturnErrorAdditionalInfo({
            managementId: validated.managementIdOfErrorOccurred,
            scannedBarcode: validated.validationError === 'target_not_found' ? scannedCode : undefined,
          });
          setRentalErrorDialogIsOpened(true);
          return;
        }

        // 返却リストに追加する機器を抽出
        const additionalProducts = validated.additionalTargets.filter(
          (product) => !returnTargetHospitalProducts.some((list) => product.hashId === list.hashId)
        );
        // 返却リストにない対象機器が1件以上
        if (additionalProducts.length > 0) {
          // 返却リストに追加
          setReturnTargetHospitalProducts([...returnTargetHospitalProducts, ...additionalProducts]);

          // 追加の親子関係情報があれば 親子関係情報ダイアログ表示
          if (validated.unitRelationAppendStatus) {
            setTargetStatusForUnitRelation(validated.unitRelationAppendStatus);
            setOpenReturnDevicesAddedDialog(true);
          }
        }
      } catch (e: unknown) {
        console.error(e); // output to console

        let errorMessage = `予期しないエラーが発生しました。`;

        if (e instanceof Error) {
          if (e.message) {
            errorMessage += e.message;
          }
        }

        const errorData = getAxiosErrorData(e);
        if (errorData && errorData?.message) {
          errorMessage = `読取バーコードでのデータ取得時にエラーが発生しました: ${errorData?.message}`;
        }
        openSnackBar(errorMessage, 'error', {
          horizontal: 'left',
          vertical: 'bottom',
        });
      } finally {
        // クリティカルセクション解除
        setIsSearching(false);
        // NOTE: まだこのタイミングではダイアログ系の状態変数を初期化できない(関数から抜ける方が先に来る)
      }
    },
    [myInfo.hospitalHashId, setReturnTargetHospitalProducts, returnTargetHospitalProducts, isSearching]
  );

  // QRコード検知時
  const onDetected = useCallback(
    (managementId: string) => {
      // NOTE: ダイアログの表示中は 新たなイベント流入を止める (被写体にQRが写っている間 リクエストストームが発生する)
      if (rentalErrorDialogIsOpened || openReturnDevicesAddedDialog) return;

      return searchScannedBarcodeAndValidateReturnTargets(managementId);
    },
    [searchScannedBarcodeAndValidateReturnTargets, rentalErrorDialogIsOpened, openReturnDevicesAddedDialog]
  );

  const handleClickCancel = useCallback(
    (rowIndex: number) => {
      setReturnTargetHospitalProducts(returnTargetHospitalProducts.filter((_item, idx) => idx !== rowIndex));
    },
    [returnTargetHospitalProducts, setReturnTargetHospitalProducts]
  );

  const handleClickNextButton = useCallback(() => {
    navigate('/shared/return/product/camera/checkout');
  }, [navigate]);

  useEffect(() => {
    setScanning(!scanning);
  }, []);

  // 親子関係情報ダイアログ表示
  useEffect(() => {
    if (!openReturnDevicesAddedDialog || !targetStatusForUnitRelation) return;

    // 即値関数で表示実行
    (async () => {
      try {
        await dialogHandler.open<ParentChildDeviceDialogProps, unknown>(ParentChildDeviceDialog, {
          status: targetStatusForUnitRelation,
        });
      } catch (e) {
        console.error(e); // only output to console
      } finally {
        // 状態変数の初期化
        setOpenReturnDevicesAddedDialog(false);
        setTargetStatusForUnitRelation(undefined);
      }
    })();
  }, [openReturnDevicesAddedDialog, targetStatusForUnitRelation]);

  return (
    <>
      <Header title={'返却'} />
      <Box sx={contentFixedStyle}>
        <Box ref={scannerRef} sx={videoContainerStyle}>
          <StyledCanvas />
          {scanning ? (
            <Scanner scannerRef={scannerRef} onDetected={onDetected} constraints={constraints} />
          ) : (
            <InnerLoading />
          )}
        </Box>
        <Box sx={resultStyle}>
          <ScannedProductTable
            returnTargetHospitalProducts={returnTargetHospitalProducts}
            onClickCancel={handleClickCancel}
          />
        </Box>
      </Box>
      <Footer
        text={'返却機器のバーコードを\nカメラで読み取って下さい'}
        nextButtonLabel={'確認画面へ'}
        onClickNextButton={handleClickNextButton}
        isDisabled={isDisabled}
      />
      <RentalErrorDialog
        open={rentalErrorDialogIsOpened}
        onClickButton={() => {
          // 閉じるタイミングで状態変数の初期化を行う
          setRentalErrorDialogIsOpened(false);
          setReturnErrorStatus(null);
          setReturnErrorAdditionalInfo(undefined);
        }}
        status={returnErrorStatus}
        additionalInfo={returnErrorAdditionalInfo}
        type="return"
      />
    </>
  );
};

const videoContainerStyle: SxProps<Theme> = {
  position: 'relative',
  width: '70%',
  ml: 4,
};

const resultStyle: SxProps<Theme> = (theme) => ({
  width: '30%',
  maxHeight: '100%',
  overflow: 'auto',
  ml: 4,
  mr: 4,
  [theme.breakpoints.up('tabletH')]: {
    paddingLeft: 7,
    paddingRight: 7,
  },
  [theme.breakpoints.up('desktop')]: {
    paddingLeft: 10,
    paddingRight: 10,
  },
});

const StyledCanvas = styled('canvas')({
  top: '0px',
  left: '0px',
  height: '100%',
  width: '100%',
  position: 'absolute',
});
