import React, {useEffect} from 'react';
import {LinearProgress, Tab, Tabs, Typography, styled} from '@material-ui/core';
import {withSuspense} from '@front-libs/core';
import {useResetByQueryParams, useUpdateQueryParamsByState} from './InternalInspection/states/query';
import {useInitialized, useInspectionType} from './InternalInspection/states/states';
import {InspectionResultListBody} from './InternalInspection';
import {Header} from './Header';
import {MakerInspectionListBody} from './MakerInspection';
import {onlineManager} from 'react-query';
import {ListPageLayout} from '@components/layouts/ListPageLayout';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {useNavigate} from 'react-router-dom';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';

const InspectionTab = styled(Tab)((props: {selected: boolean}) => ({
  minWidth: 'auto', // タブの最小幅
  minHeight: 'auto', // タブの最小高さ
  padding: '6px 12px', // タブのパディング
  fontSize: '14px', // タブのフォントサイズ
  fontWeight: props.selected ? 'bold' : 'normal', // タブのフォントウェイト
  marginRight: '16px', // タブの右マージン
}));

const tabLabels = [
  {label: '使用前点検', value: 'pre_use'},
  {label: '使用中点検', value: 'in_use'},
  {label: '使用後点検', value: 'post_use'},
  {label: '定期点検', value: 'periodic'},
  {label: 'メーカー保守点検', value: 'maker_periodic'},
];

// 現状だとTableLayoutのキーを後から変更できないので、キーが変わったら再描画する形式にしています
const _InspectionResultsList: React.FC = () => {
  const navigate = useNavigate();
  const {canAccess} = useUserResourcesPermissions('INSPECTION');
  if (FEATURE_CUSTOM_ASSET_ROLE_FLAG) {
    useEffect(() => {
      if (!canAccess) {
        navigate('/dashboard');
      }
    }, [canAccess, navigate]);
    if (!canAccess) return null;
  }
  useResetByQueryParams();
  useUpdateQueryParamsByState();
  const [type, setType] = useInspectionType();

  const handleTypeChange = (
    _: React.ChangeEvent<unknown>,
    newValue: 'pre_use' | 'in_use' | 'post_use' | 'periodic' | 'maker_periodic'
  ) => {
    setType(newValue);
  };
  const [initialized] = useInitialized();
  if (!initialized) {
    return <LinearProgress />;
  }

  const isOnline = onlineManager.isOnline();
  const filteredTabLabels = isOnline ? tabLabels : tabLabels.filter((tab) => tab.value !== 'maker_periodic');

  return (
    <ListPageLayout page="inspection_results">
      <ListPageLayout.Header>
        <Header />
      </ListPageLayout.Header>
      <Tabs
        value={type}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTypeChange}
        style={{minHeight: 'auto', padding: '0px 16px 16px'}}
        aria-label="inspection tabs">
        {filteredTabLabels.map(({label, value}) => (
          <InspectionTab key={value} label={label} value={value} selected={type === value} />
        ))}
      </Tabs>
      <ListPageLayout.Content>
        {!isOnline && (
          <Typography variant="body1" style={{padding: '4px 24px 0px', fontSize: '8pt', color: 'red'}}>
            オフラインでは一部機能が利用できません。
          </Typography>
        )}
        {type !== 'maker_periodic' ? <InspectionResultListBody /> : <MakerInspectionListBody />}
      </ListPageLayout.Content>
    </ListPageLayout>
  );
};

export const InspectionResultsList = withSuspense(_InspectionResultsList, null);
