import React, {useEffect} from 'react';
import {ListPageLayout} from '@components/layouts/ListPageLayout';
import {withSuspense} from '@front-libs/core';
import {InspectionMenuHeader} from '@components/organisms/InspectionMenuHeader';
import {InspectionScheduleBackButton} from '@components/organisms/InspectionMenuHeader/InspectionScheduleBackButton';
import {HospitalProductPlanListBody} from './HospitalProductPlanListBody';
import {ProductPlanCationHeader} from './ProductPlanCationHeader';
import {useIsDisplayAnnounceBar} from './states';
import {useNavigate} from 'react-router-dom';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';

const _HospitalProductPlanList = () => {
  const navigate = useNavigate();
  const {canAccess} = useUserResourcesPermissions('INSPECTION');
  if (FEATURE_CUSTOM_ASSET_ROLE_FLAG) {
    useEffect(() => {
      if (!canAccess) {
        navigate('/dashboard');
      }
    }, [canAccess, navigate]);
    if (!canAccess) {
      return null;
    }
  }
  const [isDisplayAnnounceBar, setIsDisplayAnnounceBar] = useIsDisplayAnnounceBar();

  return (
    <ListPageLayout page="hospital_product_plan_list">
      <ListPageLayout.Header defaultOpen={isDisplayAnnounceBar}>
        {isDisplayAnnounceBar && (
          <ProductPlanCationHeader
            onClose={() => {
              setIsDisplayAnnounceBar(false);
            }}
          />
        )}
        <InspectionScheduleBackButton />
      </ListPageLayout.Header>
      <InspectionMenuHeader />
      <ListPageLayout.Content>
        <HospitalProductPlanListBody />
      </ListPageLayout.Content>
    </ListPageLayout>
  );
};

export const HospitalProductPlanList = withSuspense(_HospitalProductPlanList, null);
