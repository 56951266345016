import React from 'react';
import {Grid} from '@mui/material';
import {Pagination} from '@mui/material';
import {DisplayNumberSelectV5, Options} from '@components/molecules/DisplayNumberSelect/DisplayNumberSelectV5';

type PaginationAndPerPageProps = {
  /* 全アイテムの総数 */
  totalCount: number;
  /* 現在のページ番号 */
  currentPage: number;
  /* 1ページあたりの表示件数 */
  pageSize: number;
  /* 件数表示の切り替えボタン 指定しなければ20,50,100のセレクトボックスが表示される */
  pageSizeMenuItems?: Options;

  /**
   * ページ番号が変更された時に呼び出されるコールバック関数
   * @param newPage 新しいページ番号
   */
  onPageChange: (newPage: number) => void;
  /**
   * 表示件数が変更された時に呼び出されるコールバック関数
   * @param newPageSize 新しい表示件数
   */
  onPageSizeChange?: (newPageSize: number) => void;
};

/**
 *
 * ページネーションと表示件数の選択を提供するコンポーネント
 *
 * @todo
 * memo化を行ったがコンフリクトおきるので後ほど対応します by矢口
 * // 以下の4つの値が変わったら再レンダリング
 * return (
 *   prevProps.totalCount === nextProps.totalCount &&
 *   prevProps.currentPage === nextProps.currentPage &&
 *   prevProps.pageSize === nextProps.pageSize &&
 *   prevProps.pageSizeMenuItems === nextProps.pageSizeMenuItems
 * );
 *
 * @example
 * ```tsx
 * const [currentPage, setCurrentPage] = useState(1);
 * const [pageSize, setPageSize] = useAtom(pageSizeAtom);
 *
 * const handlePageChange = (newPage: number) => {
 *   setCurrentPage(page);
 * };
 *
 * const handlePageSizeChange = (newPageSize: number) => {
 *   setPageSize(newPageSize);
 * };
 *
 * return (
 *   <PaginationAndPerPage
 *     totalCount={100}
 *     currentPage={currentPage}
 *     pageSize={pageSize}
 *     onPageChange={handlePageChange}
 *     onPageSizeChange={handlePageSizeChange}
 *   />
 * );
 * ```
 */
export const PaginationAndPerPage = ({
  totalCount,
  currentPage,
  pageSize,
  pageSizeMenuItems,
  onPageChange,
  onPageSizeChange,
}: PaginationAndPerPageProps) => {
  const startDisplayPosition = React.useMemo(() => {
    return totalCount === 0 ? 0 : (currentPage - 1) * pageSize + 1;
  }, [currentPage, pageSize, totalCount]);

  const endDisplayPosition = React.useMemo(() => {
    return Math.min(currentPage * pageSize, totalCount);
  }, [currentPage, pageSize, totalCount]);

  const totalPages = React.useMemo(() => {
    return Math.ceil(totalCount / pageSize) || 1;
  }, [pageSize, totalCount]);

  const handlePageChange = (_e: React.ChangeEvent<unknown>, page: number) => {
    onPageChange(page);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    if (onPageSizeChange) onPageSizeChange(newPageSize);
  };

  return (
    <Grid container alignItems="center" sx={{fontSize: '0.875rem'}}>
      <Grid item xs sx={{textAlign: 'left'}}>
        {totalCount}件のうち{startDisplayPosition}件目-{endDisplayPosition}件目までを表示しています
      </Grid>
      <Grid item xs sx={{display: 'flex', justifyContent: 'center'}}>
        <Pagination
          page={currentPage}
          count={totalPages}
          shape="rounded"
          onChange={handlePageChange}
          aria-label="ページ切り替え"
        />
        <DisplayNumberSelectV5
          pageSize={pageSize}
          pageSizeMenuItems={pageSizeMenuItems}
          update={handlePageSizeChange}
        />
      </Grid>
      <Grid item xs sx={{display: 'flex', justifyContent: 'flex-end'}}>
        {''}
      </Grid>
    </Grid>
  );
};
