import {ListScanBarcodeReturnProductsResult} from '@modules/rentals/api';
import {TargetStatusForUnitRelation, UnacceptedStatus} from '@Apps/BaseSharedMenu/types';
import {ReturnTargetHospitalProducts} from '@Apps/BaseSharedMenu/ReturnDevices/types';

export type ValidatedReturnTargetInfo = {
  additionalTargets: ReturnTargetHospitalProducts;
  isUnitRelationMode: boolean;
  unitRelationAppendStatus?: TargetStatusForUnitRelation;
  validationError?: UnacceptedStatus;
  managementIdOfErrorOccurred?: string;
};

/**
 * 返却対象として抽出された機器リストのチェックを行う (親子関係をサポートする)
 */
export const validateReturnTarget = (result: ListScanBarcodeReturnProductsResult): ValidatedReturnTargetInfo => {
  // 対象候補の機器は あらかじめ管理番号でソートしておく (APIでは 親子関係で紐付けられた機器は バーコード値で抽出された機器より後にリストされてるので)
  result.returnableList.sort((a, b) => a.managementId.localeCompare(b.managementId, 'ja'));

  // "not_returnable_list" / "returnable_list" 内に is_unit_relation = true の機器があるか？
  const existsRelationUnitInNotReturnable = result.notReturnableList.some((i) => i.isUnitRelation);
  const existsRelationUnitInReturnable = result.returnableList.some((i) => i.isUnitRelation);

  // ないとき：在来モード(単独返却・セット返却モード)
  if (!existsRelationUnitInNotReturnable && !existsRelationUnitInReturnable) {
    return validateReturnTargetForSingleOrSetProducts(result);
  }

  // あるとき：親子モード
  return validateReturnTargetForUnitRelationProducts(result);
};

/**
 * 在来モード(単独返却・セット返却モード) での チェック処理
 */
const validateReturnTargetForSingleOrSetProducts = (
  result: ListScanBarcodeReturnProductsResult
): ValidatedReturnTargetInfo => {
  // "not_returnable_list" 内に 機器があるか？
  if (result.notReturnableList.length > 0) {
    // あるとき：エラー → 返却出来ません
    const notReturnableProduct = result.notReturnableList[0];
    return {
      additionalTargets: [],
      validationError: 'not_working', // 単純に 貸出履歴データ が無い
      managementIdOfErrorOccurred: notReturnableProduct.managementId,
      isUnitRelationMode: false,
    };
  }

  // "returnable_list" 内に 機器があるか？
  if (result.returnableList.length > 0) {
    // あるとき：返却リストに追加する
    return {
      additionalTargets: result.returnableList,
      isUnitRelationMode: false,
    };
  } else {
    // ないとき：エラー → 機器が見つからなかった旨を表示
    return {
      additionalTargets: [],
      validationError: 'target_not_found',
      isUnitRelationMode: false,
    };
  }
};

/**
 * 親子モードでのチェック処理
 */
const validateReturnTargetForUnitRelationProducts = (
  result: ListScanBarcodeReturnProductsResult
): ValidatedReturnTargetInfo => {
  // "not_returnable_list" 内 に  is_unit_relation = false の機器があるか？
  //  TODO: [FIX ME] 以下、演算コストの面から filter ではなく firstメソッドを array に生やしたい。(条件合致の1個目だけ取得したら終了できる)
  const scannedTargetAndNotReturnable = result.notReturnableList.filter((i) => !i.isUnitRelation);
  if (scannedTargetAndNotReturnable.length > 0) {
    // あるとき：エラー → ○○のため返却出来ません。
    //  [NOTE] つまり、バーコード読取した機器が 返却出来ない状態
    const notReturnableProduct = scannedTargetAndNotReturnable[0];

    return {
      additionalTargets: [],
      validationError: 'not_working', // 単純に 貸出履歴データ が無い
      managementIdOfErrorOccurred: notReturnableProduct.managementId,
      isUnitRelationMode: true,
    };
  }

  // →返却リストに 親子関係の機器を追加するため 追加の判定を行う
  let targetOfUnitRelationStatus: TargetStatusForUnitRelation | undefined;

  // "not_returnable_list" 内 に is_unit_relation = true の機器があるか？
  const relationUnitInNotReturnable = result.notReturnableList.some((i) => i.isUnitRelation);
  if (relationUnitInNotReturnable) {
    // あるとき：注意付ダイアログの判定を行う

    // "returnable_list" 内 に is_unit_relation = true の機器があるか？
    const relationUnitInReturnable = result.returnableList.some((i) => i.isUnitRelation);
    if (relationUnitInReturnable) {
      // あるとき：注意付のダイアログ を表示する (親子関係の内 一部は 返却出来ない状態)
      targetOfUnitRelationStatus = 'returnRelationTargetsAppendedExcludeSomeUnits';
    } else {
      // ないとき：注意付のダイアログ を表示 (読取バーコードの機器だけ追加されて 紐付いた機器は全て返却出来ない状態)
      targetOfUnitRelationStatus = 'returnRelationTargetsNotAppended';
    }
  } else {
    // ないとき：親子関係が全て読み込まれたダイアログを表示→返却リスト表示
    targetOfUnitRelationStatus = 'returnRelationTargetsAppended';
  }

  return {
    additionalTargets: result.returnableList,
    isUnitRelationMode: true,
    unitRelationAppendStatus: targetOfUnitRelationStatus,
  };
};
