import {WardAssetMenuTemplate} from '@components/templates/WardAssetMenuTemplate';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';
import {useFetchHospitalSettingsQuery} from '@modules/hospital_settings/api';
import {faultReceptionSettings} from '@modules/hospital_settings/types';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
import {InnerLoading} from '@molecules/Loading';
import {Box, Divider, Grid, styled, SxProps, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {ConfirmCard} from './ConfirmCard';
import {MenuCard} from './MenuCard';
import React, {useEffect} from 'react';
import {StrUtil} from '@front-libs/helpers';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';

type ReceptionMenuSettings = {
  showRentableDevices: boolean;
  showFaultReceptions: boolean;
  showReturnWaitingReception: boolean;
};

const LeftMenuLabel = ({isReturnWaitingAvailable}: {isReturnWaitingAvailable: boolean}) => {
  const Typo = ({children}: {children: React.ReactNode}) => {
    return (
      <Typography component="p" variant="inherit">
        {children}
      </Typography>
    );
  };

  const Label = () => {
    return (
      <Box display="flex" flexDirection="column">
        <Typo>貸出可能</Typo>
        <Typo>機器の閲覧</Typo>
        <Divider sx={{borderColor: '#0052CC', margin: '20px 0'}} />
        <Typo>返却待ち受付</Typo>
      </Box>
    );
  };

  return <>{isReturnWaitingAvailable ? <Label /> : StrUtil.nl2br('貸出可能\n機器の閲覧')}</>;
};

/**
 * Asset病棟ユーザーメニュー
 * @todo ボタンが複数の時、タブレットで表示出来なくなるのでレスポンシブ対応(2x2に切り替わる)を行う
 * @returns
 */
export const ReceptionMenu = () => {
  const navigate = useNavigate();
  const {canAccess: canAccessWardService} = useUserResourcesPermissions('WARD_SERVICE');
  if (FEATURE_CUSTOM_ASSET_ROLE_FLAG) {
    useEffect(() => {
      if (!canAccessWardService) {
        navigate('/dashboard');
      }
    }, [canAccessWardService, navigate]);
    if (!canAccessWardService) return null;
  }
  const {isWard} = useMyRole();
  const {myInfo} = useMyInfo();

  const {data, isLoading} = useFetchHospitalSettingsQuery(myInfo.hospitalHashId);
  if (isLoading) {
    return <InnerLoading />;
  }

  const settings: ReceptionMenuSettings = {
    showRentableDevices:
      data?.data?.find((x) => x.key === faultReceptionSettings.show_rentable_devices.key)?.value === 'true',
    showFaultReceptions:
      data?.data?.find((x) => x.key === faultReceptionSettings.show_fault_receptions.key)?.value === 'true',
    showReturnWaitingReception:
      data?.data?.find((x) => x.key === faultReceptionSettings.show_return_waiting_reception.key)?.value === 'true',
  };

  /**
   * 病棟ユーザーかつ表示設定がOFFの時は非表示
   */
  const showRentableDevices = !isWard || settings.showRentableDevices;
  const showFaultReceptions = !isWard || settings.showFaultReceptions;
  const showReturnWaitingReception = !isWard || settings.showReturnWaitingReception;

  return (
    <WardAssetMenuTemplate
      headerProps={{title: 'Asset病棟ユーザーメニュー', showLogo: true}}
      footerProps={{
        backButtonHandler: () => {
          // FIXME:暫定的に修理に戻るようにする
          navigate('/repairs');
        },
        hideBackButton: isWard,
      }}
      sx={MainStyle}>
      {showRentableDevices && (
        <StyledContainer>
          <ItemGrid>
            <MenuCard
              label={<LeftMenuLabel isReturnWaitingAvailable={showReturnWaitingReception} />}
              onChange={() => {
                showReturnWaitingReception
                  ? navigate('/shared/return_waiting_reception')
                  : navigate('/shared/rentable_devices');
              }}
            />
          </ItemGrid>
          <MenuItemGrid>
            {showReturnWaitingReception && (
              <ConfirmCard
                label={'返却待ち受付の履歴確認'}
                onChange={() => navigate('/shared/return_waiting_reception/history')}
              />
            )}
          </MenuItemGrid>
        </StyledContainer>
      )}
      {showFaultReceptions && (
        <StyledContainer>
          <ItemGrid>
            <MenuCard label={'不具合受付'} onChange={() => navigate('/shared/faut_reception/register/input')} />
          </ItemGrid>
          <MenuItemGrid>
            <ConfirmCard label={'不具合受付の履歴確認'} onChange={() => navigate('/shared/fault_receptions')} />
          </MenuItemGrid>
        </StyledContainer>
      )}
    </WardAssetMenuTemplate>
  );
};
const MainStyle: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row',
  padding: '32px',
  width: 'stretch',
};

const StyledContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 20px',
});

const ItemGrid = styled(Grid)({
  width: 'min(36vmin,368px)',
  height: 'min(36vmin,368px)',
});

const MenuItemGrid = styled(Grid)({
  width: 'min(36vmin,368px)',
  height: 'min(8vmin,80px)',
  marginTop: '24px',
});
