import {BlueBackgroundCounter} from '@front-libs/ui';
import {Button, SxProps} from '@mui/material';
import React from 'react';

type CountOutlinedButtonProps = {
  children: React.ReactNode;
  onClick: () => void;
  count?: number;
};

const buttonStyle: SxProps = {
  fontSize: '14px',
  backgroundColor: 'white',
};

/**
 * ボタンに件数を表示するコンポーネント
 * ボタンはアウトラインスタイル
 */
export const CountOutlinedButton = ({children, onClick, count}: CountOutlinedButtonProps) => {
  return (
    <Button variant="outlined" color="primary" onClick={onClick} sx={buttonStyle}>
      {children}
      {count !== 0 && <BlueBackgroundCounter count={count} />}
    </Button>
  );
};
